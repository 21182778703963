import React, { Fragment, useState, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import * as moment from "moment";
import OrdersTableBase from "../../Components/Commons/OrdersTableBase";
import OrderStatus from "../OrderSets/OrderStatus";
import 'react-toastify/dist/ReactToastify.css';
import { withTranslation } from 'react-i18next';

const OrderSetsTable = ({
  data,
  isLoading,
  isSuccess,
  error,
  t,
  i18n
}) => {

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".orderCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };

  const toggle = useCallback(() => {
    
  });

  const handleOrderClick = useCallback((arg) => {
    const order = arg;
    setOrder({
      _id: order._id,
      orderId: order.orderId,
      customer: order.customer,
      product: order.product,
      orderDate: order.orderDate,
      ordertime: order.ordertime,
      amount: order.amount,
      payment: order.payment,
      status: order.status
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);

  const handleValidDate = date => {
    const date1 = moment(date).format("DD/MM");
    return date1;
  };

  const handleValidTime = (time) => {
    const updateTime = moment(time).format('HH:mm');
    return updateTime;
  };
  
  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("Order Id"),
        accessor: "reference",
        filterable: false,
        Cell: (cell) => {
          return <Link to={`${cell.row.original.id}`} className="fw-medium link-primary">{parseInt(cell.value)}</Link>;
        },
      },
      {
        Header: t("Customer"),
        accessor: "customer",
        filterable: false,
        Cell: (cell) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                {cell.row.original.customer != null ? cell.row.original.customer.name : ''}
                <p className="text-muted mb-0">
                  <small className="text-muted"> {cell.row.original.address != null ? cell.row.original.address.address_string : ''}</small>
                </p>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: t("Created at"),
        accessor: "created_at",
        Cell: (order) => (
          <>
            {handleValidDate(order.row.original.created_at)},
            <small className="text-muted"> {handleValidTime(order.row.original.created_at)}</small>
          </>
        ),
      },
      {
        Header: t("Boxes"),
        accessor: "box_quantity",
        filterable: false,
      },
      {
        Header: t("Outs of Box"),
        accessor: "quantity",
        filterable: false,
      },
      {
        Header: t('Status'),
        accessor: 'status',
        Cell: (cell) => (
          <OrderStatus status={cell.row.original.status} status_text={cell.row.original.status_text} />
        )
      },
      {
        Header: t("Campaign Id"),
        accessor: "campaign_reference",
        filterable: false,
      },
      {
        Header: t("Zone Id"),
        accessor: "zone",
        filterable: false,
      },
    ],
    [handleOrderClick, checkedAll]
  );

  return (
    <OrdersTableBase
      columns={columns}
      data={data}
      isLoading={isLoading}
      isSuccess={isSuccess}
      error={error}
      manualPagination={true}
      customPageSize={25}
      divClass="table-responsive table-card mb-1"
      tableClass="align-middle table-wrap"
      theadClass="table-light text-muted"
    />
  );
};

export default withTranslation()(OrderSetsTable);

