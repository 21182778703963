import React, { useEffect, useState, useCallback } from "react";
import { withTranslation } from 'react-i18next';
import {
	Input,
	Card,
	CardBody,
	Col,
	Container,
	CardHeader,
	Row,
	Badge,
	Spinner,
} from "reactstrap";
import { isEmpty } from "lodash";
import * as moment from "moment";
import { hasGroup } from "../../api/session_helper";
import Pagination from "../../Components/Commons/Pagination";
import ClientOrderSetsTable from "./ClientOrderSetsTable";
import DeleteModal from "../../Components/Commons/DeleteModal";
import ConfirmModal from "../../Components/Commons/ConfirmModal";

import { useAsyncDebounce } from "react-table";
import { useFormik } from "formik";

import Flatpickr from "react-flatpickr";

import Select from "react-select";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
	orderApi,
	useGetOrderSetsQuery, 
	useUpdateOrderSetsMutation,
	usePickingReturnOrderSetsMutation,
	useDeleteOrderSetMutation,
	useGetOrderSetStatusesTypesQuery,
	useGetOrderExportByIdQuery,
	useGetOrderEventsTypesQuery,
	useCreateExportOrdersMutation
 } from '../../api/api'

const ClientOrderSets = ({ t, i18n }) => {

	const [currentPage, setCurrentPage] = useState(1);
	const [currentPageSize, setCurrentPageSize] = useState(1);
	const [orderSearch, setOrderSearch] = useState(null);

	// USED AS DELIVERY DATE
	const [creationStartDate, setCreationStartDate] = useState(null);
	const [creationEndDate, setCreationEndDate] = useState(null);

	const [billingStartDate, setBillingStartDate] = useState(null);
	const [billingEndDate, setBillingEndDate] = useState(null);
	const [driver, setDriver] = useState(null);
	const [campaign, setCampaign] = useState(null);
	const [zone, setZone] = useState(null);
	const [orderSetsStatuses, setOrderSetsStatuses] = useState(null);
	const [ordersStatuses, setOrdersStatuses] = useState(null);
	const [boxesAmount, setBoxesAmount] = useState(null);
	const [outBoxesAmount, setOutBoxesAmount] = useState(null);
	const [withCollect, setWithCollect] = useState(null);
	const [kind, setKind] = useState(null);

	const [activeTab, setActiveTab] = useState("1");

	const [selectedItems, setSelectedItems] = useState([]);
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
	const [deleteModalMulti, setDeleteModalMulti] = useState(false);
	const [modal, setModal] = useState(false);
	const [backOrderModal, setBackOrderModal] = useState(false);
	const [loadingDownload, setLoadingDownload] = useState(false);

	const [exportQuery, setExportQuery] = useState(null);
	const [exportReference, setExportReference] = useState(null);

	const [updateOrderSets, 
		{ error: errors, 
			isLoading: isUpdateMultipleLoading,
			isSuccess: isUpdateMultipleSuccess,
			data: updateOrderSetsData
		}] = useUpdateOrderSetsMutation();

	const [pickingReturnOrderSets, 
		{ error: pickingReturnErrors, 
			isLoading: isPickingReturnLoading,
			isSuccess: isPickingReturnSuccess,
			data: pickingReturnOrderSetsData
		}] = usePickingReturnOrderSetsMutation();

	const [deleteOrderSet, 
		{ error: deleteErrors, 
			isLoading: isDeleteLoading,
			isSuccess: isDeleteSuccess,
			data: delteOrderSetsData
		}] = useDeleteOrderSetMutation();

	const { 
		data: orderEventsTypes,
		error: orderEventsTypesErrors,
		isFetching: isOrderEventsTypesLoading,
		isSuccess: isOrderEventsTypesSuccess
	} = useGetOrderEventsTypesQuery(
		{
			refetchOnMountOrArgChange: true,
			skip: backOrderModal == null || !backOrderModal,
		}
	);

	const toggle = useCallback(() => {
		if (modal) {
			setModal(false);
			validation.resetForm();
		} else {
			setModal(true);
		}
	}, [modal]);

	const [
		createExportOrders, 
		{
			error: isOrdersExportCreatedError,
			isLoading: isOrdersExportCreatedLoading,
			isSuccess: isOrdersExportCreatedSuccess,
			data: ordersExportCreated
		}] = useCreateExportOrdersMutation();

	const { 
		data: ordersExport,
		error: isOrdersExportError,
		isFetching: isOrdersExportLoading,
		isSuccess: isOrdersExportSuccess
	} = useGetOrderExportByIdQuery(
		exportReference,
		{
			refetchOnMountOrArgChange: true,
			pollingInterval: exportReference ? 5000 : 0,
			skip: exportReference == null
		}
	);

	useEffect(() => {
		if (isOrdersExportCreatedSuccess) {
			console.log("isOrdersExportCreatedSuccess: " + isOrdersExportCreatedSuccess + " ordersExportCreated: " + ordersExportCreated.input_reference);
			setLoadingDownload(true);
			//setExportedInputCreated(ordersExportCreated);
			setExportReference(ordersExportCreated.input_reference);
		}
	}, [isOrdersExportCreatedSuccess, ordersExportCreated, exportQuery]);

	/*
	ERROR = 1
    ENTERED = 2
    IN_PROGRESS = 3
    SUCCESS = 4
    VALIDATION_ERROR = 5
	*/

	useEffect(() => {
		if (ordersExport) {

			console.log(ordersExport + ": " + ordersExport.file_url);
			
			if(ordersExport.status === 4){
				setExportQuery(null);
				setExportReference(null);
				setLoadingDownload(false);
				window.open(ordersExport.file_url, "_blank");

				orderApi.util.invalidateTags([
					'OrderExports']);
			}

			if(ordersExport.status === 1){
				setExportQuery(null);
				setExportReference(null);
				setLoadingDownload(false);
			}

			if(ordersExport.status === 5){
				setExportQuery(null);
				setExportReference(null);
				setLoadingDownload(false);
			}
		}
	}, [ordersExport]);

	const onMultipleSelected = (selected) => {
		setSelectedItems(selected);
		if(hasGroup(['Manager', 'OperationalManager', 'RouteManager'])){
			setIsMultiDeleteButton(selected.length > 0);
		}
	}

	const onSingleDeletePressed = (selected) => {
		setSelectedOrder(selected);
		setDeleteModalMulti(true);
	}

	const doDeletion = () => {
		if(selectedOrder){
			deleteOrderSet({id: selectedOrder.id});
		} else if(selectedItems && selectedItems.length > 0){
			const batch = {
				ids: selectedItems,
				data: {
					status: -4,
				}
			}
			updateOrderSets({
				body: batch 
			});
		}
		setSelectedItems([]);
		setSelectedOrder(null);
		setDeleteModalMulti(false);
		const ele = document.querySelectorAll(".orderCheckBox");
		ele.forEach((ele) => {
			ele.checked = false;
		});
	};

	const doBackToWarehouse = () => {
		if(selectedItems && selectedItems.length > 0){
			const batch = {
				ids: selectedItems,
			}
			pickingReturnOrderSets({
				body: batch 
			});
		}
		setBackOrderModal(false);
	};

	const handleOrderSetState = (selection) => {
		setCurrentPage(1);
		let orderSetsStatuses = selection.map((status, key) => {
			return status.value;
		});
		setOrderSetsStatuses(orderSetsStatuses.length > 0 ? orderSetsStatuses : null);
	};

	const { 
		data: orderSetsPages,
		error,
		isFetching: isOrderSetLoading,
		isSuccess: isOrderSetSuccess
	} = useGetOrderSetsQuery(
		{ 
			page: currentPage,
			filter: orderSearch,
			billingStartDate: billingStartDate,
			billingEndDate: billingEndDate,
			deliveredStartDate: creationStartDate,
			deliveredEndDate: creationEndDate,
			campaign: campaign,
			zone: zone,
			onlyCompanyAssigned: activeTab === "1",
			orderSetsStatuses: orderSetsStatuses,
			ordersStatuses: ordersStatuses,
			withCollect: withCollect,
			withBoxesAmounts: boxesAmount,
			withOutBoxesAmount: outBoxesAmount,
			kind: kind,
			driver: driver,
			page_size: currentPageSize
		},
		{
			refetchOnMountOrArgChange: true,
			skip: false,
		}
	);

	const { 
		data: orderSetStatuses,
		error: orderSetStatusesErrors,
		isFetching: isOrderSetStatusesLoading,
		isSuccess: isOrderSetStatusesSuccess
	} = useGetOrderSetStatusesTypesQuery(
		{
			refetchOnMountOrArgChange: true,
			skip: false
		}
	);

	const onChange = useAsyncDebounce((value) => {
		setOrderSearch(value);
	}, 200);

	const validation = useFormik({
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: false,

		initialValues: {
			reference: '',
			campaign_reference: '',
			billing_date: '',
			box_quantity: '',
			quantity: '',
			zone: '',
			territory: '',
			afp_name: '',
			status: ''
		},

		validationSchema: Yup.object({
			quantity: Yup.number().moreThan(-1),
			afp_name: Yup.string(),
		}),

		onSubmit: (values) => {
			const newOrder = {
				campaign_reference: values["campaign_reference"],
				billing_date: values["billing_date"],
				box_quantity: values["box_quantity"],
				quantity: values["quantity"],
				zone: values["zone"],
				territory: values["territory"],
				afp_name: values["afp_name"],
				status: values["status_id"],
			};
			const newOrderFiltered = Object.entries(newOrder).reduce(
				(acc, [k, v]) => v || v != "" ? {...acc, [k]:v} : acc , {});
			if(Object.keys(newOrderFiltered).length > 0){
				const batch = {
					ids: selectedItems,
					data: newOrderFiltered
				}
				updateOrderSets({
					body: batch 
				});
				validation.resetForm();
				toggle();
			}
		},
	});

	const handleExport = () => {
		let paramsObj = {
			page: currentPage,
			filter: orderSearch,
			billingStartDate: billingStartDate,
			billingEndDate: billingEndDate,
			creationStartDate: creationStartDate,
			creationEndDate: creationEndDate,
			campaign: campaign,
			zone: zone,
			onlyCompanyAssigned: true,
			orderSetsStatuses: orderSetsStatuses,
			ordersStatuses: ordersStatuses,
			withBoxesAmounts: boxesAmount,
			kind: kind
		}
		/*orderApi.util.invalidateTags([
			'OrderExports']);
		setExportQuery(paramsObj);
		console.log("--> setExportQuery" + billingStartDate);*/

		createExportOrders(paramsObj);
		setLoadingDownload(true);

		/*let URLparams = new URLSearchParams(omitBy(paramsObj, isNull)).toString();
		if(Cookies.get("authUser")){
			let token = JSON.parse(Cookies.get("authUser")).token;
			setLoadingDownload(true);
			axios.get(`order-sets/export_data/?${URLparams}`, 
				{
					headers: { 'Authorization': `Token ${token}` },
					responseType: 'blob'
				}).then((response) => {
					setLoadingDownload(false);
					saveAs(response, `pedidos_${Date.now()}.xls`);
				});
		}*/
	};

	const handlePageSizeChange = (pageSize) => {
		setCurrentPage(1);
		setCurrentPageSize(pageSize);
	};

	document.title = "Sistema de pedidos Transchile";

	return (
		<div className="page-content">
			<DeleteModal
				show={deleteModalMulti}
				onDeleteClick={() => doDeletion()}
				onCloseClick={() => {
					setDeleteModalMulti(false);
					setSelectedOrder(null);
				}}
				isMultiple={selectedOrder === null && selectedItems && selectedItems.length > 0}
			/>
			<ConfirmModal
				show={backOrderModal}
				onConfirmClick={() => doBackToWarehouse()}
				onCloseClick={() => setBackOrderModal(false)}
				title={t("Return orders to the warehouse")}
				question={t("Do you want to return selected orders to the warehouse? The events 'Returned by driver' and 'Warehouse' will be assigned.")}
				yesQuestion={t("yes")}
			/>
			<Container fluid>
				<Row>
					<Col lg={12} className="p-2">
						<Card id="orderList">
							<CardHeader className="border-0">
								<Row className="align-items-center gy-3">
									<div className="col-sm">
										<h5 className="card-title mb-0">{t("Orders")} <Badge color="primary" className="ms-1">{orderSetsPages != null ? orderSetsPages.count : '...'}</Badge></h5>
									</div>
									<div className="col-sm-auto">
										<div className="d-flex gap-1 flex-wrap sp-4">
											<button 
												type="button"
												className="btn btn-info"
												disabled={loadingDownload}
												onClick={() => handleExport()}>
												{loadingDownload && <Spinner size='sm' color="primary"/>}
												{!loadingDownload && <i className="ri-file-download-line align-bottom me-1"></i>}
												{t("Export")}
											</button>
										</div>
									</div>
								</Row>
							</CardHeader>
							<CardBody className="pt-0">
								<Row className="mb-3">
									<CardBody className="border border-dashed border-end-0 border-start-0">
										<form>
											<Row>
												<Col md={3}>
													<Input
														name="orderId"
														id="id-field"
														className="form-control"
														placeholder="Busca por pedido o consultora"
														type="text"
														onChange={(e) => {
															setCurrentPage(1);
															onChange(e.target.value);
														}}
													/>
												</Col>

												<Col md={3}>
													<Flatpickr
														name="created-at"
														className="form-control"
														id="datepicker-created-at"
														placeholder="Fecha de entrega"
														options={{
															altInput: true,
															altFormat: "F j, Y",
															mode: "range",
															dateFormat: "DD-MM-YYYY",
														}}
														onChange={(date) => {
															setCurrentPage(1);
															if(date[0]!== undefined){
																const date1 = moment(date[0]).format("DD-MM-YYYY");
																setCreationStartDate(date1);
															} else {
																setCreationStartDate(null);
															}
															if(date[1]!== undefined){
																const date1 = moment(date[1]).format("DD-MM-YYYY");
																setCreationEndDate(date1);
															} else {
																setCreationEndDate(null);
															}
														}}
													/>
												</Col>
									
												<Col md={3}>
													<Flatpickr
														name="billing-at"
														className="form-control"
														id="datepicker-billing-date"
														placeholder="Facturación"
														options={{
															altInput: true,
															altFormat: "F j, Y",
															mode: "range",
															dateFormat: "DD-MM-YYYY",
														}}
														onChange={(date) => {
															setCurrentPage(1);
															if(date[0]!== undefined){
																const date1 = moment(date[0]).format("DD-MM-YYYY");
																setBillingStartDate(date1);
															} else {
																setBillingStartDate(null);
															}
															if(date[1]!== undefined){
																const date1 = moment(date[1]).format("DD-MM-YYYY");
																setBillingEndDate(date1);
															} else {
																setBillingEndDate(null);
															}
														}}
													/>
												</Col>

												{orderSetStatuses && orderSetStatuses.statuses && (
													<Col md={3}>
														<Select
															isMulti
															placeholder="Estado del pedido"
															classNamePrefix="form-select"
															isLoading={isOrderSetStatusesLoading}
															isClearable={true}
															name="types"
															onChange={handleOrderSetState}
															options={orderSetStatuses.statuses.map((eventType, key) => {
																return {
																	...eventType,
																	value: eventType.value,
																	label: `${eventType.value} - ${eventType.display}`,
																};
															})}
														/>
													</Col>
												)}
												
											</Row>
											<Row className='mt-2'>
												
												<Col md={3}>
													<Input
														name="zoneId"
														id="id-zone"
														className="form-control"
														placeholder="Zona"
														type="text"
														validate={{
															required: { value: true },
														}}
														onChange={(e) => {
															setCurrentPage(1);
															setZone(e.target.value && e.target.value.length > 0 ? e.target.value : null);
															debugger;
														}}
													/>
												</Col>

												<Col md={3}>
													<Input
														name="boxesAmount"
														id="id-boxes"
														className="form-control"
														placeholder="Cantidad de cajas"
														type="number"
														validate={{
															required: { value: true },
														}}
														onChange={(e) => {
															setCurrentPage(1);
															setBoxesAmount(e.target.value && e.target.value.length > 0 ? e.target.value : null);
														}}
													/>
												</Col>

												<Col md={3}>
													<Input
														name="campaignId"
														id="id-campaign"
														className="form-control"
														placeholder="Campaña"
														type="text"
														validate={{
															required: { value: true },
														}}
														onChange={(e) => {
															setCurrentPage(1);
															setCampaign(e.target.value && e.target.value.length > 0 ? e.target.value : null);
														}}
													/>
												</Col>
											
											</Row>
										</form>
									</CardBody>
								</Row>
								<ClientOrderSetsTable
									data={orderSetsPages != null ? orderSetsPages.results : []}
									isLoading={isOrderSetLoading}
									isSuccess={isOrderSetSuccess}
									error={error}
									onSelected={onMultipleSelected}
									onDelete={onSingleDeletePressed}
								/>
								{orderSetsPages && !isEmpty(orderSetsPages.results) && (
									<Pagination
										totalRows={orderSetsPages.count}
										pageChangeHandler={setCurrentPage}
										pageSizeChangeHandler={handlePageSizeChange}
										rowsPerPage={currentPageSize}
									/>
								)}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default withTranslation()(ClientOrderSets);

