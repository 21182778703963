import { api } from "../config";
// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { omitBy, isNull } from "lodash";
import Cookies from 'js-cookie';

// Define a service using a base URL and expected endpoints
export const orderApi = createApi({
	reducerPath: 'transchileApi',
	tagTypes: ['Orders', 'OrderImports', 'Users', 'Picking', 'Drivers', 'OperationalBlocks', 'Companies', 'PublicPickings', 'PickingCompletionRequests', 'OrderExports'],
	baseQuery: fetchBaseQuery({
		baseUrl: api.API_URL,
		prepareHeaders: (headers) => {
			if(Cookies.get("authUser")){
				let token = JSON.parse(Cookies.get("authUser")).token;
				headers.set('Authorization', `Token ${token}`);
			}
			const language = localStorage.getItem("I18N_LANGUAGE")
			if(language){
				headers.set('Accept-Language', language);
			}
			return headers;
		},
	}),
	endpoints: (builder) => ({

		auth: builder.mutation({
			query: (body) => ({
				url: `auth/`,
				method: 'POST',
				body,
			}),
			transformErrorResponse: (response, meta, arg) => {
				let errorString;
				if(response.data instanceof Array){
					errorString = response.data.join(', ');
				} else if(response.data && response.data.non_field_errors && response.data.non_field_errors instanceof Array){
					errorString = response.data.non_field_errors.join(', ');
				} else if(response.error){
					errorString = response.error;
				} else {
					errorString = "Unknown error"
				}
				return errorString;
			},
			invalidatesTags: ['Orders', 'OrderImports', 'Users', 'Picking', 'Drivers', 'OperationalBlocks', 'Companies'],
		}),

		driverSearch: builder.mutation({
			query: (body) => ({
				url: `driver-rut-search/`,
				method: 'POST',
				body,
			}),
			transformErrorResponse: (response, meta, arg) => {
				let errorString;
				if(response.data instanceof Array){
					errorString = response.data.join(', ');
				} else if(response.data && response.data.non_field_errors && response.data.non_field_errors instanceof Array){
					errorString = response.data.non_field_errors.join(', ');
				} else if(response.error){
					errorString = response.error;
				} else {
					errorString = "Unknown error"
				}
				debugger;
				return errorString;
			},
		}),

		getCollectTickets: builder.query({
			query: (args) => {
				let params = new URLSearchParams(omitBy(args, isNull)).toString();
				return `collect-tickets/?${params}`;
			},
			transformResponse: (response, meta, arg) => {
				return response;
			},
			providesTags: ['CollectTickets'],
		}),

		getCollectTicketById: builder.query({
			query: (id) => `collect-tickets/${id}`,
			transformErrorResponse: (response, meta, arg) => {
				return response;
			},
			providesTags: (result, error, id) => {
				return [{ type: 'CollectTickets', id }];
			}
		}),

		createCollectTicket: builder.mutation({
			query: (body) => ({
				url: `collect-tickets/`,
				method: 'POST',
				body,
			}),
			transformErrorResponse: (response, meta, arg) => {
				return response.data;
			},
			invalidatesTags: ['CollectTickets', 'Pickings'],
		}),

		updateCollectTicket: builder.mutation({
			query: ({id, body}) => ({
				url: `collect-tickets/${id}/`,
				method: 'PATCH',
				body,
			}),
			transformErrorResponse: (response, meta, arg) => {
				return response.data;
			},
			invalidatesTags: ['CollectTickets', 'Pickings'],
		}),

		createOutOfBox: builder.mutation({
			query: (body) => ({
				url: `out-of-boxes/`,
				method: 'POST',
				body,
			}),
			transformErrorResponse: (response, meta, arg) => {
				return response.data;
			},
			invalidatesTags: ['OrderSets'],
		}),

		getOutOfBoxes: builder.query({
			query: (args) => {
				let params = new URLSearchParams(omitBy(args, isNull)).toString();
				return `out-of-boxes/?${params}`;
			},
			transformResponse: (response, meta, arg) => {
				return response;
			},
			providesTags: ['CollectTickets'],
		}),
		
		getOrderSets: builder.query({
			query: (args) => {
				let params = new URLSearchParams(omitBy(args, isNull)).toString();
				return `order-sets/?${params}`;
			},
			transformResponse: (response, meta, arg) => {
				return response;
			},
			providesTags: ['OrderSets'],
		}),

		getOrderSetById: builder.query({
			query: (id) => `order-sets/${id}`,
			transformErrorResponse: (response, meta, arg) => {
				return response;
			},
			providesTags: (result, error, id) => {
				return [{ type: 'OrderSets', id }];
			}
		}),

		createOrderSet: builder.mutation({
			query: (body) => ({
				url: `order-sets/`,
				method: 'POST',
				body,
			}),
			transformErrorResponse: (response, meta, arg) => {
				return response.data;
			},
			invalidatesTags: ['OrderSets', 'Pickings'],
		}),

		updateOrderSet: builder.mutation({
			query: ({id, body}) => ({
				url: `order-sets/${id}/`,
				method: 'PATCH',
				body,
			}),
			transformErrorResponse: (response, meta, arg) => {
				return response.data;
			},
			invalidatesTags: ['OrderSets', 'Pickings'],
		}),

		deleteOrderSet: builder.mutation({
			query: ({id, body}) => ({
				url: `order-sets/${id}/`,
				method: 'DELETE',
				body,
			}),
			transformErrorResponse: (response, meta, arg) => {
				return response.data;
			},
			invalidatesTags: ['OrderSets', 'Pickings'],
		}),

		updateOrderSets: builder.mutation({
			query: ({body}) => ({
				url: `order-sets/batch_update_orders/`,
				method: 'POST',
				body,
			}),
			transformErrorResponse: (response, meta, arg) => {
				return response.data;
			},
			invalidatesTags: ['OrderSets', 'Pickings'],
		}),

		pickingReturnOrderSets: builder.mutation({
			query: ({body}) => ({
				url: `order-sets/batch_picking_return/`,
				method: 'POST',
				body,
			}),
			transformErrorResponse: (response, meta, arg) => {
				return response.data;
			},
			invalidatesTags: ['OrderSets', 'Pickings'],
		}),

		getOrderSetStatusesTypes: builder.query({
			query: () => ({
				url: `order-sets/get_statuses/`,
			}),
		}),

		getOrderStatusesTypes: builder.query({
			query: () => ({
				url: `orders/get_statuses/`,
			}),
		}),

		getOrderById: builder.query({
			query: (id) => `orders/${id}`,
			transformErrorResponse: (response, meta, arg) => {
				return response;
			},
			providesTags: (result, error, id) => {
				return [{ type: 'Orders', id }];
			}
		}),

		importOrders: builder.mutation({
			query: (body) => ({
				url: `orders/import_data/`,
				method: 'POST',
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: 'OrderSets', id: 'LIST' }, { type: 'OrderImports', id: 'LIST'}];
			},
		}),

		getExportOrders: builder.query({
			query: (args) => {
				let params = new URLSearchParams(omitBy(args, isNull)).toString();
				return `order-sets/export_data/?${params}`;
			},
			transformResponse: (response, meta, arg) => {
				return response;
			},
			providesTags: ['OrderExports'],
		}),

		getOrderExportById: builder.query({
			query: (id) => `export-inputs/${id}`,
			transformResponse: (response, meta, arg) => {
				return response;
			},
			providesTags: ['OrderExports'],
		}),

		createExportOrders: builder.mutation({
			query: (body) => ({
				url: `order-sets/create_export_data/`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['OrderExports'],
		}),

		createOrder: builder.mutation({
			query: (body) => ({
				url: `orders/`,
				method: 'POST',
				body,
			}),
			transformErrorResponse: (response, meta, arg) => {
				return response.data;
			},
			invalidatesTags: ['Orders'],
		}),

		updateOrder: builder.mutation({
			query: ({id, body}) => ({
				url: `orders/${id}/`,
				method: 'PATCH',
				body,
			}),
			transformErrorResponse: (response, meta, arg) => {
				return response.data;
			},
			invalidatesTags: ['Orders', 'Pickings'],
		}),

		pickingInOrders: builder.mutation({
			query: ({id, body}) => ({
				url: `pickings/${id}/picking_in_order/`,
				method: 'POST',
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: 'Pickings', id: arg.id }];
			},
		}),

		pickingOutOrders: builder.mutation({
			query: ({id, body}) => ({
				url: `pickings/${id}/picking_out_order/`,
				method: 'POST',
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: 'Pickings', id: arg.id }/*{ type: 'OperationalBlocks', id: arg.operationalBlock }*/];
			},
		}),

		pickingReturnOrders: builder.mutation({
			query: ({id, body}) => ({
				url: `pickings/${id}/picking_return_order/`,
				method: 'POST',
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: 'Pickings', id: arg.id }/*{ type: 'OperationalBlocks', id: arg.operationalBlock }*/];
			},
		}),

		createExternalOrder: builder.mutation({
			query: ({id, body}) => ({
				url: `pickings/${id}/create_external_order/`,
				method: 'POST',
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				return ['Pickings'];
			},
		}),

		getPickingStatusesTypes: builder.query({
			query: () => ({
				url: `pickings/get_statuses/`,
			}),
		}),

		deleteOrderEvent: builder.mutation({
			query: ({id, body, order}) => ({
				url: `order-events/${id}/delete/`,
				method: 'POST',
				body,
			}),
			transformErrorResponse: (response, meta, arg) => {
				return response.data;
			},
			invalidatesTags: (result, error, arg) => {
				return [{ type: 'Orders', id: arg.order }];
			},
		}),

		createOrderEvent: builder.mutation({
			query: ({body, order}) => ({
				url: `order-events/`,
				method: 'POST',
				body,
			}),
			transformErrorResponse: (response, meta, arg) => {
				return response.data;
			},
			invalidatesTags: (result, error, arg) => {
				return [{ type: 'Orders', id: arg.order }];
			},
		}),

		getOrderEventsTypes: builder.query({
			query: () => `order-events/get_events/`,
			providesTags: ['Events'],
		}),
		
		getOrderImports: builder.query({
			query: (args) => {
				let params = new URLSearchParams(omitBy(args, isNull)).toString();
				return `import-inputs/?${params}`;
			},
			/*transformResponse: (response, meta, arg) => {
				return response;
			},*/
			providesTags: (result, error, id) => {
				return [{ type: 'OrderImports', id: 'LIST'}];
			}
		}),

		getOrderImportById: builder.query({
			query: (id) => `import-inputs/${id}`,
			transformResponse: (response, meta, arg) => {
				return response;
			},
			providesTags: ['OrderImports'],
		}),

		getOrderImportOrderSets: builder.query({
			query: ({id, page}) => `import-inputs/${id}/get_order_sets/?page=${page}`,
			/*transformResponse: (response, meta, arg) => {
				return response;
			},*/
		}),

		getUsers: builder.query({
			query: (args) => {
				let params = new URLSearchParams(omitBy(args, isNull)).toString();
				return `users/?${params}`;
			},
			transformResponse: (response, meta, arg) => {
				return response;
			},
			providesTags: ['Users'],
		}),

		getAllUsers: builder.query({
			query: () => `users/get_all/`,
			transformResponse: (response, meta, arg) => {
				return response;
			},
		}),

		getUserProfile: builder.query({
			query: () => `users/profile/`,
			transformResponse: (response, meta, arg) => {
				return response;
			},
			providesTags: ['Users'],
		}),

		getUserGroups: builder.query({
			query: () => `users/get_user_groups/`,
			transformResponse: (response, meta, arg) => {
				return response;
			},
		}),

		updateUserPassword: builder.mutation({
			query: (body) => ({
				url: `update-user-password/`,
				method: 'PATCH',
				/*headers: {
					'content-type': 'text/plain',
				},*/
				body,
			}),
			transformErrorResponse: (response, meta, arg) => {
				let errorString;
				if(response.data instanceof Array){
					errorString = response.data.join(', ');
				} else if(response.data && response.data.non_field_errors && response.data.non_field_errors instanceof Array){
					errorString = response.data.non_field_errors.join(', ');
				} else if(response.error){
					errorString = response.error;
				} else {
					errorString = "Unknown error"
				}
				debugger;
				return errorString;
			},
		}),

		createUser: builder.mutation({
			query: (body) => ({
				url: `users/`,
				method: 'POST',
				/*headers: {
					'content-type': 'text/plain',
				},*/
				body,
			}),
			transformErrorResponse: (response, meta, arg) => {
				debugger;
				return response.data;
			},
			invalidatesTags: ['Users'],
		}),

		updateUser: builder.mutation({
			query: ({id, body}) => ({
				url: `users/${id}/`,
				method: 'PATCH',
				/*headers: {
					'content-type': 'text/plain',
				},*/
				body,
			}),
			transformErrorResponse: (response, meta, arg) => {
				return response.data;
			},
			invalidatesTags: ['Users'],
		}),

		deleteUser: builder.mutation({
			query: (id) => ({
				url: `users/${id}/`,
				method: 'DELETE'
			}),
			transformErrorResponse: (response, meta, arg) => {
				return response.data;
			},
			invalidatesTags: ['Users'],
		}),

		getUserById: builder.query({
			query: (id) => `users/${id}`,
			transformErrorResponse: (response, meta, arg) => {
				return response;
			},
			providesTags: ['Orders'],
		}),

		getDrivers: builder.query({
			query: (args) => {
				let params = new URLSearchParams(omitBy(args, isNull)).toString();
				return `drivers/?${params}`;
			},
			transformResponse: (response, meta, arg) => {
				return response;
			},
			providesTags: ['Drivers'],
		}),

		createDriver: builder.mutation({
			query: (body) => ({
				url: `drivers/`,
				method: 'POST',
				body,
			}),
			transformErrorResponse: (response, meta, arg) => {
				return response.data;
			},
			invalidatesTags: ['Drivers'],
		}),

		deleteDriver: builder.mutation({
			query: (id) => ({
				url: `drivers/${id}/`,
				method: 'DELETE'
			}),
			transformErrorResponse: (response, meta, arg) => {
				debugger;
				return response.data;
			},
			invalidatesTags: ['Drivers'],
		}),

		getDriverById: builder.query({
			query: (id) => `drivers/${id}`,
			transformErrorResponse: (response, meta, arg) => {
				return response;
			},
			providesTags: ['Drivers'],
		}),

		updateDriver: builder.mutation({
			query: ({id, body}) => ({
				url: `drivers/${id}/`,
				method: 'PUT',
				body,
			}),
			transformErrorResponse: (response, meta, arg) => {
				debugger;
				return response.data;
			},
			invalidatesTags: ['Users'],
		}),

		getOperationalBlocks: builder.query({
			query: (args) => {
				let params = new URLSearchParams(omitBy(args, isNull)).toString();
				return `operational-blocks/?${params}`;
			},
			transformResponse: (response, meta, arg) => {
				return response;
			},
			providesTags: ['OperationalBlocks'],
		}),

		getOperationalBlockById: builder.query({
			query: (id) => `operational-blocks/${id}`,
			transformErrorResponse: (response, meta, arg) => {
				if(response.data){
					return response.data.join(', ');
				} else if (response.error){
					return response.error;
				}
			},
			providesTags: (result, error, id) => {
				return [{ type: 'OperationalBlocks', id }];
			}
		}),

		getOperationalBlockPickeableOrders: builder.query({
			query: (id) => `operational-blocks/${id}/get_pickeable_orders`,
			transformErrorResponse: (response, meta, arg) => {
				if(response.data){
					return response.data.join(', ');
				} else if (response.error){
					return response.error;
				}
			},
			/*providesTags: (result, error, id) => {
				return [{ type: 'OperationalBlocks', id }];
			}*/
		}),

		getPickings: builder.query({
			query: (args) => {
				let params = new URLSearchParams(omitBy(args, isNull)).toString();
				return `pickings/?${params}`;
			},
			transformResponse: (response, meta, arg) => {
				return response;
			},
			providesTags: ['Pickings'],
		}),

		createPicking: builder.mutation({
			query: (body) => ({
				url: `pickings/`,
				method: 'POST',
				body,
			}),
			transformErrorResponse: (response, meta, arg) => {
				return response.data;
			},
			invalidatesTags: ['Pickings'],
		}),

		getPickingById: builder.query({
			query: (id) => `pickings/${id}`,
			transformErrorResponse: (response, meta, arg) => {
				return response;
			},
			providesTags: (result, error, id) => {
				return [{ type: 'Pickings', id }];
			}
		}),

		getPickingPickedOrders: builder.query({
			query: (id) => `pickings/${id}/get_picked_in_orders`,
			transformErrorResponse: (response, meta, arg) => {
				if(response.data){
					return response.data.join(', ');
				} else if (response.error){
					return response.error;
				}
			},
			providesTags: (result, error, id) => {
				return [{ type: 'Pickings', id }];
			}
		}),

		getPickingPickedOutOrders: builder.query({
			query: ({id, page}) => `pickings/${id}/get_picked_out_order_sets/?page=${page}`,
			transformErrorResponse: (response, meta, arg) => {
				if(response.data){
					return response.data.join(', ');
				} else if (response.error){
					return response.error;
				}
			},
			providesTags: (result, error, id) => {
				return [{ type: 'Pickings', id }];
			}
		}),

		getPublicPickings: builder.query({
			query: (args) => {
				let params = new URLSearchParams(omitBy(args, isNull)).toString();
				return `public-pickings/?${params}`;
			},
			transformResponse: (response, meta, arg) => {
				return response;
			},
			providesTags: ['Public-Pickings'],
		}),

		getPublicPickingById: builder.query({
			query: (id) => `public-pickings/${id}`,
			transformErrorResponse: (response, meta, arg) => {
				return response;
			},
			providesTags: (result, error, id) => {
				return [{ type: 'Public-Pickings', id }];
			}
		}),

		requestPickingReview: builder.mutation({
			query: ({id, body}) => ({
				url: `pickings/${id}/request_picking_completion/`,
				method: 'POST',
				body,
			}),
			transformErrorResponse: (response, meta, arg) => {
				return response.data;
			},
			invalidatesTags: (result, error, arg) => {
				return [{ type: 'Pickings', id: arg.id }];
			},
		}),

		getPickingCompletionRequestById: builder.query({
			query: (id) => `pickings-completion-request/${id}`,
			transformErrorResponse: (response, meta, arg) => {
				return response.data;
			},
			providesTags: (result, error, id) => {
				return [{ type: 'PickingCompletionRequests', id }];
			}
		}),

		getPickingIssueOrders: builder.query({
			query: ({id, page}) => `picking-issues/${id}/get_orders/?page=${page}`,
			transformErrorResponse: (response, meta, arg) => {
				return response.data;
			},
		}),

		getPickingIssueOrderSets: builder.query({
			query: ({id, page}) => `picking-issues/${id}/get_order_sets/?page=${page}`,
			transformErrorResponse: (response, meta, arg) => {
				return response.data;
			},
		}),

		authorizePicking: builder.mutation({
			query: ({id, body, pickingId}) => ({
				url: `pickings-completion-request/${id}/authorize_completion/`,
				method: 'POST',
				body,
			}),
			transformErrorResponse: (response, meta, arg) => {
				return response.data;
			},
			invalidatesTags: (result, error, arg) => {
				return [{ type: 'PickingCompletionRequests', id: arg.id }];
			},
		}),

		denyPicking: builder.mutation({
			query: ({id, body, pickingId}) => ({
				url: `pickings-completion-request/${id}/deny_completion/`,
				method: 'POST',
				body,
			}),
			transformErrorResponse: (response, meta, arg) => {
				return response.data;
			},
			invalidatesTags: (result, error, arg) => {
				return [{ type: 'PickingCompletionRequests', id: arg.id }];
			},
		}),

		getCompanies: builder.query({
			query: (args) => {
				let params = new URLSearchParams(omitBy(args, isNull)).toString();
				return `companies/?${params}`;
			},
			transformResponse: (response, meta, arg) => {
				return response;
			},
			providesTags: ['Companies'],
		}),

		getAllCompanies: builder.query({
			query: () => `companies/get_all_companies/`,
			transformResponse: (response, meta, arg) => {
				return response;
			},
			providesTags: ['Companies'],
		}),

		getCompanyById: builder.query({
			query: (id) => `companies/${id}`,
			transformErrorResponse: (response, meta, arg) => {
				return response;
			},
			providesTags: ['Companies'],
		}),

		createCompany: builder.mutation({
			query: (body) => ({
				url: `companies/`,
				method: 'POST',
				body,
			}),
			transformErrorResponse: (response, meta, arg) => {
				return response.data;
			},
			invalidatesTags: ['Companies'],
		}),

		updateCompany: builder.mutation({
			query: ({id, body}) => ({
				url: `companies/${id}/`,
				method: 'PATCH',
				body,
			}),
			transformErrorResponse: (response, meta, arg) => {
				return response.data;
			},
			invalidatesTags: ['Companies'],
		}),

		deleteCompany: builder.mutation({
			query: (id) => ({
				url: `companies/${id}/`,
				method: 'DELETE'
			}),
			transformErrorResponse: (response, meta, arg) => {
				return response.data;
			},
			invalidatesTags: ['Companies'],
		}),

		getFilterSetting: builder.query({
			query: () => `filter-settings`,
			transformResponse: (response, meta, arg) => {
				return response;
			},
		}),

		updateFilterSetting: builder.mutation({
			query: ({id, ...body}) => ({
				url: `filter-settings/update_filter/`,
				method: 'PATCH',
				body,
			}),
			transformErrorResponse: (response, meta, arg) => {
				return response.data;
			},
		}),

		getPublicOrderSetById: builder.query({
			query: (id) => `public-order-sets/${id}`,
			transformErrorResponse: (response, meta, arg) => {
				return response;
			},
			providesTags: (result, error, id) => {
				return [{ type: 'Orders', id }];
			}
		}),

		getPublicOrderSets: builder.query({
			query: (args) => {
				let params = new URLSearchParams(omitBy(args, isNull)).toString();
				return `public-order-sets/?${params}`;
			},
			transformErrorResponse: (response, meta, arg) => {
				return response;
			},
			providesTags: (result, error, id) => {
				return [{ type: 'Orders', id }];
			}
		}),
		
	}),
})

export const {
	useAuthMutation,
	useDriverSearchMutation,
	useGetOperationalBlocksQuery,
	useGetOperationalBlockByIdQuery,
	useGetOperationalBlockPickeableOrdersQuery,
	useGetCollectTicketByIdQuery,
	useGetCollectTicketsQuery,
	useUpdateCollectTicketMutation,
	useCreateCollectTicketMutation,
	useCreateOutOfBoxMutation,
	useGetOutOfBoxesQuery,
	useGetOrderSetByIdQuery,
	useGetOrderSetsQuery,
	useUpdateOrderSetMutation,
	useDeleteOrderSetMutation,
	useCreateOrderSetMutation,
	useUpdateOrderSetsMutation,
	usePickingReturnOrderSetsMutation,
	useGetOrderSetStatusesTypesQuery,
	useGetOrderStatusesTypesQuery,
	useCreateOrderMutation,
	useUpdateOrderMutation,
	useGetOrderByIdQuery,
	usePickingInOrdersMutation,
	usePickingOutOrdersMutation,
	usePickingReturnOrdersMutation,
	useCreateExternalOrderMutation,
	useCreateOrderEventMutation,
	useDeleteOrderEventMutation,
	useGetOrderEventsTypesQuery,
	useGetOrderImportsQuery,
	useGetOrderImportByIdQuery,
	useGetOrderImportOrderSetsQuery,
	useImportOrdersMutation,
	useGetExportOrdersQuery,
	useCreateExportOrdersMutation,
	useGetOrderExportByIdQuery,
	useGetUsersQuery,
	useGetAllUsersQuery,
	useGetUserByIdQuery,
	useGetUserProfileQuery,
	useGetUserGroupsQuery,
	useUpdateUserPasswordMutation,
	useCreateUserMutation,
	useUpdateUserMutation,
	useDeleteUserMutation,
	useGetDriversQuery,
	useGetDriverByIdQuery,
	useCreateDriverMutation,
	useUpdateDriverMutation,
	useDeleteDriverMutation,
	useGetPickingsQuery,
	useCreatePickingMutation,
	useRequestPickingReviewMutation,
	useGetPickingCompletionRequestByIdQuery,
	useGetPickingIssueOrdersQuery,
	useGetPickingIssueOrderSetsQuery,
	useGetPickingStatusesTypesQuery,
	useAuthorizePickingMutation,
	useDenyPickingMutation,
	useGetPickingByIdQuery,
	useGetPickingPickedOrdersQuery,
	useGetPickingPickedOutOrdersQuery,
	useGetCompaniesQuery,
	useGetAllCompaniesQuery,
	useGetCompanyByIdQuery,
	useCreateCompanyMutation,
	useUpdateCompanyMutation,
	useDeleteCompanyMutation,
	useGetFilterSettingQuery,
	useUpdateFilterSettingMutation,
	useGetPublicPickingsQuery,
	useGetPublicPickingByIdQuery,
	useGetPublicOrderSetByIdQuery,
	useGetPublicOrderSetsQuery
} = orderApi