import React, { useEffect, useState, useMemo, useCallback } from "react";
import { withTranslation } from 'react-i18next';
import { hasGroup } from "../../api/session_helper";
import {
  Input,
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  ModalHeader,
  Modal,
  ModalBody,
  Row,
  Form,
  Label,
  FormFeedback
} from "reactstrap";
import * as moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone";
import { useAsyncDebounce } from "react-table";
import { useFormik } from "formik";
import Flatpickr from "react-flatpickr";
import { isEmpty } from "lodash";

import BreadCrumb from "../../Components/Commons/BreadCrumb";
import OperationalBlockTable from "./OperationalBlockTable";
import Pagination from "../../Components/Commons/Pagination";

import ErrorModal from "../../Components/Commons/ErrorModal";
import ConfirmModal from "../../Components/Commons/ConfirmModal";
import ImportOrdersModal from "../OrderImports/ImportOrdersModal";

import 'react-toastify/dist/ReactToastify.css';

import { 
  useGetOperationalBlocksQuery,
  useImportOrdersMutation 
} from '../../api/api'

const OperationalBlocks = ({ t, i18n }) => {

  let navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [creationStartDate, setCreationStartDate] = useState(null);
  const [creationEndDate, setCreationEndDate] = useState(null);
  const [billingStartDate, setBillingStartDate] = useState(null);
  const [billingEndDate, setBillingEndDate] = useState(null);
  const [campaign, setCampaign] = useState(null);

  const [blockList, setBlockList] = useState(null);

  const [modal, setModal] = useState(false);
	
	const [importedInput, setImportedInput] = useState(null);
	const [ordersImportedModal, setOrdersImportedModal] = useState(false);

	const [errorModal, setErrorModal] = useState(false);
	const [errorTitle, setErrorTitle] = useState(false);
	const [errorDescription, setErrorDescription] = useState(false);

  const { 
    data: blocksPages,
    error,
    isFetching: blocksLoading,
    isSuccess: blocksSuccess
  } = useGetOperationalBlocksQuery(
    { 
      page: currentPage,
      billingStartDate: billingStartDate,
      billingEndDate: billingEndDate,
      creationStartDate: creationStartDate,
      creationEndDate: creationEndDate,
      campaign: campaign
    }, 
    {
      refetchOnMountOrArgChange: true,
      skip: false,
    }
  );

  /*useEffect(() => {
		if (isImportSuccess) {
			let data = importObject;
			navigate(`../pickings/${data.input_reference}`);
		}
	}, [isImportSuccess]);*/

  useEffect(() => {
    if (blocksPages) {
      setBlockList(blocksPages.results);
    }
  }, [blocksPages]);

  const handleSuccessImported = (importObject) => {
		setImportedInput(importObject);
		setOrdersImportedModal(true);
	};

	const handleNavigateToInput = () => {
		let data = importedInput;
		navigate(`../orders-import/${data.input_reference}`);
	};

	const handleImportedError = (error) => {
	let errorString;
				
		if(error.data instanceof Array){
			errorString = error.data.join(', ');
		} else if(error.error){
			errorString = error.error;
		} else {
			errorString = "Unknown error"
		}
		setModal(false);
		setErrorTitle("Error when trying to import");
		setErrorDescription(errorString);
		setErrorModal(true);
	};

  document.title = "Sistema de pedidos Transchile";

  return (
    <div className="page-content">
      <Container fluid>

        <BreadCrumb title={t("Billing Days list")} pageTitle={t("Orders")} />
        <ImportOrdersModal 
          show={modal}
          onClose={() => setModal(false)}
          onOrderImportSuccess={handleSuccessImported}
          onOrderImportError={handleImportedError}
        />
        <ErrorModal
          show={errorModal}
          onCloseClick={() => setErrorModal(false)}
          title={errorTitle}
          description={errorDescription}
        />
        <ConfirmModal
          show={ordersImportedModal}
          onConfirmClick={handleNavigateToInput}
          onCloseClick={() => setOrdersImportedModal(false)}
          title={t("Order import created")}
          question={t("You will be redirected to the import detail so you can see the progress.")}
          yesQuestion={t("Ok")}
        />
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <CardHeader className="border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("Billing Days")}</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div>
                      {hasGroup(['Manager', 'OperationalManager', 'RouteManager']) && (
                        <button
                          type="button"
                          className="btn btn-success add-btn"
                          id="create-btn"
                          onClick={() => { setModal(true); }}
                        >
                          <i className="ri-download-line align-bottom me-1"></i> {t("Import Orders")}
                        </button>
                      )}
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <Row className="mb-3">
                    <CardBody className="border border-dashed border-end-0 border-start-0 mt-3">
                      <form>
                        <Row>
                          <Col md={3}>
                            <Flatpickr
                              className="form-control"
                              id="datepicker-created-at"
                              placeholder={t("Created at")}
                              options={{
                                altInput: true,
                                altFormat: "F j, Y",
                                mode: "range",
                                dateFormat: "DD-MM-YYYY",
                              }}
                              onChange={(date) => {
                                setCurrentPage(1);
                                if(date[0]!== undefined){
                                  const date1 = moment(date[0]).format("DD-MM-YYYY");
                                  setCreationStartDate(date1);
                                } else {
                                  setCreationStartDate(null);
                                }
                                if(date[1]!== undefined){
                                  const date1 = moment(date[1]).format("DD-MM-YYYY");
                                  setCreationEndDate(date1);
                                } else {
                                  setCreationEndDate(null);
                                }
                              }}
                            />
                          </Col>
                    
                          <Col md={3}>
                            <Flatpickr
                              className="form-control"
                              id="datepicker-billing-date"
                              placeholder={t("Billing Day")}
                              options={{
                                altInput: true,
                                altFormat: "F j, Y",
                                mode: "range",
                                dateFormat: "DD-MM-YYYY",
                              }}
                              onChange={(date) => {
                                setCurrentPage(1);
                                if(date[0]!== undefined){
                                  const date1 = moment(date[0]).format("DD-MM-YYYY");
                                  setBillingStartDate(date1);
                                } else {
                                  setBillingStartDate(null);
                                }
                                if(date[1]!== undefined){
                                  const date1 = moment(date[1]).format("DD-MM-YYYY");
                                  setBillingEndDate(date1);
                                } else {
                                  setBillingEndDate(null);
                                }
                              }}
                            />
                          </Col>
                    
                          <Col md={3}>
                            <Input
                              name="campaignId"
                              id="id-field"
                              className="form-control"
                              placeholder={t("Campaign Id")}
                              type="text"
                              validate={{
                                required: { value: true },
                              }}
                              onChange={(e) => {
                                setCurrentPage(1);
                                setCampaign(e.target.value && e.target.value.length > 0 ? e.target.value : null);
                              }}
                            />
                          </Col>
                        </Row>
                        
                      </form>
                    </CardBody>
                  </Row>
                  
                  <OperationalBlockTable
                    data={(blockList || [])}
                    isLoading={blocksLoading}
                    isSuccess={blocksSuccess}
                    error={error}
                    isGlobalFilter={true}
                    totalDataLength={( blocksPages !== undefined ? blocksPages.count : 0)}
                    customPageSize={25}
                    divClass="table-responsive table-card"
                    tableClass="align-middle table-nowrap"
                    theadClass="table-light text-muted"
                    isOrderFilter={true}
                    SearchPlaceholder='Search for order ID, customer'
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {blocksPages && !isEmpty(blocksPages.results) && (
          <Pagination
            totalRows={blocksPages.count}
            pageChangeHandler={setCurrentPage}
            rowsPerPage={25}
          />
        )}
      </Container>
    </div>
  );
};

export default withTranslation()(OperationalBlocks);

