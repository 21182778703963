import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Spinner, Alert  } from 'reactstrap';
import AuthSlider from '../authCarousel';

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import * as Yup from "yup";
import { useFormik } from "formik";
import Cookies from 'js-cookie';

import { hasGroup } from "../../../api/session_helper";

import { 
	useAuthMutation, 
	useGetUserProfileQuery,
	useDriverSearchMutation
} from '../../../api/api'


const CoverSignIn = () => {

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [userLogin, setUserLogin] = useState([]);
	const [passwordShow, setPasswordShow] = useState(false);
	const [skipProfile, setSkipProfile] = useState(true);

	const [auth, 
		{ error: errorMsg, 
			isLoading: loading,
			isSuccess: isAuthSuccess,
			data: user
		}] = useAuthMutation();
	
	const { 
		data: profile,
		error: profileError,
		isFetching: profileLoading,
		isSuccess: profileSuccess
	} = useGetUserProfileQuery(
		{
			refetchOnMountOrArgChange: true,
			skip: skipProfile,
		}
	);
	
	useEffect(() => {
		if (isAuthSuccess) {
			Cookies.set('authUser', JSON.stringify(user));
			debugger;

			setSkipProfile(false);
		}
	}, [isAuthSuccess]);

	useEffect(() => {
		if (profileSuccess) {			
			Cookies.set('first_name', profile.first_name);
			Cookies.set('last_name', profile.last_name);
			Cookies.set('email', profile.email);
			Cookies.set('groups', JSON.stringify(profile.groups));
			if(hasGroup(['CompanyAdministrator'])){
				navigate('/client-order-sets');
			} else {
				navigate('/logout');
			}
		}
	}, [profileSuccess]);

	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,

		initialValues: {
			email: userLogin.email || '',
			password: userLogin.password || '',
		},
		validationSchema: Yup.object({
			email: Yup.string().required("Please Enter Your Email"),
			password: Yup.string().required("Please Enter Your Password"),
		}),
		onSubmit: (values) => {
			const login = {
				username: values["email"],
				password: values["password"]
			};
			const formData = new FormData();
			formData.append("username", values["email"])
			formData.append("password", values["password"]);
			auth(formData);
		}
	});

	document.title="Login Clientes | Sistema de pedidos Transchile";

	return (
		<React.Fragment>
			<div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
				<div className="bg-overlay"></div>
				<div className="auth-page-content overflow-hidden pt-lg-5">
					<Container>
						<Row>
							<Col lg={12}>
								<Card className="overflow-hidden">
									<Row className="g-0">
										<AuthSlider />

										<Col lg={6}>
											<div className="p-lg-5 p-4">
												<div>
													<h5 className="text-primary">Inicio de sesión clientes</h5>
													<p className="text-muted">Ingresa para revisar el estado de tu empresa.</p>
												</div>
												<div className="mt-2 mb-4 pb-4">
													{errorMsg && errorMsg ? (<Alert color="danger"> {errorMsg} </Alert>) : null}
													<Form
														onSubmit={(e) => {
															e.preventDefault();
															validation.handleSubmit();
															return false;
														}}
														action="#">

														<div className="mb-3">
															<Label htmlFor="email" className="form-label">Correo</Label>
															<Input
																name="email"
																className="form-control"
																placeholder="Enter email"
																type="email"
																onChange={validation.handleChange}
																onBlur={validation.handleBlur}
																value={validation.values.email || ""}
																invalid={
																	validation.touched.email && validation.errors.email ? true : false
																}
															/>
															{validation.touched.email && validation.errors.email ? (
																<FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
															) : null}
														</div>

														<div className="mb-3">
															<Label className="form-label" htmlFor="password-input">Contraseña</Label>
															<div className="position-relative auth-pass-inputgroup mb-3">
																<Input
																	name="password"
																	value={validation.values.password || ""}
																	type={passwordShow ? "text" : "password"}
																	className="form-control pe-5"
																	placeholder="Enter Password"
																	onChange={validation.handleChange}
																	onBlur={validation.handleBlur}
																	invalid={
																			validation.touched.password && validation.errors.password ? true : false
																	}
																/>
																{validation.touched.password && validation.errors.password ? (
																	<FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
																) : null}
																<button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><i className="ri-eye-fill align-middle"></i></button>
															</div>
														</div>

														<div className="mt-4">
															<Button color="success" disabled={errorMsg ? null : loading ? true : false} className="btn btn-success w-100" type="submit">
																{loading ? <Spinner size="sm" className='me-2'> Cargando... </Spinner> : null}
																Iniciar Sesión
															</Button>
														</div>
													</Form>
												</div>

											</div>
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>

				<footer className="footer">
					<Container>
						<Row>
							<Col lg={12}>
								<div className="text-center">
									<p className="mb-0">&copy; {new Date().getFullYear()} Transchile. Disgned and Developed by Optiroute</p>
								</div>
							</Col>
						</Row>
					</Container>
				</footer>

			</div>
		</React.Fragment>
	);
};

export default CoverSignIn;