import React, { useEffect, useState, useCallback, useRef } from "react";
import { withTranslation } from 'react-i18next';
import {
	Input,
	Card,
	CardBody,
	Col,
	Container,
	CardHeader,
	Nav,
	NavItem,
	NavLink,
	Row,
	Badge,
	Spinner,
	Modal,
	ModalHeader,
	ModalBody,
	Form,
	Label,
	FormFeedback
} from "reactstrap";
import { isEmpty } from "lodash";
import * as moment from "moment";
import { Link } from "react-router-dom";
import { hasGroup } from "../../api/session_helper";
import classnames from "classnames";
import BreadCrumb from "../../Components/Commons/BreadCrumb";
import Pagination from "../../Components/Commons/Pagination";
import OrderSetsTable from "./OrderSetsTable";
import DeleteModal from "../../Components/Commons/DeleteModal";
import ConfirmModal from "../../Components/Commons/ConfirmModal";
import MultipleSelectModal from "./MultipleSelectModal"

import { useAsyncDebounce } from "react-table";
import { useFormik } from "formik";

import Flatpickr from "react-flatpickr";

import Select from "react-select";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as FileSaver from "file-saver";


import { omitBy, isNull } from "lodash";
import Cookies from 'js-cookie';
import axios from "axios";

import {
	orderApi,
	useGetOrderSetsQuery, 
	useUpdateOrderSetsMutation,
	usePickingReturnOrderSetsMutation,
	useDeleteOrderSetMutation,
	useGetOrderSetStatusesTypesQuery,
	useGetOrderStatusesTypesQuery,
	useGetOrderEventsTypesQuery,
	useGetOrderExportByIdQuery,
	useCreateExportOrdersMutation
 } from '../../api/api'
 

const OrderSets = ({ t, i18n }) => {

	const ref = useRef(null);

	const [orderSearchDefault, setOrderSearchDefault] = useState(null);
	// the variable with debounce
	const [orderSearch, setOrderSearch] = useState(null);

	const [currentPage, setCurrentPage] = useState(1);
	const [currentPageSize, setCurrentPageSize] = useState(25);

	// USED AS DELIVERY DATE
	const [creationStartDate, setCreationStartDate] = useState(null);
	const [creationEndDate, setCreationEndDate] = useState(null);

	const [billingStartDate, setBillingStartDate] = useState(null);
	const [billingEndDate, setBillingEndDate] = useState(null);
	const [driver, setDriver] = useState(null);
	const [campaign, setCampaign] = useState(null);
	const [zone, setZone] = useState(null);
	const [orderSetsStatuses, setOrderSetsStatuses] = useState(null);
	const [ordersStatuses, setOrdersStatuses] = useState(null);
	const [boxesAmount, setBoxesAmount] = useState(null);
	const [outBoxesAmount, setOutBoxesAmount] = useState(null);
	const [withCollect, setWithCollect] = useState(null);
	const [kind, setKind] = useState(null);

	const [activeTab, setActiveTab] = useState("1");

	const [selectedItems, setSelectedItems] = useState([]);
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
	const [deleteModalMulti, setDeleteModalMulti] = useState(false);
	const [modal, setModal] = useState(false);
	const [backOrderModal, setBackOrderModal] = useState(false);
	const [selectModalMulti, setSelectModalMulti] = useState(false);

	const [loadingDownload, setLoadingDownload] = useState(false);

	const [skipPolling, setSkipPolling] = useState(false);
	const [exportQuery, setExportQuery] = useState(null);
	const [exportReference, setExportReference] = useState(null);
	
	const [updateOrderSets, 
		{ error: errors, 
			isLoading: isUpdateMultipleLoading,
			isSuccess: isUpdateMultipleSuccess,
			data: updateOrderSetsData
		}] = useUpdateOrderSetsMutation();

	const [pickingReturnOrderSets, 
		{ error: pickingReturnErrors, 
			isLoading: isPickingReturnLoading,
			isSuccess: isPickingReturnSuccess,
			data: pickingReturnOrderSetsData
		}] = usePickingReturnOrderSetsMutation();

	const [deleteOrderSet, 
		{ error: deleteErrors, 
			isLoading: isDeleteLoading,
			isSuccess: isDeleteSuccess,
			data: delteOrderSetsData
		}] = useDeleteOrderSetMutation();

	const { 
		data: orderEventsTypes,
		error: orderEventsTypesErrors,
		isFetching: isOrderEventsTypesLoading,
		isSuccess: isOrderEventsTypesSuccess
	} = useGetOrderEventsTypesQuery(
		{
			refetchOnMountOrArgChange: true,
			skip: backOrderModal == null || !backOrderModal,
		}
	);

	const toggle = useCallback(() => {
		if (modal) {
			setModal(false);
			validation.resetForm();
		} else {
			setModal(true);
		}
	}, [modal]);
	
	const [
		createExportOrders, 
		{
			error: isOrdersExportCreatedError,
			isLoading: isOrdersExportCreatedLoading,
			isSuccess: isOrdersExportCreatedSuccess,
			data: ordersExportCreated
		}] = useCreateExportOrdersMutation();

	const { 
		data: ordersExport,
		error: isOrdersExportError,
		isFetching: isOrdersExportLoading,
		isSuccess: isOrdersExportSuccess
	} = useGetOrderExportByIdQuery(
		exportReference,
		{
			refetchOnMountOrArgChange: true,
			pollingInterval: exportReference ? 5000 : 0,
			skip: exportReference == null
		}
	);

	useEffect(() => {
		if (isOrdersExportCreatedSuccess) {
			console.log("isOrdersExportCreatedSuccess: " + isOrdersExportCreatedSuccess + " ordersExportCreated: " + ordersExportCreated.input_reference);
			setLoadingDownload(true);
			//setExportedInputCreated(ordersExportCreated);
			setExportReference(ordersExportCreated.input_reference);
		}
	}, [isOrdersExportCreatedSuccess, ordersExportCreated, exportQuery]);

	/*
	ERROR = 1
    ENTERED = 2
    IN_PROGRESS = 3
    SUCCESS = 4
    VALIDATION_ERROR = 5
	*/

	useEffect(() => {
		if (ordersExport) {

			console.log(ordersExport + ": " + ordersExport.file_url);
			
			if(ordersExport.status === 4){
				setExportQuery(null);
				setExportReference(null);
				setLoadingDownload(false);
				window.open(ordersExport.file_url, "_blank");

				orderApi.util.invalidateTags([
					'OrderExports']);
			}

			if(ordersExport.status === 1){
				setExportQuery(null);
				setExportReference(null);
				setLoadingDownload(false);
			}

			if(ordersExport.status === 5){
				setExportQuery(null);
				setExportReference(null);
				setLoadingDownload(false);
			}
		}
	}, [ordersExport]);
	
	// Checked All
	/*const checkedAll = useCallback(() => {
		const checkall = document.getElementById("checkBoxAll");
		const ele = document.querySelectorAll(".orderCheckBox");

		if (checkall.checked) {
			ele.forEach((ele) => {
				ele.checked = true;
			});
		} else {
			ele.forEach((ele) => {
				ele.checked = false;
			});
		}
		deleteCheckbox();
	}, []);

	const deleteMultiple = () => {
		const checkall = document.getElementById("checkBoxAll");
		selectedCheckBoxDelete.forEach((element) => {
			dispatch(onDeleteOrder(element.value));
			setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
		});
		checkall.checked = false;
		setIsMultiDeleteButton(false);
	};

	const deleteCheckbox = () => {
		const ele = document.querySelectorAll(".orderCheckBox:checked");
		ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
		setSelectedCheckBoxDelete(ele);
	};*/

	const toggleTab = (tab, type) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	const onMultipleSelected = (selected) => {
		setSelectedItems(selected);
		if(hasGroup(['Manager', 'OperationalManager', 'RouteManager'])){
			setIsMultiDeleteButton(selected.length > 0);
		}
	}

	const onSingleDeletePressed = (selected) => {
		setSelectedOrder(selected);
		setDeleteModalMulti(true);
	}

	const doDeletion = () => {
		if(selectedOrder){
			deleteOrderSet({id: selectedOrder.id});
		} else if(selectedItems && selectedItems.length > 0){
			const batch = {
				ids: selectedItems,
				data: {
					status: -4,
				}
			}
			updateOrderSets({
				body: batch 
			});
		}
		setSelectedItems([]);
		setSelectedOrder(null);
		setDeleteModalMulti(false);
		const ele = document.querySelectorAll(".orderCheckBox");
		ele.forEach((ele) => {
			ele.checked = false;
		});
	};

	const doBackToWarehouse = () => {
		if(selectedItems && selectedItems.length > 0){
			const batch = {
				ids: selectedItems,
			}
			pickingReturnOrderSets({
				body: batch 
			});
		}
		setBackOrderModal(false);
	};

	const handleOrderSetState = (selection) => {
		setCurrentPage(1);
		let orderSetsStatuses = selection.map((status, key) => {
			return status.value;
		});
		setOrderSetsStatuses(orderSetsStatuses.length > 0 ? orderSetsStatuses : null);
	};

	const withCollectOptions = [
		{value: true, label: t('With collect')},
		{value: false, label: t('Without collect')}
	];
	
	const { 
		data: orderSetsPages,
		error,
		isFetching: isOrderSetLoading,
		isSuccess: isOrderSetSuccess
	} = useGetOrderSetsQuery(
		{ 
			page: currentPage,
			filter: orderSearch,
			billingStartDate: billingStartDate,
			billingEndDate: billingEndDate,
			deliveredStartDate: creationStartDate,
			deliveredEndDate: creationEndDate,
			campaign: campaign,
			zone: zone,
			onlyCompanyAssigned: activeTab === "1",
			orderSetsStatuses: orderSetsStatuses,
			ordersStatuses: ordersStatuses,
			withCollect: withCollect,
			withBoxesAmounts: boxesAmount,
			withOutBoxesAmount: outBoxesAmount,
			kind: kind,
			driver: driver,
			page_size: currentPageSize
		},
		{
			refetchOnMountOrArgChange: true,
			skip: false,
		}
	);

	const { 
		data: orderSetStatuses,
		error: orderSetStatusesErrors,
		isFetching: isOrderSetStatusesLoading,
		isSuccess: isOrderSetStatusesSuccess
	} = useGetOrderSetStatusesTypesQuery(
		{
			refetchOnMountOrArgChange: true,
			skip: false
		}
	);

	const onChange = useAsyncDebounce((value) => {
		setOrderSearch(value);
	}, 200);

	const onDriverChange = useAsyncDebounce((value) => {
		setDriver(value);
	}, 200);

	const validation = useFormik({
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: false,

		initialValues: {
			reference: '',
			campaign_reference: '',
			billing_date: '',
			box_quantity: '',
			quantity: '',
			zone: '',
			territory: '',
			afp_name: '',
			status: ''
		},

		validationSchema: Yup.object({
			quantity: Yup.number().moreThan(-1),
			afp_name: Yup.string(),
		}),

		onSubmit: (values) => {
			const newOrder = {
				campaign_reference: values["campaign_reference"],
				billing_date: values["billing_date"],
				box_quantity: values["box_quantity"],
				quantity: values["quantity"],
				zone: values["zone"],
				territory: values["territory"],
				afp_name: values["afp_name"],
				status: values["status_id"],
			};
			const newOrderFiltered = Object.entries(newOrder).reduce(
				(acc, [k, v]) => v || v != "" ? {...acc, [k]:v} : acc , {});
			if(Object.keys(newOrderFiltered).length > 0){
				const batch = {
					ids: selectedItems,
					data: newOrderFiltered
				}
				updateOrderSets({
					body: batch 
				});
				validation.resetForm();
				toggle();
			}
		},
	});

	const handleExport = () => {
		let paramsObj = {
			page: currentPage,
			filter: orderSearch,
			billingStartDate: billingStartDate,
			billingEndDate: billingEndDate,
			creationStartDate: creationStartDate,
			creationEndDate: creationEndDate,
			campaign: campaign,
			zone: zone,
			onlyCompanyAssigned: activeTab === "1",
			orderSetsStatuses: orderSetsStatuses,
			ordersStatuses: ordersStatuses,
			withCollect: withCollect,
			withBoxesAmounts: boxesAmount,
			withOutBoxesAmount: outBoxesAmount,
			kind: kind
		}
		createExportOrders(paramsObj);
		setLoadingDownload(true);

		/*if(Cookies.get("authUser")){
			let URLparams = new URLSearchParams(omitBy(paramsObj, isNull)).toString();
			let token = JSON.parse(Cookies.get("authUser")).token;
			setLoadingDownload(true);
			axios.get(`order-sets/export_data/?${URLparams}`, 
				{
					headers: { 'Authorization': `Token ${token}` },
					responseType: 'blob'
				}).then((response) => {
					setLoadingDownload(false);
					saveAs(response, `pedidos_${Date.now()}.xls`);
				});
		}*/
	};

	const handlePageSizeChange = (pageSize) => {
		setCurrentPage(1);
		setCurrentPageSize(pageSize);
		setSelectedItems([]);
		const checkall = document.getElementById("checkBoxAll");
		const ele = document.querySelectorAll(".orderCheckBox");
		ele.forEach((ele) => {
			ele.checked = false;
		});
		checkall.checked = false;
	};

	document.title = "Sistema de pedidos Transchile";

	return (
		<div className="page-content">
			<DeleteModal
				show={deleteModalMulti}
				onDeleteClick={() => doDeletion()}
				onCloseClick={() => {
					setDeleteModalMulti(false);
					setSelectedOrder(null);
				}}
				isMultiple={selectedOrder === null && selectedItems && selectedItems.length > 0}
			/>
			<MultipleSelectModal
				isOpen={selectModalMulti}
				onConfirmClick={(items) => {
					const str = items.join(' ');
					ref.current.value = str;
					setOrderSearch(str);
					setOrderSearchDefault(str);
				}}
				onCloseClick={() => {
					setSelectModalMulti(false);
				}}
				defaultItemsString={orderSearchDefault}
			/>
			<ConfirmModal
				show={backOrderModal}
				onConfirmClick={() => doBackToWarehouse()}
				onCloseClick={() => setBackOrderModal(false)}
				title={t("Return orders to the warehouse")}
				question={t("Do you want to return selected orders to the warehouse? The events 'Returned by driver' and 'Warehouse' will be assigned.")}
				yesQuestion={t("yes")}
			/>
			<Container fluid>
				<BreadCrumb title={t("Orders List")} pageTitle={t("Orders")} />
				<Row>
					<Col lg={12}>
						<Card id="orderList">
							<CardHeader className="border-0">
								<Row className="align-items-center gy-3">
									<div className="col-sm">
										<h5 className="card-title mb-0">{t("Orders")} <Badge color="primary" className="ms-1">{orderSetsPages != null ? orderSetsPages.count : '...'}</Badge></h5>
										<h5 className="card-title mb-0">{t("Boxes")} <Badge color="secondary" className="ms-1">{orderSetsPages != null ? orderSetsPages.orders_count : '...'}</Badge></h5>
									</div>
									<div className="col-sm-auto">
										<div className="d-flex gap-1 flex-wrap sp-4">
											{/*hasGroup(['Manager', 'OperationalManager', 'RouteManager']) && (
												<>
													<Link to="../collect-ticket/new" className="btn btn-outline-success"><i className="ri-add-line align-bottom me-1"></i> {t("Create Collect Ticket")}</Link>
												</>
											)*/}
											{hasGroup(['Manager', 'OperationalManager', 'RouteManager']) && (
												<>
													<Link to="new" className="btn btn-success"><i className="ri-add-line align-bottom me-1"></i> {t("Create Order")}</Link>
												</>
											)}
											<button 
												type="button"
												className="btn btn-info"
												disabled={loadingDownload}
												onClick={() => handleExport()}>
												{loadingDownload && <Spinner size='sm' color="primary"/>}
												{!loadingDownload && <i className="ri-file-download-line align-bottom me-1"></i>}
												{t("Export")}
											</button>
											{isMultiDeleteButton && hasGroup(['Manager', 'OperationalManager', 'RouteManager']) && (
												<button
													className="btn btn-soft-danger"
													onClick={() => setDeleteModalMulti(true)}>
													<i className="ri-delete-bin-2-line"></i>
												</button>
											)}
											{isMultiDeleteButton && hasGroup(['Manager', 'OperationalManager', 'RouteManager']) && (
												<button 
													className="btn btn-soft-primary"
													onClick={() => setModal(true)}>
													<i className="ri-pencil-line"></i>
												</button>
											)}
											{isMultiDeleteButton && hasGroup(['Manager', 'OperationalManager', 'RouteManager']) && (
												<button 
													className="btn btn-soft-secondary"
													onClick={() => setBackOrderModal(true)}>
													<i className="ri-arrow-go-back-line"></i>
												</button>
											)}
										</div>
									</div>
								</Row>
							</CardHeader>
							<CardBody className="pt-0">
								<div>
									<Nav
										className="nav-tabs nav-tabs-custom nav-success"
										role="tablist"
									>
										<NavItem>
											<NavLink
												className={classnames(
													{ active: activeTab === "1" },
													"fw-semibold"
												)}
												onClick={() => {
													toggleTab("1", "all");
												}}
												href="#"
											>
												<i className="ri-store-2-fill me-1 align-bottom"></i>{" "}
												{t("Transchile Orders")}
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames(
													{ active: activeTab === "2" },
													"fw-semibold"
												)}
												onClick={() => {
													toggleTab("2", "Delivered");
												}}
												href="#"
											>
												<i className="ri-checkbox-circle-line me-1 align-bottom"></i>{" "}
												{t("All Orders")}
											</NavLink>
										</NavItem>
									</Nav>
									<Row className="mb-3">
										<CardBody className="border border-dashed border-end-0 border-start-0">
											<form>
												<Row>
													<Col md={3}>
														<div className="input-group">
															<Input
																name="orderId"
																id="id-field"
																className="form-control"
																placeholder={t("Search order or customer")}
																type="text"
																ref={ref}
																value={orderSearchDefault || ""}
																onChange={(e) => {
																	setCurrentPage(1);
																	onChange(e.target.value);
																	setOrderSearchDefault(e.target.value);
																}}
															/>
															<button className="btn btn-outline-success" type="button" id="button-addon2" onClick={() => setSelectModalMulti(true)}><i className="ri-qr-scan-line"></i></button>
														</div>
													</Col>

													<Col md={3}>
														<Flatpickr
															className="form-control"
															id="datepicker-created-at"
															placeholder={t("Delivered at")}
															options={{
																altInput: true,
																altFormat: "F j, Y",
																mode: "range",
																dateFormat: "DD-MM-YYYY",
															}}
															onChange={(date) => {
																setCurrentPage(1);
																if(date[0]!== undefined){
																	const date1 = moment(date[0]).format("DD-MM-YYYY");
																	setCreationStartDate(date1);
																} else {
																	setCreationStartDate(null);
																}
																if(date[1]!== undefined){
																	const date1 = moment(date[1]).format("DD-MM-YYYY");
																	setCreationEndDate(date1);
																} else {
																	setCreationEndDate(null);
																}
															}}
														/>
													</Col>
										
													<Col md={3}>
														<Flatpickr
															className="form-control"
															id="datepicker-billing-date"
															placeholder={t("Billing Day")}
															options={{
																altInput: true,
																altFormat: "F j, Y",
																mode: "range",
																dateFormat: "DD-MM-YYYY",
															}}
															onChange={(date) => {
																setCurrentPage(1);
																if(date[0]!== undefined){
																	const date1 = moment(date[0]).format("DD-MM-YYYY");
																	setBillingStartDate(date1);
																} else {
																	setBillingStartDate(null);
																}
																if(date[1]!== undefined){
																	const date1 = moment(date[1]).format("DD-MM-YYYY");
																	setBillingEndDate(date1);
																} else {
																	setBillingEndDate(null);
																}
															}}
														/>
													</Col>
										
													<Col md={3}>
														<Input
															name="driverId"
															id="id-field"
															className="form-control"
															placeholder={t("Driver name, email o rut")}
															type="text"
															validate={{
																required: { value: true },
															}}
															onChange={(e) => {
																setCurrentPage(1);
																onDriverChange(e.target.value && e.target.value.length > 0 ? e.target.value : null);
															}}
														/>
													</Col>
												</Row>
												<Row className='mt-2'>
													{orderSetStatuses && orderSetStatuses.statuses && (
														<Col md={6}>
															<Select
																isMulti
																placeholder={t("Order State")}
																classNamePrefix="form-select"
																isLoading={isOrderSetStatusesLoading}
																isClearable={true}
																name="types"
																onChange={handleOrderSetState}
																options={orderSetStatuses.statuses.map((eventType, key) => {
																	return {
																		...eventType,
																		value: eventType.value,
																		label: `${eventType.value} - ${eventType.display}`,
																	};
																})}
															/>
														</Col>
													)}

													<Col md={3}>
														<Input
															name="zoneId"
															id="id-field"
															className="form-control"
															placeholder={t("Zone Id")}
															type="text"
															validate={{
																required: { value: true },
															}}
															onChange={(e) => {
																setCurrentPage(1);
																setZone(e.target.value && e.target.value.length > 0 ? e.target.value : null);
																debugger;
															}}
														/>
													</Col>

													<Col md={3}>
														<Input
															name="boxesAmount"
															id="id-boxes"
															className="form-control"
															placeholder={t("Boxes amount")}
															type="number"
															validate={{
																required: { value: true },
															}}
															onChange={(e) => {
																setCurrentPage(1);
																setBoxesAmount(e.target.value && e.target.value.length > 0 ? e.target.value : null);
															}}
														/>
													</Col>
												
												</Row>
												<Row className='mt-2'>

													<Col md={3}>
														<Input
															name="outBoxesAmount"
															id="id-out-boxes"
															className="form-control"
															placeholder={t("Out of Boxes amount")}
															type="number"
															validate={{
																required: { value: true },
															}}
															onChange={(e) => {
																setCurrentPage(1);
																setOutBoxesAmount(e.target.value && e.target.value.length > 0 ? e.target.value : null);
															}}
														/>
													</Col>
												
													<Col md={3}>
														<Select
															placeholder={t("Collects")}
															classNamePrefix="form-select"
															isClearable={true}
															name="types"
															onChange={(e) => {
																setCurrentPage(1);
																setWithCollect(e ? e.value : null);
															}}
															options={withCollectOptions}
														/>
													</Col>

													<Col md={3}>
														<Input
															name="kind"
															id="id-kind"
															className="form-control"
															placeholder={t("Order kind")}
															type="number"
															validate={{
																required: { value: true },
															}}
															onChange={(e) => {
																setCurrentPage(1);
																setKind(e.target.value && e.target.value.length > 0 ? e.target.value : null);
															}}
														/>
													</Col>

													<Col md={3}>
														<Input
															name="campaignId"
															id="id-field"
															className="form-control"
															placeholder={t("Campaign Id")}
															type="text"
															validate={{
																required: { value: true },
															}}
															onChange={(e) => {
																setCurrentPage(1);
																setCampaign(e.target.value && e.target.value.length > 0 ? e.target.value : null);
															}}
														/>
													</Col>

												</Row>
											</form>
										</CardBody>
									</Row>
									<OrderSetsTable
										data={orderSetsPages != null ? orderSetsPages.results : []}
										isLoading={isOrderSetLoading}
										isSuccess={isOrderSetSuccess}
										error={error}
										onSelected={onMultipleSelected}
										onDelete={onSingleDeletePressed}
									/>
								</div>
								{orderSetsPages && !isEmpty(orderSetsPages.results) && (
									<Pagination
										totalRows={orderSetsPages.count}
										pageChangeHandler={setCurrentPage}
										pageSizeChangeHandler={handlePageSizeChange}
										rowsPerPage={currentPageSize}
									/>
								)}
								<Modal isOpen={modal} toggle={toggle} centered>
									<ModalHeader toggle={toggle}>
										{t("Edit orders")}
									</ModalHeader>
									
									<ModalBody>
										<p className="text-muted mb-4">{t("Only fields with values will be updated")}</p>
										<Form onSubmit={validation.handleSubmit}>
											<Row className="g-3">
												<Col lg={6}>
													<Label htmlFor="campaign_reference" className="form-label" >
														{t("Campaign Id")}
													</Label>
													<Input
														name="campaign_reference"
														className="form-control"
														id="campaign_reference"
														placeholder={t("Campaign Id")}
														onChange={validation.handleChange}
														onBlur={validation.handleBlur}
														value={validation.values.campaign_reference || ""}
														invalid={
															(validation.errors.campaign_reference) || (errors && errors.campaign_reference) /*|| (updateErrors && updateErrors.campaign_reference)*/ ? true : false
														}
													></Input>
													{validation.touched.campaign_reference && validation.errors.campaign_reference ? (
														<FormFeedback type="invalid">{validation.errors.campaign_reference}</FormFeedback>
													) : null}
													<FormFeedback type="invalid">{errors && errors.campaign_reference ? (<div>{errors.campaign_reference}</div>) : null}</FormFeedback>
													{/*<FormFeedback type="invalid">{updateErrors && updateErrors.campaign_reference ? (<div>{updateErrors.campaign_reference}</div>) : null}</FormFeedback>*/}
												</Col>

												<Col sm={6}>
													<div>
														<Label htmlFor="billing_date" className="form-label" >
															{t("Billing Day")}
														</Label>
														<Flatpickr
															className="form-control"
															id="billing_date"
															placeholder={t("Billing Day")}
															options={{
																mode: "single",
																dateFormat: "Y-m-d",
															}}
															rules={{ required: true }}
														onChange={(selectedDates, dateStr, instance) => validation.setFieldValue('billing_date', selectedDates[0])}
														/>
														<FormFeedback type="invalid">{errors && errors.billing_date ? (<div>{errors.billing_date}</div>) : null}</FormFeedback>
														{/*<FormFeedback type="invalid">{updateErrors && updateErrors.billing_date ? (<div>{updateErrors.billing_date}</div>) : null}</FormFeedback>*/}
													</div>
												</Col>

												<Col lg={6}>
													<Label htmlFor="quantity" className="form-label" >
														{t("AFP Quantity")}
													</Label>
													<Input
														name="quantity"
														className="form-control"
														id="quantity"
														placeholder={t("AFP Quantity")}
														onChange={validation.handleChange}
														onBlur={validation.handleBlur}
														value={validation.values.quantity || ""}
														invalid={
															(validation.errors.quantity) || (errors && errors.quantity) /*|| (updateErrors && updateErrors.quantity)*/ ? true : false
														}
													></Input>
													{validation.touched.quantity && validation.errors.quantity ? (
														<FormFeedback type="invalid">{validation.errors.quantity}</FormFeedback>
													) : null}
													<FormFeedback type="invalid">{errors && errors.quantity ? (<div>{errors.quantity}</div>) : null}</FormFeedback>
													{/*<FormFeedback type="invalid">{updateErrors && updateErrors.quantity ? (<div>{updateErrors.quantity}</div>) : null}</FormFeedback>*/}
												</Col>

												<Col lg={6}>
													<Label htmlFor="zone" className="form-label" >
														{t("Zone")}
													</Label>
													<Input
														name="zone"
														className="form-control"
														id="zone"
														placeholder={t("Zone")}
														onChange={validation.handleChange}
														onBlur={validation.handleBlur}
														value={validation.values.zone || ""}
														invalid={
															(validation.errors.zone) || (errors && errors.zone) /*|| (updateErrors && updateErrors.zone)*/ ? true : false
														}
													></Input>
													{validation.touched.zone && validation.errors.zone ? (
														<FormFeedback type="invalid">{validation.errors.zone}</FormFeedback>
													) : null}
													<FormFeedback type="invalid">{errors && errors.zone ? (<div>{errors.zone}</div>) : null}</FormFeedback>
													{/*<FormFeedback type="invalid">{updateErrors && updateErrors.zone ? (<div>{updateErrors.zone}</div>) : null}</FormFeedback>*/}
												</Col>

												<Col lg={12}>
													<Label htmlFor="territory" className="form-label" >
														{t("Territory")}
													</Label>
													<Input
														name="territory"
														className="form-control"
														id="territory"
														placeholder={t("Territory")}
														onChange={validation.handleChange}
														onBlur={validation.handleBlur}
														value={validation.values.territory || ""}
														invalid={
															(validation.errors.territory) || (errors && errors.territory) /*|| (updateErrors && updateErrors.territory)*/ ? true : false
														}
													></Input>
													{validation.touched.territory && validation.errors.territory ? (
														<FormFeedback type="invalid">{validation.errors.territory}</FormFeedback>
													) : null}
													<FormFeedback type="invalid">{errors && errors.territory ? (<div>{errors.territory}</div>) : null}</FormFeedback>
													{/*<FormFeedback type="invalid">{updateErrors && updateErrors.territory ? (<div>{updateErrors.territory}</div>) : null}</FormFeedback>*/}
												</Col>

												{orderEventsTypes && <Col lg={12}>
													<div className="mb-3">
														<Label htmlFor="status_id" className="form-label">{t("Status")}</Label>
														<Select
															required
															
															classNamePrefix="form-select"
															isLoading={isOrderEventsTypesLoading}
															isClearable={true}
															name="types"
															onChange={(selection) => {validation.setFieldValue('status_id', selection ? selection.id : null)}}
															options={orderEventsTypes.map((eventType, key) => {
																return {
																	...eventType,
																	value: eventType.id,
																	label: eventType.name,
																};
															})}
														/>
														{validation.touched.status_id && validation.errors.status_id ? (
															<FormFeedback type="invalid">{validation.errors.status_id}</FormFeedback>
														) : null}
													</div>
												</Col>}

												<Col lg={12}>
													<div className="hstack gap-2 justify-content-end mt-4">
														<button type="button" className="btn btn-light" onClick={() => toggle()}>{t("Close")}</button>
														<button
															type="submit"
															className="btn btn-success"
															disabled={isUpdateMultipleLoading}
															id="addNewUser">
																{isUpdateMultipleLoading && <Spinner size='sm' color="secondary"/>}
																{t("Edit orders")}
														</button>
													</div>
												</Col>
											</Row>
										</Form>
									</ModalBody>
								</Modal>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default withTranslation()(OrderSets);

