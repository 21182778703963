import React from 'react';
import { Routes, Route } from "react-router-dom";
import { hasGroup } from "../api/session_helper";

//Layouts
import NonAuthLayout from "../Components/Layouts/NonAuthLayout";
import VerticalLayout from "../Components/Layouts/index";
import ClientsLayout from "../Components/Layouts/ClientsLayout";

//routes
import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import { AuthProtected } from './AuthProtected';

import OrderSets from "../Pages/OrderSets/index";
import ClientOrderSets from "../Pages/OrderSets/ClientOrderSets";
import OrderSetDetail from "../Pages/OrderSets/OrderSetDetail";
import OrderDetail from "../Pages/OrderSets/OrderDetail";
import CreateOrderSet from "../Pages/OrderSets/CreateOrderSet";
import CreateTicketCollect from "../Pages/OrderSets/CreateTicketCollect";
import CollectTicketDetail from "../Pages/OrderSets/CollectTicketDetail";
import CreateOrder from "../Pages/OrderSets/CreateOrder";
import ShippingLabel from "../Pages/OrderSets/ShippingLabel";
import Tracking from "../Pages/Tracking/index";
import TrackingDetails from "../Pages/Tracking/TrackingDetails";
import CustomerOrderSetsSearch from "../Pages/Tracking/CustomerOrderSetsSearch"
import ClientsTrackingDetails from "../Pages/Tracking/ClientsTrackingDetails";
import CollectTickets from "../Pages/CollectTickets/index";
import OutOfBoxes from "../Pages/OutOfBoxes/index";

import Users from "../Pages/Users/index";
import UserProfile from "../Pages/Users/UserProfile";
import EditUser from "../Pages/Users/EditUser";

import Drivers from "../Pages/Drivers/index";
import DriverProfile from "../Pages/Drivers/DriverProfile";
import EditDriver from "../Pages/Drivers/EditDriver";

import OrderImports from "../Pages/OrderImports/index";
import OrdersImportDetails from "../Pages/OrderImports/OrdersImportDetails";

import OperationalBlocks from "../Pages/OperationalBlocks/index";
import OperationalBlockDetails from "../Pages/OperationalBlocks/OperationalBlockDetails";

import Pickings from "../Pages/Pickings/index";
import PickingDetails from "../Pages/Pickings/PickingDetails";
import OutOfBox from "../Pages/Pickings/OutOfBox";

import PickingCompletionRequestDetails from "../Pages/PickingCompletionRequets/PickingCompletionRequestDetails";

import RoutesPage from "../Pages/Routes/index";
import RoutesSearcher from "../Pages/Routes/RoutesSearcher";
import RouteDetails from "../Pages/Routes/RouteDetails";
import PublicRouteDetails from "../Pages/Routes/PublicRouteDetails";
import Companies from "../Pages/Companies/index";

import SystemSettings from "../Pages/Settings/SystemSettings";


const Index = () => {
	return (
		<React.Fragment>
			<Routes>
				<Route>
					{publicRoutes.map((route, idx) => (
						<Route
							path={route.path}
							element={
								<NonAuthLayout>
									{route.component}
								</NonAuthLayout>
							}
							key={idx}
							exact={true}
						/>
					))}

		|			<Route 
						path="public-routes/"
						element={
							<RoutesSearcher />
						}
						exact={true}
					/>

					<Route
						path="public-routes/:id"
						element={
							<PublicRouteDetails />
						}
						exact={true}
					/>

					<Route
						path="tracking/"
						element={
							<Tracking />
						}
						exact={true}
					/>

					<Route
						path="tracking/:id"
						element={
							<CustomerOrderSetsSearch />
						}
						exact={true}
					/>

					<Route
						path="tracking/:id/order/:reference"
						element={
							<TrackingDetails />
						}
						exact={true}
					/>

				</Route>

				<Route>
					{authProtectedRoutes.map((route, idx) => (
						<Route
							path={route.path}
							element={
								<AuthProtected>
									<VerticalLayout>{route.component}</VerticalLayout>
								</AuthProtected>}
							key={idx}
							exact={true}
						/>
					))}

					<Route 
						path="operational-blocks/"
						element={
							<AuthProtected>
								<VerticalLayout><OperationalBlocks /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>

					<Route
						path="operational-blocks/:id"
						element={
							<AuthProtected>
								<VerticalLayout><OperationalBlockDetails /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route 
						path="order-sets/"
						element={
							<AuthProtected>
								<VerticalLayout><OrderSets /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route 
						path="order-sets/:id"
						element={
							<AuthProtected>
								<VerticalLayout><OrderSetDetail /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route 
						path="boxes/:id"
						element={
							<AuthProtected>
								<VerticalLayout><OrderDetail /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route 
						path="boxes/:id/shipping-label"
						element={
							<AuthProtected>
								<VerticalLayout><ShippingLabel /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route
						path="boxes/:id/edit"
						element={
							<AuthProtected>
								<VerticalLayout><CreateOrder /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route
						path="order-sets/:id/edit"
						element={
							<AuthProtected>
								<VerticalLayout><CreateOrderSet /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route 
						path="order-sets/new"
						element={
							<AuthProtected>
								<VerticalLayout><CreateOrderSet /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route
						path="order-sets/:id/create-collect-ticket"
						element={
							<AuthProtected>
								<VerticalLayout><CreateTicketCollect /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route 
						path="collect-ticket/new"
						element={
							<AuthProtected>
								<VerticalLayout><CreateTicketCollect /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route 
						path="collect-ticket/:id"
						element={
							<AuthProtected>
								<VerticalLayout><CollectTicketDetail /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route
						path="collect-ticket/:id/edit"
						element={
							<AuthProtected>
								<VerticalLayout><CreateTicketCollect /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route 
						path="orders-import/"
						element={
							<AuthProtected>
								<VerticalLayout><OrderImports /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route 
						path="orders-import/:reference"
						element={
							<AuthProtected>
								<VerticalLayout><OrdersImportDetails /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route 
						path="pickings/:id/out-of-box"
						element={
							<AuthProtected>
								<VerticalLayout><OutOfBox /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route 
						path="routes/"
						element={
							<AuthProtected>
								<VerticalLayout><RoutesPage /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route
						path="routes/:id"
						element={
							<AuthProtected>
								<VerticalLayout><RouteDetails /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route 
						path="inbound-pickings/"
						element={
							<AuthProtected>
								<VerticalLayout><Pickings /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route 
						path="outbound-pickings/"
						element={
							<AuthProtected>
								<VerticalLayout><Pickings /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route 
						path="return-pickings/"
						element={
							<AuthProtected>
								<VerticalLayout><Pickings /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route
						path="pickings/:id"
						element={
							<AuthProtected>
								<VerticalLayout><PickingDetails /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route 
						path="pickings/:id/new-order"
						element={
							<AuthProtected>
								<VerticalLayout><CreateOrder /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route 
						path="collect-tickets/"
						element={
							<AuthProtected>
								<VerticalLayout><CollectTickets /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route 
						path="out-of-boxes/"
						element={
							<AuthProtected>
								<VerticalLayout><OutOfBoxes /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route
						path="picking-completion-requests/:id"
						element={
							<AuthProtected>
								<VerticalLayout><PickingCompletionRequestDetails /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route 
						path="users/"
						element={
							<AuthProtected>
								<VerticalLayout><Users /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route
						path="users/:id"
						element={
							<AuthProtected>
								<VerticalLayout><UserProfile /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route
						path="users/:id/edit"
						element={
							<AuthProtected>
								<VerticalLayout><EditUser /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route 
						path="drivers/"
						element={
							<AuthProtected>
								<VerticalLayout><Drivers /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route
						path="drivers/:id"
						element={
							<AuthProtected>
								<VerticalLayout><DriverProfile /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route
						path="drivers/:id/edit"
						element={
							<AuthProtected>
								<VerticalLayout><EditDriver /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route 
						path="companies/"
						element={
							<AuthProtected>
								<VerticalLayout><Companies /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route 
						path="settings/"
						element={
							<AuthProtected>
								<VerticalLayout><SystemSettings /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route 
						path="client-order-sets/"
						element={
							<AuthProtected>
								<ClientsLayout><ClientOrderSets /></ClientsLayout>
							</AuthProtected>
						}
						exact={true}
					/>
					<Route 
						path="client-order-sets/:id"
						element={
							<AuthProtected>
								<ClientsLayout><ClientsTrackingDetails /></ClientsLayout>
							</AuthProtected>
						}
						exact={true}
					/>
				</Route>
			</Routes>
		</React.Fragment>
	);
};

export default Index;