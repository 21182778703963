import React, { useState, useEffect, useRef } from 'react';
import { CardBody, Row, Col, Card, Table, CardHeader, Container, Button } from 'reactstrap';
import Loader from "../../Components/Commons/Loader";
import BreadCrumb from "../../Components/Commons/BreadCrumb";
import { Link, useParams } from "react-router-dom";
import { withTranslation, useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
//import PrintableShippingLabel from "./PrintableShippingLabel";
import Barcode from 'react-jsbarcode';
import * as moment from "moment";

import { 
	useGetOrderByIdQuery
 } from '../../api/api'


export const PrintableShippingLabel = React.forwardRef((props, ref) => {
	
	let { id } = useParams();
	
	const { t, i18n } = useTranslation();

	const { 
		data: order,
		error,
		isFetching: isOrderLoading,
		isSuccess: isorderSuccess
	} = useGetOrderByIdQuery(
		id,
		{
			refetchOnMountOrArgChange: true,
			skip: false,
		}
	);

	const handleValidDate = date => {
		const date1 = moment(date).format("DD/MM");
		return date1;
	};

	return (
		<div ref={ref}>
			<Row className="justify-content-center">
				<Col xxl={9}>
					{isOrderLoading && <Loader/>}
					{isorderSuccess && (
						<Card id="demo">
							<Row className="p-4">
								
								<Col lg={12}>
									<CardBody className="pl-4 pr-4">
										<Row className="g-3 border-bottom border-dark">
											<Col lg={12} xs={12}>
												<p className="mb-0 text-uppercase fw-semibold">
													{t("Order Id")}
												</p>
												<h1 className="fs-48 mb-0"><span >{order.reference}</span></h1>
											</Col>
										</Row>
									</CardBody>
								</Col>


								<Col lg={12}>
									<CardBody className="pl-4 pr-4">
										<Row className="g-3 border-bottom border-dark">
											<Col className="g-3 border-end border-dark" lg={6} xs={6}>
												<p className="mb-2 text-uppercase fw-semibold">
													{t("Section")}
												</p>
												<h1 className="fs-48 mb-0"><span >{order.section}</span></h1>
											</Col>
											<Col lg={6} xs={6}>
												<p className="mb-2 text-uppercase fw-semibold">
													{t("Type")}
												</p>
												<h5 className="mb-0">
													<span id="invoice-date">{order.order_kind}</span>
												</h5>
											</Col>
										</Row>
									</CardBody>
								</Col>

								<Col lg={12}>
									<CardBody className="pl-4 pr-4">
										<Row className="g-3 border-bottom border-dark">
											<Col lg={12} xs={6}>
												<p className="mb-2 text-uppercase fw-semibold">
													{t("Address")}
												</p>	
												<h5 className="fs-24 mb-0"><span >{order.address != null ? order.address.address_string : ''}</span></h5>
											</Col>
										</Row>
									</CardBody>
								</Col>

								<Col lg={12}>
									<CardBody className="pl-4 pr-4">
										<Row className="g-3 border-bottom border-dark">
											<Col lg={12} xs={6}>
												<p className="mb-2 text-uppercase fw-semibold">
													{t("Customer")}
												</p>	
												<h5 className="fs-24 mb-0"><span >{order.customer != null ? order.customer.name : ''}</span></h5>
											</Col>
										</Row>
									</CardBody>
								</Col>

								<Col lg={12}>
									<CardBody className="pl-4 pr-4">
										<Row className="g-3 border-bottom border-dark">
											<Col lg={6} xs={6}>
												<p className="mb-2 text-uppercase fw-semibold">
													{t("Billing day")}
												</p>
												<h5 className="fs-24 mb-0">
													<span id="invoice-date">{handleValidDate(order.operational_block.billing_date)}</span>
												</h5>
											</Col>
										</Row>
									</CardBody>
								</Col>
								
								<Col lg={12}>
									<CardBody className="pl-4 pr-4">
										<Barcode value={order.reference} />
									</CardBody>
								</Col>

							</Row>
						</Card>
					)}
				</Col>
			</Row >
		</div>
	);
});

document.title = "Sistema de pedidos Transchile";

const ShippingLabel = ({t, i18n}) => {
	
	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<div className="page-content">
			<Container fluid>
				<BreadCrumb title={t("Shipping Label")} pageTitle={t("Orders")} />
				<PrintableShippingLabel ref={componentRef}/>
				<Col lg={12}>
					<CardBody className="p-4">
						<div className="hstack gap-2 justify-content-end d-print-none mt-4">
							<Button onClick={handlePrint}
							>
								<i className="ri-printer-line align-bottom me-1"></i> {t("Print")}
							</Button>
						</div>
					</CardBody>
				</Col>
			</Container >
		</div >
	);
};

export default withTranslation()(ShippingLabel);