import React, { useState, useEffect, useCallback } from "react";
import {
	Card,
	CardBody,
	Col,
	Container,
	Row,
	CardHeader,
	Collapse,
	Table,
	Form,
	Label,
	Modal,
	ModalBody,
	ModalHeader,
	FormFeedback
} from "reactstrap";

import { hasGroup } from "../../api/session_helper";
import * as moment from "moment";
import classnames from "classnames";
import { Link, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Masonry from "react-masonry-component";

import BreadCrumb from "../../Components/Commons/BreadCrumb";
import Loader from "../../Components/Commons/Loader";
import OrderEventStatus from "./OrderEventStatus";
import DeleteModal from "../../Components/Commons/DeleteModal";
import OrderStatus from "./OrderStatus";
import OrderStatusIcon from "./OrderStatusIcon";

import { 
	useGetOrderByIdQuery, 
	useDeleteOrderEventMutation, 
	useGetAllUsersQuery, 
	useGetOrderEventsTypesQuery,
	useCreateOrderEventMutation
} from '../../api/api'

const OrderDetail = ({ t, i18n }) => {

	let { id } = useParams();

	const { 
		data: order,
		error,
		isFetching: isOrderLoading,
		isSuccess: isOrderSuccess
	} = useGetOrderByIdQuery(
		id,
		{
			refetchOnMountOrArgChange: true,
			skip: false,
		}
	);

	const [deleteEventModal, setDeleteEventModal] = useState(false);

	const { 
		data: allUsers,
		error: allUsersErrors,
		isFetching: isAllUsersLoading,
		isSuccess: isAllUsersSuccess
	} = useGetAllUsersQuery(
		{
			refetchOnMountOrArgChange: true,
			skip: deleteEventModal=== null || !deleteEventModal,
		}
	);

	const { 
		data: orderEventsTypes,
		error: orderEventsTypesErrors,
		isFetching: isOrderEventsTypesLoading,
		isSuccess: isOrderEventsTypesSuccess
	} = useGetOrderEventsTypesQuery(
		{
			refetchOnMountOrArgChange: true,
			skip: deleteEventModal == null || !deleteEventModal,
		}
	);

	const [deleteEvent, { 
		error: deleteEventErrors, 
		isSuccess: deleteEventSuccess
	}] = useDeleteOrderEventMutation();

	useEffect(() => {
		if (deleteEventSuccess) {
			toast.success(
				t(`Event deleted successfully!`), 
				{
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
					bodyClassName: "fs-24",
				}
			);
		}
		if(deleteEventErrors){
			toast.error(t(`There was an error while deleting the event`), {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
				bodyClassName: "fs-24",}
			);
		}
	}, [deleteEventSuccess, deleteEventErrors]);

	const [createEvent, { 
		error: createEventErrors, 
		isSuccess: createEventSuccess
	}] = useCreateOrderEventMutation();

	useEffect(() => {
		if (createEventSuccess) {
			toogle();
			toast.success(
				t(`Event created successfully!`), 
				{
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
					bodyClassName: "fs-24",
				}
			);
		}
		if(createEventErrors){
			toast.error(t(`There was an error while creating the event`), {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
				bodyClassName: "fs-24",}
			);
		}
	}, [createEventSuccess, createEventErrors]);

	const [orderEventToBeDeleted, setOrderEventToBeDeleted] = useState(null);
	const [colapseStates, setColapseStates] = useState(null);

	const [dontShowDeletedEvents, setDontShowDeletedEvents] = useState(true);
	const [orderEvents, setOrderEvents] = useState(null);
	const [createModal, setCreateModal] = useState(false);

	useEffect(() => {
		if (order) {
			debugger;
			const colapseStates = new Array(order.order_events.length).fill(true);
			setColapseStates(colapseStates);
			if(dontShowDeletedEvents){
				let filtered = order.order_events.filter(event => event.status === 1);
				setOrderEvents(filtered);
			} else {
				setOrderEvents(order.order_events);
			}
		}
	}, [order, dontShowDeletedEvents]);

	const deleteOrderEvent = () => {
		deleteEvent({id: orderEventToBeDeleted.id, order:id});
	};

	const colapseDetails = useCallback((key) => {
		let newColapseStates = colapseStates.map((c, i) => {
			if (i === key) {
				return !c;
			} else {
				return c;
			}
		});
		setColapseStates(newColapseStates);
	}, [colapseStates]);
	
	const handleValidDate = date => {
		const date1 = moment(date).format("DD/MM");
		return date1;
	};

	const handleValidTime = (time) => {
		const updateTime = moment(time).format('HH:mm');
		return updateTime;
	};

	const onChangeFilterSelect = (event) => {
		debugger;
		setDontShowDeletedEvents(event.target.value === 'true');
	};

	const toogle = useCallback(() => {
		if (createModal) {
			setCreateModal(false);
			validation.resetForm();
		} else {
			setCreateModal(true);
		}
	}, [createModal]);

	// validation
	const validation = useFormik({
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: false,

		initialValues: {
			user_id: '',
			datetime: '',
			event_id: '',
		},

		validationSchema: Yup.object({
			user_id: Yup.string().required("Please Enter User"),
			datetime: Yup.string().required("Please Enter a Event Datetime"),
			event_id: Yup.string().required("Please Enter Event"),
		}),
		onSubmit: (values) => {
			debugger
			const user = {
				id: values["user_id"]
			};
			const event = {
				id: values["event_id"]
			};
			const order = {
				id: id
			};
			const orderEvent = {
				user: user,
				event: event,
				order: order,
				datetime: moment(values["datetime"]).format()
			};
			debugger;
			createEvent({body: orderEvent});
		},
	});

	document.title ="Sistema de pedidos Transchile";

	return (
		<div className="page-content">
			<Container fluid>        
				<BreadCrumb title={t("Box Details")} pageTitle={t("Boxes")} />
				<DeleteModal
					show={deleteEventModal}
					onDeleteClick={() => {
						deleteOrderEvent();
						setDeleteEventModal(false);
					}}
					onCloseClick={() => setDeleteEventModal(false)}
				/>
				<div className="modal fade" id="addmembers" tabIndex="-1" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered">
						<Modal isOpen={createModal} toogle={toogle} centered>
							<ModalHeader toogle={toogle}>{t("Create Order Event")}</ModalHeader>
							<ModalBody>
								<Form onSubmit={validation.handleSubmit}>
									<Row>
										{allUsers && <Col lg={6}>
											<div className="mb-3">
												<Label htmlFor="user_id" className="form-label">{t("User")}</Label>
												<Select
													required
													classNamePrefix="form-select"
													isLoading={isAllUsersLoading}
													isClearable={true}
													name="types"
													onChange={(selection) => {debugger;validation.setFieldValue('user_id', selection ? selection.id : null)}}
													options={allUsers.map((user, key) => {
														let name = `${user.first_name} ${user.last_name}`
														return {
															...user,
															value: user.id,
															label: name,
														};
													})}
												/>
												{validation.errors.user_id ? (
													<FormFeedback type="invalid">{validation.errors.user_id}</FormFeedback>
												) : null}
												{createEventErrors && createEventErrors.user_id ? (<FormFeedback type="invalid">{createEventErrors.user_id}</FormFeedback>) : null}
											</div>
										</Col>}
										<Col lg={6}>
											<div className="mb-3">
												<Label htmlFor="datetime" className="form-label">{t("Event datetime")}</Label>
												<Flatpickr
													className="form-control"
													id="datetime"
													placeholder={t("Event datetime")}
													options={{
														altInput: true,
														altInputClass: validation.errors.datetime ? 'is-invalid form-control' : 'form-control',
														enableTime: true,
														dateFormat: "Y-m-d H:i",
													}}
													rules={{ required: true }}
													onChange={(selectedDates, dateStr, instance) => validation.setFieldValue('datetime', selectedDates[0])}
												/>
												{validation.errors.datetime ? (
													<FormFeedback type="invalid">{validation.errors.datetime}</FormFeedback>
												) : null}
												<FormFeedback type="invalid">{createEventErrors && createEventErrors.datetime ? (<div>{createEventErrors.datetime}</div>) : null}</FormFeedback>
											</div>
										</Col>
										{orderEventsTypes && <Col lg={12}>
											<div className="mb-3">
												<Label htmlFor="event_id" className="form-label">{t("Event type")}</Label>
												<Select
													required
													
													classNamePrefix="form-select"
													isLoading={isOrderEventsTypesLoading}
													isClearable={true}
													name="types"
													onChange={(selection) => {validation.setFieldValue('event_id', selection ? selection.id : null)}}
													options={orderEventsTypes.map((eventType, key) => {
														return {
															...eventType,
															value: eventType.id,
															label: eventType.name,
														};
													})}
												/>
												{validation.touched.event_id && validation.errors.event_id ? (
													<FormFeedback type="invalid">{validation.errors.event_id}</FormFeedback>
												) : null}
												{createEventErrors && createEventErrors.event_id ? (<FormFeedback type="invalid">{createEventErrors.event_id}</FormFeedback>) : null}
											</div>
										</Col>}
										<Col lg={12}>
											<div className="hstack gap-2 justify-content-end">
												<button type="button" className="btn btn-light" onClick={() => toogle()}>{t("Close")}</button>
												<button type="submit" className="btn btn-success" id="addNewUser">{t("Add Box Event")}</button>
											</div>
										</Col>
									</Row>
								</Form>
							</ModalBody>
						</Modal>
					</div>
				</div>
				<Row>
					<Col xl={4}>
						<Card>
							<CardHeader className="align-items-center d-flex">
								<h4 className="card-title mb-0 flex-grow-1">{t("Box Details")}</h4>
							</CardHeader>
							<CardBody>
								{isOrderLoading && <Loader/>}
								{isOrderSuccess && (
									<div className="table-responsive table-card">
										<Table className="table mb-0">
											<tbody>
												<tr>
													<td className="fw-medium text-muted">{t("Box Reference ID")}</td>
													<td className="overview-industryType">{parseInt(order.reference)}</td>
												</tr>
												<tr>
													<td className="fw-medium text-muted">{t("Order")}</td>
													<td className="overview-industryType">
														<Link to={`../order-sets/${order.order_set_id}`} className="fw-medium link-primary">{order.order_set_reference}</Link>
													</td>
												</tr>
												<tr>
													<td className="fw-medium text-muted">{t("Status")}</td>
													<td className="overview-industryType">
														<OrderStatus status={order.status} status_text={order.status_text} />
													</td>
												</tr>
												<tr>
													<td className="fw-medium text-muted">{t("Billing Day")}</td>
													<td className="overview-industryType">
														{handleValidDate(order.billing_date)}
													</td>
												</tr>
												<tr>
													<td className="fw-medium text-muted">{t("Campaign Id")}</td>
													<td className="overview-company_location">
														{order.campaign_reference}
													</td>
												</tr>
												<tr>
													<td className="fw-medium text-muted">{t("Territory")}</td>
													<td className="overview-company_location">
														{order.territory}
													</td>
												</tr>
												<tr>
													<td className="fw-medium text-muted">{t("Zone")}</td>
													<td className="overview-company_location">
														{order.zone}
													</td>
												</tr>
											</tbody>
										</Table>
									</div>
								)}
							</CardBody>
						</Card>

						<Card>
							<CardHeader>
								<div className="d-flex">
									<h5 className="card-title flex-grow-1 mb-0">
										{t("Customer Details")}
									</h5>
								</div>
							</CardHeader>
							<CardBody>
								{isOrderLoading && <Loader/>}
								{isOrderSuccess && (
									<div className="table-responsive table-card">
										<Table className="table table-borderless">
											<tbody>
												<tr>
													<td className="fw-medium text-muted">{t("Name")}</td>
													<td className="overview-industryType">
														{order.customer != null ? order.customer.name : ''}
													</td>
												</tr>
												<tr>
													<td className="fw-medium text-muted">{t("Reference")}</td>
													<td className="overview-company_location">
														{order.customer != null ? order.customer.reference : ''}
													</td>
												</tr>
												<tr>
													<td className="fw-medium text-muted">{t("Address")}</td>
													<td className="overview-company_location">
														{order.address != null ? order.address.address_string : ''}
													</td>
												</tr>
												
											</tbody>
										</Table>
									</div>
								)}
							</CardBody>
						</Card>

						{isOrderSuccess && (
							<Card id="auth">
								<CardHeader className="align-items-center d-flex">
									<h4 className="card-title mb-0 flex-grow-1">{t("Shipping Label")}</h4>
								</CardHeader>
								<CardBody>
									<div className="p-0">
										<Link to={`shipping-label/`} className="btn btn-primary w-100">
											Ver
										</Link>
									</div>
								</CardBody>
							</Card>
						)}
					</Col>
					<Col xl={8}>
						<Card>
							<CardHeader>
								<div className="d-flex align-items-center">
									<h5 className="card-title flex-grow-1 mb-0">{t("Box Events")}</h5>
									{hasGroup(['Manager', 'OperationalManager', 'RouteManager']) && (
										<div>
											<button
												type="button"
												className="btn btn-sm btn-primary"
												data-bs-dismiss="modal"
												onClick={() => { toogle(); }}
											>
												{t("New Event")}
											</button>
											
											<select
												className="form-select-sm btn-light ms-3"
												value={dontShowDeletedEvents}
												onChange={onChangeFilterSelect}
											>
												<option key={true} value={true}>
													{t("Don't show deleted events")}
												</option>
												<option key={false} value={false}>
													{t("Show deleted events")}
												</option>
											</select>
										</div>
									)}
								</div>
							</CardHeader>
							<CardBody>
								<div className="profile-timeline">
									<div
										className="accordion accordion-flush"
										id="accordionFlushExample"
									>
										{colapseStates && orderEvents.map((event, key) => (
											<div className="accordion-item border-0" onClick={() => { colapseDetails(key); }} key={key}>
												<div className="accordion-header" id="headingOne">
													<Link to="#" className={classnames(
														"accordion-button",
														"p-2",
														"shadow-none",
														{ collapsed: colapseStates[key] }

													)}>
														<div className="d-flex align-items-center">
															<div className="flex-shrink-0 avatar-xs">
																<div className={`avatar-title rounded-circle ${event.status === 1 ? 'bg-success' : 'bg-light text-danger'}`}>
																	<i className={`${OrderStatusIcon({status: event.event.id})}`}></i>
																</div>
															</div>
															<div className="flex-grow-1 ms-3">
																<div>
																	<span className="fs-15 mb-0 fw-medium">
																		{event.event.name}
																	</span>
																	<span className="fw-normal ms-3">
																		-
																		{' '}
																		{event.user != null ? event.user.first_name : ''}
																		{' '}
																		{event.user != null ? event.user.last_name : ''}
																	</span>
																	<span className="fw-normal ms-3">
																		-
																		{' '}
																		{handleValidDate(event.datetime)} {handleValidTime(event.datetime)}
																	</span>
																	{event.status === 0 && (
																		<>
																			{' '}
																			<OrderEventStatus status={event.status} status_text={event.status_text}/>
																			{' '}
																		</>
																	)}
																
																</div>
															</div>
														</div>
													</Link>
												</div>
												<Collapse
													id="collapseOne"
													className="accordion-collapse"
													isOpen={!colapseStates[key]}
												>
													<div className="accordion-body ms-2 ps-5 pt-0">
														<h6 className="mb-1 fw-semibold">
															{t("Event created by {{creator}}", { creator: event.creator != null ? [event.creator.first_name, event.creator.last_name].join(' ') : '' })}
														</h6>
														<p className="text-muted">
															{handleValidDate(event.created_at)} {handleValidTime(event.created_at)}
														</p>

														{!colapseStates[key] && event.status === 1 &&(
															<button
																type="button"
																className="btn btn-soft-danger btn-sm"
																onClick={(e) => {
																	setOrderEventToBeDeleted(event);
																	setDeleteEventModal(true);
																	/*e.stopPropagation();*/
																}}
															>
																{t("Delete event")}
															</button>
														)}

														{event.edited_at && (
															<>
																<h6 className="mb-1 fw-semibold">
																	{t("Event deleted by {{creator}}", { creator: event.editor != null ? [event.editor.first_name, event.editor.last_name].join(' ') : ''})}
																</h6>
																<p className="text-muted">
																	{handleValidDate(event.edited_at)} {handleValidTime(event.edited_at)}
																</p>
															</>
														)}
													</div>
												</Collapse>
											</div>
										))}
									</div>
								</div>
							</CardBody>
						</Card>
						{isOrderLoading && <Loader/>}

						{isOrderSuccess && order.images_urls && order.images_urls.length > 0 && (
							<Card>
								<CardHeader className="align-items-center d-flex">
									<h4 className="card-title mb-0 flex-grow-1">{t("Images")}</h4>
								</CardHeader>

								<CardBody>

									<Row>
										<Col lg={12}>
											{/*<Masonry className="row gallery-wrapper">
												{isOrderSuccess && order.images_urls.map((image, key) => (



													<Col xxl={3} xl={4} sm={6} className="element-item project designing development" key={key}>
														<Card className="gallery-box">
															<div className="gallery-container">
																<Link className="image-popup" to={image}>
																	<img className="gallery-img img-fluid mx-auto" src={image} alt="" />
																	<div className="gallery-overlay">
																		<h5 className="overlay-caption"></h5>
																	</div>
																</Link>
															</div>

															<div className="box-content">
																<div className="d-flex align-items-center mt-1">
																	
																</div>
															</div>
														</Card>
													</Col>
												))}

												</Masonry>*/}
											<Masonry className="row gallery-wrapper">
												{(isOrderSuccess && order.images_urls.map(( img, key) => (
													<Col xxl={3} xl={4} sm={6} className="element-item project designing development" key={key}>
														<Card className="gallery-box">
															<div className="gallery-container">
																<Link className="image-popup" to={img} title="">
																	<img className="gallery-img img-fluid mx-auto" src={img} alt="" />
																	<div className="gallery-overlay">
																		<h5 className="overlay-caption"></h5>
																	</div>
																</Link>
															</div>

														</Card>
													</Col>
												)))}

											</Masonry>
										</Col>
									</Row>

									{/*<div className="live-preview">
										<div>
											{isOrderSuccess && order.images_urls.map((image, key) => (
												<img key={key} src={image} className="img-fluid" alt="Responsive" />
											))}
										</div>
											</div>*/}
								</CardBody>
							</Card>
						)}
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default withTranslation()(OrderDetail);