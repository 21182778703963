import React, { useEffect } from "react";
import { Navigate, Route } from "react-router-dom";
import { setAuthorization } from "../api/api_helper";
import { useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import { useProfile } from "../Components/Hooks/UserHooks";
import { hasGroup } from "../api/session_helper";
import { logoutUser } from "../slices/auth/login/thunk";

const AuthProtected = (props) => {
  const dispatch = useDispatch();
  const { userProfile, loading, token } = useProfile();
  const location = useLocation();

  useEffect(() => {
    if (userProfile && !loading && token) {
      setAuthorization(token);
    } else if (!userProfile && loading && !token) {
      dispatch(logoutUser());
    }
  }, [token, userProfile, loading, dispatch]);

  /*
    Navigate is un-auth access protected routes via url
  */

  if(!location.pathname.includes("logout")){

    if((hasGroup(['CompanyAdministrator'])) && !(location.pathname.includes("client-order-sets"))){
      return (
        <Navigate to={{ pathname: "/client-order-sets", state: { from: props.location } }} />
      );
    }
  
    if (!userProfile && loading && !token) {
      return (
        <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
      );
    }
    
    if(!hasGroup(['Manager']) && !hasGroup(['OperationalManager']) && 
      (location.pathname.includes("user/") && location.pathname.includes("edit"))){
      return (
        <Navigate to={{ pathname: "/order-sets", state: { from: props.location } }} />
      );
    }
  
    if((hasGroup(['WarehouseOperator']) || hasGroup(['OutOfBoxOperator'])) && 
      (location.pathname.includes("orders-import"))){
      return (
        <Navigate to={{ pathname: "/order-sets", state: { from: props.location } }} />
      );
    }
  
    if(!hasGroup(['Manager']) && !hasGroup(['OperationalManager']) && !hasGroup(['RouteManager']) && 
      (location.pathname.includes("order-sets/new"))){
      return (
        <Navigate to={{ pathname: "/order-sets", state: { from: props.location } }} />
      );
    }
  
    if(!hasGroup(['Manager']) && !hasGroup(['OperationalManager']) && !hasGroup(['RouteManager']) && !hasGroup(['ReverseLogisticsOperator']) && 
      (location.pathname.includes("order-sets/") && location.pathname.includes("edit"))){
      return (
        <Navigate to={{ pathname: "/order-sets", state: { from: props.location } }} />
      );
    }
  
    if((hasGroup(['WarehouseOperator']) || hasGroup(['Executives']) || hasGroup(['OutOfBoxOperator'])) && 
      (location.pathname.includes("create-collect-ticket"))){
      return (
        <Navigate to={{ pathname: "/order-sets", state: { from: props.location } }} />
      );
    }
  
    if((hasGroup(['WarehouseOperator']) || hasGroup(['Executives']) || hasGroup(['OutOfBoxOperator'])) && 
      (location.pathname.includes("collect-ticket") && location.pathname.includes("edit"))){
      return (
        <Navigate to={{ pathname: "/order-sets", state: { from: props.location } }} />
      );
    }
  
    if((hasGroup(['WarehouseOperator']) || hasGroup(['ReverseLogisticsOperator'])) && (location.pathname.includes("pickings") && location.pathname.includes("out-of-box"))){
      return (
        <Navigate to={{ pathname: "/order-sets", state: { from: props.location } }} />
      );
    }
  
    if(!hasGroup(['Manager']) && !hasGroup(['OperationalManager']) && !hasGroup(['RouteManager']) && 
      (location.pathname.includes("drivers") && location.pathname.includes("edit"))){
      return (
        <Navigate to={{ pathname: "/order-sets", state: { from: props.location } }} />
      );
    } 
    
    if(!hasGroup(['Manager']) && !hasGroup(['OperationalManager']) && !hasGroup(['RouteManager']) && 
      (location.pathname.includes("companies") )){
      return (
        <Navigate to={{ pathname: "/order-sets", state: { from: props.location } }} />
      );
    }
  
    if(!hasGroup(['Manager']) && !hasGroup(['OperationalManager']) && !hasGroup(['RouteManager']) && 
      (location.pathname.includes("settings") )){
      return (
        <Navigate to={{ pathname: "/order-sets", state: { from: props.location } }} />
      );
    }

    if(hasGroup(['WarehouseOperator']) && 
      (location.pathname.includes("out-of-boxes"))){
      return (
        <Navigate to={{ pathname: "/order-sets", state: { from: props.location } }} />
      );
    }

    if(hasGroup(['WarehouseOperator'] || hasGroup(['Executives']) || hasGroup(['OutOfBoxOperator'])) && 
      (location.pathname.includes("collect-tickets"))){
      return (
        <Navigate to={{ pathname: "/order-sets", state: { from: props.location } }} />
      );
    }
  }
  
  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (<> <Component {...props} /> </>);
      }}
    />
  );
};

export { AuthProtected, AccessRoute };