import React, { useEffect, useState, useRef } from "react";
import {
	Card,
	CardBody,
	Col,
	Container,
	CardHeader,
	Row,
	Table,
} from "reactstrap";

import * as moment from "moment";
import { useParams } from "react-router-dom";
import BreadCrumb from "../../Components/Commons/BreadCrumb";
import Loader from "../../Components/Commons/Loader";
import ErrorModal from "../../Components/Commons/ErrorModal";
import PublicOrdersTable from "./PublicOrdersTable";
import _ from 'lodash';
import { useNavigate } from "react-router-dom";

import 'react-toastify/dist/ReactToastify.css';

import { 
	useGetPublicPickingByIdQuery,
 } from '../../api/api'

const PublicRouteDetails = () => {

	let { id } = useParams();

	const navigate = useNavigate();

	const ref = useRef(null);

	const [orders, setOrders] = useState([]);
	const [errorModal, setErrorModal] = useState(false);
	const [errorTitle, setErrorTitle] = useState(false);
	const [errorDescription, setErrorDescription] = useState(false);

	const { 
		data: picking,
		error,
		isFetching: isPickingLoading,
		isSuccess: isPickingSuccess
	} = useGetPublicPickingByIdQuery(
		id,
		{
			refetchOnMountOrArgChange: true,
			skip: false,
		}
	);

	useEffect(() => {
		if (picking) {

			/* outbound */
			if(picking.kind === 1){
				setOrders(picking.route_orders);
			}
		}
	}, [picking]);

	/*useEffect(() => {
		if(error){
			setErrorTitle("Error when trying to obtain information of the picking");
			setErrorDescription(error);
			setErrorModal(true);
		}
	}, [error]);*/

	const handleValidDate = date => {
		const date1 = moment(date).format("DD/MM");
		return date1;
	};

	const handleValidTime = (time) => {
		const updateTime = moment(time).format('HH:mm');
		return updateTime;
	};

	document.title="Sistema de pedidos Transchile";

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					<ErrorModal
						show={errorModal}
						onCloseClick={() => setErrorModal(false)}
						title={errorTitle}
						description={errorDescription}
					/>
					<Row>
						<Col xxl={3}>
							<Card id="picking-overview">
								<CardHeader className="align-items-center d-flex">
									<h4 className="card-title mb-0 flex-grow-1">Route Information</h4>
								</CardHeader>
								<CardBody>
									{isPickingLoading && <Loader/>}
									{isPickingSuccess && (
										<div className="table-responsive table-card">
											<Table className="table mb-0">
												<tbody>
													<tr>
														<td className="fw-medium text-muted">Route Reference ID</td>
														<td className="overview-industryType">{parseInt(id)}</td>
													</tr>
													<tr>
														<td className="fw-medium text-muted">Assigned</td>
														<td className="overview-industryType">
															{picking.user.first_name} {picking.user.last_name}
														</td>
													</tr>
													<tr>
														<td className="fw-medium text-muted">Created at</td>
														<td className="overview-company_location">
															{handleValidDate(picking.created_at)} {handleValidTime(picking.created_at)}
														</td>
													</tr>
												</tbody>
											</Table>
										</div>
									)}
								</CardBody>
							</Card>
						</Col>

						<Col xl={9}>
							<Card className="card-height-100">
								<CardHeader className="align-items-center d-flex">
									<h4 className="card-title mb-0 flex-grow-1">Boxes</h4>
								</CardHeader>
								<CardBody>
									<div>
										<PublicOrdersTable
											data={picking && picking.picked_out_orders != null ? picking.picked_out_orders : []}
											isLoading={isPickingLoading}
											isSuccess={isPickingSuccess}
											error={error}
										/>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default PublicRouteDetails;