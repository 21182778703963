import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Commons/BreadCrumb';
import OrdersImportOrders from './OrdersImportOrders';
import OrdersImportSummary from "./OrdersImportSummary";
import { withTranslation } from 'react-i18next';

const OrdersImportDetails = ({t, i18n}) => {
    document.title="Tasks Details | Sistema de pedidos Transchile";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t("Orders Import Details")} pageTitle={t("Orders Imports")} />
                    <Row>
                        <Col xl={3}>
                            <OrdersImportSummary />
                        </Col>
                        <Col xl={9}>
                            <OrdersImportOrders />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(OrdersImportDetails);