import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { hasGroup } from "../../api/session_helper";


const Navdata = () => {
    const history = useNavigate();
    //state data
    const [isOrders, setIsOrders] = useState(false);
    const [isMultiLevel, setIsMultiLevel] = useState(false);


    // Authentication
    const [isLockScreen, setIsLockScreen] = useState(false);
    const [isLogout, setIsLogout] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Dashboard');
    
    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Orders') {
            setIsOrders(false);
        }
    }, [
        history,
        iscurrentState,
        isOrders
    ]);

    const menuItems = [
        {
            id: "start",
            label: "start",
            isHeader: true,
        },
        {
            id: "billing-days",
            label: "Billing Days",
            icon: "ri-calendar-event-line",
            link: "/operational-blocks",
            click: function (e) {
                e.preventDefault();
                setIsOrders(!isOrders);
            }
        },
        {
            id: "operation",
            label: "operation",
            isHeader: true,
        },
        {
            id: "orders",
            label: "Orders",
            icon: "ri-inbox-archive-line",
            link: "/order-sets",
            click: function (e) {
                e.preventDefault();
                setIsOrders(!isOrders);
            }
        },
        {
            id: "imports",
            label: "Import Orders",
            icon: "ri-inbox-archive-line",
            link: "/orders-import",
            click: function (e) {
                e.preventDefault();
                setIsOrders(!isOrders);
            }
        },
        {
            id: "pickings",
            label: "Pickings",
            icon: "ri-qr-scan-line",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsMultiLevel(!isMultiLevel);
                setIscurrentState('MuliLevel');
                updateIconSidebar(e);
            },
            stateVariables: isMultiLevel,
            subItems: [
                {
                    id: "inbound", 
                    label: "Inbound", 
                    link: "/inbound-pickings", 
                    parentId: "pickings"
                },
                {
                    id: "outbound",
                    label: "Outbound",
                    link: "/outbound-pickings",
                    parentId: "pickings"
                },
                {
                    id: "return",
                    label: "Return",
                    link: "/return-pickings",
                    parentId: "pickings"
                },
            ],
        },
        {
            id: "routes",
            label: "Routes",
            icon: "ri-route-line",
            link: "/routes",
            click: function (e) {
                e.preventDefault();
                setIsOrders(!isOrders);
            }
        },
        {
            id: "collect-tickets",
            label: "Collect Tickets",
            icon: "ri-arrow-go-back-line",
            link: "/collect-tickets",
            click: function (e) {
                e.preventDefault();
                setIsOrders(!isOrders);
            }
        },
        {
            id: "out-of-boxes",
            label: "Out of Boxes",
            icon: "ri-gift-line",
            link: "/out-of-boxes",
            click: function (e) {
                e.preventDefault();
                setIsOrders(!isOrders);
            }
        },
        {
            id: "managment",
            label: "managment",
            isHeader: true,
        },
        {
            id: "users",
            label: "Users",
            icon: "ri-user-settings-line",
            link: "/users",
            click: function (e) {
                e.preventDefault();
            }
        },
        {
            id: "drivers",
            label: "Drivers",
            icon: "ri-user-line",
            link: "/drivers",
            click: function (e) {
                e.preventDefault();
            }
        },
        {
            id: "companies",
            label: "Companies",
            icon: "ri-building-line",
            link: "/companies",
            click: function (e) {
                e.preventDefault();
            }
        },
        {
            id: "settings",
            label: "Settings",
            icon: "ri-settings-line",
            link: "/settings",
            click: function (e) {
                e.preventDefault();
            }
        },
        {
            id: "session",
            label: "session",
            isHeader: true,
        },
        {
            id: "lockScreen",
            label: "Lock Screen",
            link: "/#",
            icon: "ri-shield-line",
            isChildItem: true,
            click: function (e) {
                e.preventDefault();
                setIsLockScreen(!isLockScreen);
            },
            parentId: "authentication",
            stateVariables: isLockScreen,
            link: "/auth-lockscreen"
        },
        {
            id: "logout",
            label: "Logout",
            link: "/#",
            icon: "ri-logout-box-line",
            isChildItem: true,
            click: function (e) {
                e.preventDefault();
                setIsLogout(!isLogout);
            },
            parentId: "authentication",
            stateVariables: isLogout,
            link: "/logout"
        },
    ];
    if(hasGroup(['WarehouseOperator'])){
        const filteredMenuItems = menuItems.filter((x) => x.id !== "imports" && x.id !== "companies" && x.id !== "out-of-boxes" && x.id !== "collect-tickets" && x.id !== "return");
        return <React.Fragment>{filteredMenuItems}</React.Fragment>;
    } else if(hasGroup(['Executives'])){
        const filteredMenuItems = menuItems.filter((x) => x.id !== "companies" && x.id !== "collect-tickets" && x.id !== "return");
        return <React.Fragment>{filteredMenuItems}</React.Fragment>;
    } else if(hasGroup(['ReverseLogisticsOperator'])){
        const filteredMenuItems = menuItems.filter((x) => x.id !== "companies" && x.id !== "outbound");
        return <React.Fragment>{filteredMenuItems}</React.Fragment>;
    } else if(hasGroup(['OutOfBoxOperator'])){
        const filteredMenuItems = menuItems.filter((x) => x.id !== "imports" && x.id !== "companies" && x.id !== "collect-tickets" && x.id !== "return");
        return <React.Fragment>{filteredMenuItems}</React.Fragment>;
    } else {
        return <React.Fragment>{menuItems}</React.Fragment>;
    }
};
export default Navdata;