import React, { useState, useCallback, useEffect } from "react";

import {
	Card,
	CardBody,
	Col,
	Input,
	Container,
	Form,
	Row,
} from "reactstrap";

import Loader from "../../Components/Commons/Loader";
import { Link, useParams, useNavigate } from "react-router-dom";

import * as moment from "moment";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import notFoundImage from "../../images/not_found.png";

import OrderStatusPublic from "../OrderSets/OrderStatusPublic";
import OrderStatusIcon from "../OrderSets/OrderStatusIcon";
import EmptyState from "../../Components/Commons/EmptyState";
import Footer from "./Footer";
import Masonry from "react-masonry-component";

import SwiperCore, { FreeMode, Navigation, Thumbs } from "swiper";

SwiperCore.use([FreeMode, Navigation, Thumbs]);

import Navbar from "./Navbar";

import {
	useGetPublicOrderSetByIdQuery
} from '../../api/api';

const TrackingDetails = () => {

	document.title = "Seguimiento en línea de pedidos Transchile";
	
	const navigate = useNavigate();

	let { id } = useParams();
	let { reference } = useParams();

	const [search, setSearch] = useState(null);

	const { 
		data: orderSet,
		error,
		isFetching: orderSetLoading,
		isSuccess: orderSetSuccess
	} = useGetPublicOrderSetByIdQuery(
		reference ? reference : '',
		{
			refetchOnMountOrArgChange: true,
			skip: !reference,
		}
	);

	const handleValidDate = date => {
    const date1 = moment(date).format("DD/MM");
    return date1;
  };

	const handleValidTime = (time) => {
		const updateTime = moment(time).format('HH:mm');
		return updateTime;
	};
	
	document.title = "Tracking de pedidos Transchile";

	window.onscroll = function () {
		scrollFunction();
	};

	const scrollFunction = () => {
		const element = document.getElementById("back-to-top");
		if (element) {
			if (
				document.body.scrollTop > 100 ||
				document.documentElement.scrollTop > 100
			) {
				element.style.display = "block";
			} else {
				element.style.display = "none";
			}
		}
	};

	const searchPressed = useCallback((value) => {
		navigate(`../tracking/${search}`);
	});

	const onSearchChange = useCallback((value) => {
		setSearch(value);
	});

	const toTop = () => {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	};

	useEffect(() => {
		if (orderSet) {
			debugger;
		}
	}, [orderSet]);

	return (
		<React.Fragment>
			<div className="layout-wrapper landing tracking-details bg-light">
				<Navbar />
				<div className="bg-overlay bg-overlay-pattern"></div>
				<Container className="mb-4 pb-4">
					<Row className="section tracking-hero-section pb-4">
						<Col lg={12}>
							<Card>
								<CardBody>
									<Row className="gx-lg-5 p-4">
										<Col xl={12}>
											{orderSetLoading && <Loader/>}
											{orderSetSuccess && (
												<div className="mt-xl-0">

													<div className="d-flex mt-2 mb-4">
														<div className="flex-grow-1">
															<div className="hstack gap-3 flex-wrap">
																<h1 className="p-0">Pedido #{orderSet.reference}</h1>
																<div className="d-flex flex-wrap gap-2 align-items-center">
																	<h4>
																		<OrderStatusPublic
																			status={orderSet.status}
																			status_text={orderSet.status_text}/>
																	</h4>
																	{/*<div className="d-flex flex-wrap fs-12 text-muted gap-4">
																		{"("}
																		actualización: 
																		{" "}
																		{handleValidDate(orderSet.updated_at)}
																		{" - "}
																		{handleValidTime(orderSet.updated_at)}
																		{")"}
																	</div>*/}
																</div>
															</div>
															<div className="hstack gap-3 flex-wrap mt-0">
																
																<div className="fs-14 text-muted">
																	Cliente :{" "}
																	<span className="text-body fw-medium">
																		{orderSet.customer.name}
																	</span>
																</div>

																<div className="vr"></div>

																<div className="fs-14 text-muted">
																	CC :{" "}
																	<span className="text-body fw-medium">
																		{orderSet.customer.reference}
																	</span>
																</div>

																<div className="vr"></div>

																<div className="fs-14 text-muted">
																	Zona:{" "}
																	<span className="text-body fw-medium">
																		{orderSet.zone}
																	</span>
																</div>

															</div>
														</div>
													</div>

													<div className="tracking-details">
														<div className="product-content mt-4">

															<Row className="mt-4">

																<Col lg={6} sm={12}>

																	<h2 className="fs-16 mt-3">Eventos:</h2>

																	<div className="profile-timeline border border-dashed pt-1 ps-4 pe-4">
																		{orderSet.order_events && orderSet.order_events.map((event, key) => (
																			<div className="accordion-item border-0" key={key}>
																				<div className="accordion-header p-2 accordion-button shadow-none" id="headingOne">
																					<div className="d-flex align-items-center">
																						<div className="flex-shrink-0 avatar-xs">
																							<div className={`avatar-title rounded-circle ${event.status === 1 ? 'bg-success' : 'bg-light text-danger'}`}>
																								<i className={`${OrderStatusIcon({status: event.event.id})}`}></i>
																							</div>
																						</div>
																						<div className="flex-grow-1 ms-3">
																							<div>
																								<span className="fs-15 mb-0 fw-medium">
																									{event.event.name}
																								</span>
																								{(event.event.id === 6 || event.event.id === 7 || event.event.id === 8 || event.event.id === 5) && (
																									<span className="fw-normal ms-2">
																										-
																										{' '}
																										{handleValidDate(event.datetime)} {handleValidTime(event.datetime)}
																									</span>
																								)}
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		))}
																	</div>
																</Col>

																<Col lg={6} sm={12} className="mt-4 mb-4">

																	{(orderSet.status === 5 || orderSet.reception_note) && (
																		<>
																			<h1 className="fs-16 mb-3">Información de Recepción</h1>
																		
																			<div className="table-responsive table-bordered">
																				<table className="table mb-0">
																					<tbody>
																						<tr>
																							<th scope="row">Nombre receptor</th>
																							<td>{orderSet.reception_name}</td>
																						</tr>
																						<tr>
																							<th scope="row">RUT receptor</th>
																							<td>{orderSet.reception_rut}</td>
																						</tr>
																						<tr>
																							<th scope="row">Comentario de entrega</th>
																							<td>{orderSet.reception_note}</td>
																						</tr>
																					</tbody>
																				</table>
																			</div>
																		</>
																	)}
																	
																	{(orderSet.images_urls && orderSet.images_urls.length > 0) && (
																		<>
																			<h1 className="fs-16 mt-3">Imágenes Entrega</h1>
																			<Masonry className="row gallery-wrapper">
																				{(orderSet.images_urls && orderSet.images_urls.map(( img, key) => (
																					<Col xxl={3} xl={4} sm={6} className="element-item project designing development" key={key}>
																						<Card className="gallery-box">
																							<div className="gallery-container">
																								<Link className="image-popup" to={img} title="">
																									<img className="gallery-img img-fluid mx-auto" src={img} alt="" />
																									<div className="gallery-overlay">
																										<h5 className="overlay-caption"></h5>
																									</div>
																								</Link>
																							</div>

																						</Card>
																					</Col>
																				)))}
																			</Masonry>
																		</>
																	)}
																	
																</Col>

															</Row>

														</div>
													</div>

													<div className="tracking-details">
														<div className="product-content">

															<Row>

																<Col lg={6} sm={12} className="mt-4">

																	<h1 className="fs-16 mb-3">Información pedido:</h1>

																	<div className="table-responsive table-bordered">
																		<table className="table mb-0">
																			<tbody>
																				<tr>
																					<th scope="row">Facturación</th>
																					<td>{handleValidDate(orderSet.billing_date)}</td>
																				</tr>
																				<tr>
																					<th scope="row">Campaña</th>
																					<td>{orderSet.campaign_reference}</td>
																				</tr>
																				<tr>
																					<th scope="row">Cantidad de cajas</th>
																					<td>{orderSet.box_quantity}</td>
																				</tr>
																				<tr>
																					<th scope="row">Cantidad de fuera de caja</th>
																					<td>{orderSet.quantity}</td>
																				</tr>
																				<tr>
																					<th scope="row">Descripción fuera de caja</th>
																					<td>
																						{orderSet.out_of_boxes.map((out_of_box, key) => {
																							return out_of_box.quantity + ' x ' + out_of_box.afp_name + ', ';
																						})}
																					</td>
																				</tr>
																				<tr>
																					<th scope="row">Total Bultos</th>
																					<td>{orderSet.box_quantity + orderSet.quantity}</td>
																				</tr>
																			</tbody>
																		</table>
																	</div>
																</Col>

																<Col lg={6} sm={12} className="mt-4">

																	<h1 className="fs-16 mb-3">Información cliente:</h1>

																	<div className="table-responsive table-bordered">
																		<table className="table mb-0">
																			<tbody>
																				<tr>
																					<th scope="row">Nombre</th>
																					<td>{orderSet.customer.name}</td>
																				</tr>
																				<tr>
																					<th scope="row">Código</th>
																					<td>{orderSet.customer.reference}</td>
																				</tr>
																				<tr>
																					<th scope="row">Dirección</th>
																					<td>{orderSet.address.address_string}</td>
																				</tr>
																			</tbody>
																		</table>
																	</div>
																</Col>

															</Row>

														</div>

													</div>

												</div>
											)}
										{error && (
											<EmptyState
												image={notFoundImage}
												title={"Pedido no encontrado"}
												description={"Verifica el número de pedido e intenta nuevamente"}
											/>
										)}
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
				<Footer />

				<button
					onClick={() => toTop()}
					className="btn btn-danger btn-icon landing-back-top"
					id="back-to-top"
				>
					<i className="ri-arrow-up-line"></i>
				</button>
			</div>
		</React.Fragment>
	);
};

export default TrackingDetails;