import React, { useEffect, useState, useCallback, useRef } from "react";
import { withTranslation } from 'react-i18next';
import {
	Card,
	CardBody,
	Col,
	Container,
	CardHeader,
	Row,
	Table,
	Badge,
	Spinner
} from "reactstrap";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroller";
import getChartColorsArray from "../../Components/Commons/ChartsDynamicColor";
import * as moment from "moment";
import { Link, useParams } from "react-router-dom";
import BreadCrumb from "../../Components/Commons/BreadCrumb";
import Loader from "../../Components/Commons/Loader";
import { toast } from 'react-toastify';
import { useFormik } from "formik";
import _ from 'lodash';
import { useNavigate } from "react-router-dom";
import { hasGroup } from "../../api/session_helper";

import ConfirmModal from "../../Components/Commons/ConfirmModal";
import PickingStatus from "./PickingStatus";
import PickingKind from "./PickingKind";
import PickingChart from "./PickingChart";
import PickingCompletionRequestStatus from "./PickingCompletionRequestStatus";
import ErrorModal from "../../Components/Commons/ErrorModal";
import useSound from 'use-sound';
import errorSound from '../../audio/error-sound.mp3';

import 'react-toastify/dist/ReactToastify.css';

import { 
	useGetPickingByIdQuery, 
	usePickingInOrdersMutation,
	usePickingOutOrdersMutation,
	usePickingReturnOrdersMutation,
	useRequestPickingReviewMutation
 } from '../../api/api'

const PickingDetails = ({ t, i18n }) => {

	let { id } = useParams();
	

	const navigate = useNavigate();

	const ref = useRef(null);

	const [fromOrders, setFromOrders] = useState([]);
	const [fromOrdersPage, setFromOrdersPage] = useState(1);
	const [fromOrdersHasMore, setFromOrdersHasMore] = useState(true);
	const [fromOrdersCount, setFromOrdersCount] = useState([]);

	const [toOrders, setToOrders] = useState([]);
	const [toOrdersPage, setToOrdersPage] = useState(1);
	const [toOrdersHasMore, setToOrdersHasMore] = useState(true);
	const [toOrdersCount, setToOrdersCount] = useState([]);

	/*const [statusAmounts, setStatusAmounts] = useState([]);
	const [statuses, setStatuses] = useState([]);*/
	const [completePickingModal, setCompletePickingModal] = useState(false);
	const [requestAuthorizePickingModal, setRequestAuthorizationPickingModal] = useState(false);
	const [authorizePickingModal, setAuthorizePickingModal] = useState(false);
	const [createExternalOrderModal, setCreateExternalOrderModal] = useState(false);

	const [errorModal, setErrorModal] = useState(false);
	const [errorTitle, setErrorTitle] = useState(false);
	const [errorDescription, setErrorDescription] = useState(false);
	const [lastPickedOrderReference, setLastPickedOrderReference] = useState(null);

	const [selectedAuthorization, setSelectedAuthorization] = useState(false);

	const [fromTitle, setFromTitle] = useState("");
	const [toTitle, setToTitle] = useState("");

	const fromOrdersRef = useRef(null);
	const toOrdersRef = useRef(null);

	const [fromOrdersFetching, setFromOrdersFetching] = useState(false);
	const [toOrdersFetching, setToOrdersFetching] = useState(false);

	const [playErrorSound] = useSound(errorSound);

	const { 
		data: picking,
		error,
		isFetching: isPickingLoading,
		isSuccess: isPickingSuccess
	} = useGetPickingByIdQuery(
		id,
		{
			refetchOnMountOrArgChange: true,
			skip: false,
		}
	);

	useEffect(() => {
		if (picking) {

			fetchFromOrders();
			fetchToOrders();

			if(picking.kind == 0){
				setFromTitle(t("On Client Side"));
				setToTitle(t("In Warehouse"));
			}
			if(picking.kind == 1){
				setFromTitle(t("In Warehouse"));
				setToTitle(t("On Route"));
				if (hasGroup(['ReverseLogisticsOperator'])){
					navigate('..');
				}
			} 
			if(picking.kind == 2){
				setFromTitle(t("On Route"));
				setToTitle(t("In Warehouse"));
				if (hasGroup(['WarehouseOperator'])){
					navigate('..');
				}
			}

			if(picking && picking.picking_completion_requests && picking.picking_completion_requests.length > 0){
				setSelectedAuthorization(picking.picking_completion_requests[0]);
			}

		}
	}, [picking]);

	useEffect(() => {
		if(error){
			if(!(error instanceof Object)){
				setErrorTitle(t("Error when trying to obtain information of the picking"));
				setErrorDescription(error);
				setErrorModal(true);
			} else {
				setErrorTitle(t("Error when trying to obtain information of the picking"));
				//setErrorDescription(error);
				setErrorModal(true);
			}	
		}
	}, [error]);

	const fetchFromOrders = () => {
		if(picking){
			let url = ``;
			if(picking.kind === 0){
				url = `pickings/${picking.id}/get_pickeable_orders`;
			} else if(picking.kind === 1) {
				url = `pickings/${picking.id}/get_pickeable_out_orders`;
			} else if(picking.kind === 2) {
				url = `pickings/${picking.id}/get_return_pickeable_orders`;
			}

			setFromOrdersFetching(true);
			if(!fromOrdersFetching){
				axios
					.get(url, { params: { page: fromOrdersPage }, })
					.then((res) => {
						setFromOrdersCount(res.count);
						let array = [...fromOrders];
						res.results.forEach(item => {
							let filteredOrder = array.filter(order => order.id !== item.id);
							array = [...filteredOrder, item];
						});
						setFromOrders(array);
						res.next_page_number ? setFromOrdersHasMore(true) : setFromOrdersHasMore(false);
						if(res.next_page_number){
							setFromOrdersPage(res.next_page_number);
						}
						setFromOrdersFetching(false);
					})
					.catch((err) => {
						toast.error(t("Error when trying to obtain information of the orders"), 
							{
								position: "top-center",
								autoClose: 5000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
								theme: "light",
								bodyClassName: "fs-24",
							}
						);

						setFromOrders([]);
						setFromOrdersHasMore(true);
						setFromOrdersPage(1);
						fromOrdersRef.current.pageLoaded = 0;
					});
			}
		}
	};

	/*const { 
		data: operationalBlock,
		error: operationalBlockError,
		isFetching: isOperationalBlockLoading,
		isSuccess: isOperationalBlockSuccess
	} = useGetOperationalBlockByIdQuery(
		picking && picking.operational_block ? picking.operational_block.id : '',
		{
			refetchOnMountOrArgChange: true,
			skip: !(picking && picking.operational_block)
		}
	);

	useEffect(() => {
		if (operationalBlock) {
			fetchToOrders();
		}
	}, [operationalBlock]);*/

	const fetchToOrders = () => {
		
		if(picking){
			let url = ``;
			const id = picking ? picking.id : null;

			if(picking.kind === 0){
				url = `pickings/${id}/get_picked_in_orders`;
			} else if(picking.kind === 1){
				url = `pickings/${id}/get_picked_out_orders`;
			} else if(picking.kind === 2){
				url = `pickings/${id}/get_return_picked_orders`;
			}

			setToOrdersFetching(true);
			if(!toOrdersFetching){
				axios
					.get(url, { params: { page: toOrdersPage } })
					.then((res) => {
						setToOrdersCount(res.count);
						
						let array = [...toOrders];
						res.results.forEach(item => {
							let filteredOrder = array.filter(order => order.id !== item.id);
							array = [...filteredOrder, item];
						});
						setToOrders(array);
						
						res.next_page_number ? setToOrdersHasMore(true) : setToOrdersHasMore(false);
						if(res.next_page_number){
							setToOrdersPage(res.next_page_number);
						}
						setToOrdersFetching(false);
					})
					.catch((err) => {
						toast.error(t("Error when trying to obtain information of the orders"), 
							{
								position: "top-center",
								autoClose: 5000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
								theme: "light",
								bodyClassName: "fs-24",
							}
						);
					});
			}
			
		}
	};

	/* Picking IN */
	const [pickingInOrder, 
		{ error: orderPickingInError, 
			isLoading: orderPickingInLoading,
			isSuccess: orderPickingInSuccess,
			data: orderPickingInData
		}] = usePickingInOrdersMutation();

	useEffect(() => {
		if (orderPickingInSuccess) {
			
			const newFromOrders = fromOrders.filter((item) => item.id !== orderPickingInData.id);
			setFromOrders(newFromOrders);
			setFromOrdersCount((newFromOrders) => newFromOrders -1);

			const orders = [...toOrders, orderPickingInData];
			const sorted = [...orders].sort((a, b) => {
				const a_check = a.created_in_picking && a.customer === null && a.address === null;
				const b_check = b.created_in_picking && b.customer === null && b.address === null;
				if (!a_check && b_check) {
					return 1;
				} else if (!b_check && a_check) {
					return -1;
				}
				return 0;			
			});
			setToOrders(sorted);
			setToOrdersCount((toOrdersCount) => toOrdersCount +1);

			toast.success(t(`Order {{reference}} Picked In!`, { reference: parseInt(orderPickingInData.reference) }), {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
					bodyClassName: "fs-24",
				}
			);
		}
		if (orderPickingInError) {
			let errorString;

			setFromOrders([]);
			setFromOrdersHasMore(true);
			setFromOrdersPage(1);
			fromOrdersRef.current.pageLoaded = 0;

			if(orderPickingInError.data instanceof Array){
				errorString = orderPickingInError.data.join(', ');
			} else if(orderPickingInError.error){
				errorString = orderPickingInError.error;
			} else {
				errorString = t("Unknown error")
			}

			toast.error(errorString, 
				{
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
					bodyClassName: "fs-24",
				}
			);

			playErrorSound();

			/*setErrorTitle(t("Error when trying to picking in the order"));
			setErrorDescription(errorString);
			setErrorModal(true);*/
		}
	}, [orderPickingInSuccess, orderPickingInError]);

	/* Picking OUT */
	const [pickingOutOrder, 
		{ error: orderPickingOutError, 
			isLoading: orderPickingOutLoading,
			isSuccess: orderPickingOutSuccess,
			data: orderPickingOutData
		}] = usePickingOutOrdersMutation();

	useEffect(() => {
		if (orderPickingOutSuccess) {

			const newFromOrders = fromOrders.filter((item) => item.id !== orderPickingOutData.id);
			setFromOrders(newFromOrders);
			setFromOrdersCount((newFromOrders) => newFromOrders -1);

			const orders = [...toOrders, orderPickingOutData];
			const sorted = [...orders].sort((a, b) => {
				const a_check = a.created_in_picking && a.customer === null && a.address === null;
				const b_check = b.created_in_picking && b.customer === null && b.address === null;
				if (!a_check && b_check) {
					return 1;
				} else if (!b_check && a_check) {
					return -1;
				}
				return 0;			
			});
			setToOrders(sorted);
			setToOrdersCount((toOrdersCount) => toOrdersCount +1);

			toast.success(t(`Order {{reference}} Picked Out!`, { reference: parseInt(orderPickingOutData.reference) }), {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
					bodyClassName: "fs-24",
				}
			);
		}
		if (orderPickingOutError) {
			let errorString;
			if(orderPickingOutError.data instanceof Array){
				errorString = orderPickingOutError.data.join(', ');
			} else if(orderPickingOutError.error){
				errorString = orderPickingOutError.error;
			} else {
				errorString = t("Unknown error")
			}

			setFromOrders([]);
			setFromOrdersHasMore(true);
			setFromOrdersPage(1);
			fromOrdersRef.current.pageLoaded = 0;

			toast.error(errorString, 
				{
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
					bodyClassName: "fs-24",
				}
			);

			/*if(orderPickingOutError.status && orderPickingOutError.status === 404){
				setErrorTitle(t("Error when trying to picking out the order"));
				setErrorDescription(t("The entered order doesn't belong to the picking out"));
				setErrorModal(true);

			} else {
				let errorString;

				if(orderPickingOutError.data instanceof Array){
					errorString = orderPickingOutError.data.join(', ');
				} else if(orderPickingOutError.error){
					errorString = orderPickingOutError.error;
				} else {
					errorString = t("Unknown error")
				}

				setErrorTitle(t("Error when trying to picking out the order"));
				setErrorDescription(errorString);
				setErrorModal(true);
			}*/
		}
	}, [orderPickingOutSuccess, orderPickingOutError]);

	/* Picking RETURN */
	const [pickingReturnOrder, 
		{ error: orderPickingReturnError, 
			isLoading: orderPickingReturnLoading,
			isSuccess: orderPickingReturnSuccess,
			data: orderPickingReturnData
		}] = usePickingReturnOrdersMutation();

	useEffect(() => {
		if (orderPickingReturnSuccess) {

			const newFromOrders = fromOrders.filter((item) => item.id !== orderPickingReturnData.id);
			setFromOrders(newFromOrders);
			setFromOrdersCount((newFromOrders) => newFromOrders -1);

			const orders = [...toOrders, orderPickingReturnData];
			const sorted = [...orders].sort((a, b) => {
				const a_check = a.created_in_picking && a.customer === null && a.address === null;
				const b_check = b.created_in_picking && b.customer === null && b.address === null;
				if (!a_check && b_check) {
					return 1;
				} else if (!b_check && a_check) {
					return -1;
				}
				return 0;			
			});
			setToOrders(sorted);
			setToOrdersCount((toOrdersCount) => toOrdersCount +1);

			toast.success(t(`Order {{reference}} Picked for return!`, { reference: parseInt(orderPickingReturnData.reference) }), {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
					bodyClassName: "fs-24",
				}
			);
		}
		if (orderPickingReturnError) {
			
			let errorString;

			if(orderPickingReturnError.data instanceof Array){
				errorString = orderPickingReturnError.data.join(', ');
			} else if(orderPickingReturnError.error){
				errorString = orderPickingReturnError.error;
			} else {
				errorString = t("Unknown error")
			}

			setFromOrders([]);
			setFromOrdersHasMore(true);
			setFromOrdersPage(1);
			fromOrdersRef.current.pageLoaded = 0;

			toast.error(errorString, 
				{
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
					bodyClassName: "fs-24",
				}
			);
			/*setErrorTitle(t("Error when trying to picking out the order"));
			setErrorDescription(errorString);
			setErrorModal(true);*/
		}
	}, [orderPickingReturnSuccess, orderPickingReturnError]);

	/*const [createExternalOrder, 
		{ error: createExternalOrderError, 
			isLoading: createExternalOrderLoading,
			isSuccess: createExternalOrderSuccess,
			data: createExternalOrderData
		}] = useCreateExternalOrderMutation();

	useEffect(() => {
		if (createExternalOrderSuccess) {
			toast.success(`Order ${parseInt(createExternalOrderData.reference)} created and picked In!`, {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
					bodyClassName: "fs-24",
				}
			);
			setLastPickedOrderReference(null);
		}
		if (createExternalOrderError) {
			let errorString;
				
			if(createExternalOrderError.data instanceof Array){
				errorString = createExternalOrderError.data.join(', ');
			} else if(createExternalOrderError.error){
				errorString = createExternalOrderError.error;
			} else {
				errorString = "Unknown error"
			}

			setErrorTitle("Error when trying to picking in the order");
			setErrorDescription(errorString);
			setErrorModal(true);
		}
	}, [createExternalOrderSuccess, createExternalOrderError]);*/

	const [requestPickingReview, 
		{ error: orderRequestPickingReviewError, 
			isLoading: orderRequestPickingReviewLoading,
			isSuccess: orderRequestPickingReviewSuccess,
			data: ordeRequestPickingReviewData
		}] = useRequestPickingReviewMutation();

	useEffect(() => {
		if (orderRequestPickingReviewSuccess) {
			toast.success(
				t(`Picking in review requested successfully!`), 
				{
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
					bodyClassName: "fs-24",
				}
			);
		}
		if(orderRequestPickingReviewError){
			setErrorTitle(t("Problem when requesting picking review"));
			setErrorDescription(orderRequestPickingReviewError);
			setErrorModal(true);
		}
	}, [orderRequestPickingReviewSuccess, orderRequestPickingReviewError]);

	/*const [authorizePickingIn, 
		{ error: authorizePickingError, 
			isLoading: authorizePickingLoading,
			isSuccess: authorizePickingSuccess,
			data: oauthorizePickingData
		}] = useAuthorizePickingMutation();
	
	useEffect(() => {
		if (authorizePickingSuccess) {
			toast.success(t(`Picking in authorized successfully!`), {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
					bodyClassName: "fs-24",
				}
			);
		}
		if (authorizePickingError) {
			setErrorTitle(t("Error when trying to authorize the picking in!"));
			setErrorDescription(authorizePickingError);
			setErrorModal(true);
		}
	}, [authorizePickingSuccess, authorizePickingError]);

	const [denyPickingIn, 
		{ error: denyPickingError, 
			isLoading: denyPickingLoading,
			isSuccess: denyPickingSuccess,
			data: denyPickingData
		}] = useDenyPickingMutation();
	
	useEffect(() => {
		if (denyPickingSuccess) {
			toast.success(t(`Picking in denied successfully!`), {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
					bodyClassName: "fs-24",
				}
			);
		}
		if (denyPickingError) {
			setErrorTitle(t("Error when trying to deny the picking in!"));
			setErrorDescription(denyPickingError);
			setErrorModal(true);
		}
	}, [denyPickingSuccess, denyPickingError]);*/

	const searchPickingCode = (orderReference) => {
		setLastPickedOrderReference(orderReference);
		ref.current.value = "";
		if(picking.kind === 0){
			pickingInOrder({
				id: picking.id,
				body: {order_reference: orderReference}, 
			});
		} else if(picking.kind === 1){
			pickingOutOrder({
				id: picking.id,
				body: {order_reference: orderReference}
			});
		} else {
			pickingReturnOrder({
				id: picking.id,
				body: {order_reference: orderReference},
			});
		}
	};

	/*const onPickingInputChange = useAsyncDebounce((orderReference) => {
		setLastPickedOrderReference(orderReference);
		if(orderReference.length === 13){
			searchPickingCode(orderReference);
		}
	}, 200);*/

	const pickingPressed = useCallback((orderReference) => {
		if(picking.kind === 0){
			pickingInOrder({ 
				id: picking.id,
				body: {order_reference: orderReference}, 
			});
		} else if(picking.kind === 1){
			pickingOutOrder({ 
				id: picking.id,
				body: {order_reference: parseInt(orderReference)}, 
			});
		} else {
			pickingReturnOrder({
				id: picking.id,
				body: {order_reference: orderReference},
			});
		}
	});

	const handleValidDate = date => {
		const date1 = moment(date).format("DD/MM");
		return date1;
	};

	const handleValidTime = (time) => {
		const updateTime = moment(time).format('HH:mm');
		return updateTime;
	};

	/*useEffect(() => {
		if (operationalBlock) {
			let statistics = _.map(operationalBlock.orders_statistics, 'count');
			setStatusAmounts(statistics);
			let statuses = _.map(operationalBlock.orders_statistics, 'display');
			setStatuses(statuses);
		}
	}, [operationalBlock]);*/

	
	// validation
	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		//enableReinitialize: true,

		initialValues: {
			enterpriseId: '',
		},
		
		/*validationSchema: Yup.object({
			enterpriseId: Yup.string().required("Please Enter enterprise Id"),
		}),*/

		onSubmit: (values) => {
			const formData = new FormData();
			formData.append("company_id", 1)
			formData.append("file", selectedFiles[0]);

			importOrders(formData);

			validation.resetForm();

			toggle();
		},
	});
	
	const toggle = useCallback(() => {
		if (completePickingModal) {
			setCompletePickingModal(false);
		} else {
			setCompletePickingModal(true);
		}
	}, [completePickingModal]);

	const authorizeToggle = useCallback(() => {
		if (requestAuthorizePickingModal) {
			setRequestAuthorizationPickingModal(false);
		} else {
			setRequestAuthorizationPickingModal(true);
		}
	}, [requestAuthorizePickingModal]);

	const createExternalToggle = useCallback(() => {
		if (createExternalOrderModal) {
			setCreateExternalOrderModal(false);
		} else {
			setCreateExternalOrderModal(true);
		}
	}, [createExternalOrderModal]);


	const handleCompletePicking = () => {
		toggle();
		requestPickingReview({id: id});
	};

	/*const handleAuthorizePicking = (request) => {
		authorizeToggle();
		authorizePickingIn({id: request.id, pickingId: id});
	};

	const handleDenyPicking = (request) => {
		authorizeToggle();
		denyPickingIn({id: request.id, pickingId: id});
	};*/

	const handleCreateExternalOrder = () => {
		createExternalToggle();
		navigate(`new-order`, {
			state: {
				orderReference: lastPickedOrderReference,
			}
		});
	};

	const handlePickingCompletionRequestsClose = () => {
		authorizeToggle();
	};

	const handleAuthorizeSelected = (authorization) => {
		setSelectedAuthorization(authorization);
		authorizeToggle();
	};

	const handlePercentage = (partialValue, totalValue) => {
		if (totalValue > 0){
			const value = Math.round((partialValue / totalValue) * 100);
			return value;
		} else{
			return 0;
		}
	};

	document.title="Sistema de pedidos Transchile";

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					<BreadCrumb title={t("Picking Details")} pageTitle={t("Pickings")} />
					<ConfirmModal
						show={completePickingModal}
						onConfirmClick={() => handleCompletePicking()}
						onCloseClick={() => setCompletePickingModal(false)}
						title={t("Complete picking in")}
						question={t("An administrator must authorize the completion of this inbound picking. Do you want to continue?")}
						yesQuestion={t("Yes, request review")}
					/>
					{/*<ConfirmModal
						show={requestAuthorizePickingModal}
						onConfirmClick={() => handleAuthorizePicking()}
						onCloseClick={() => setRequestAuthorizationPickingModal(false)}
						title="Authorize picking in"
						question="Do you want to authorize the completion of inbound picking?"
						yesQuestion="Yes, authorize it!"
					/>*/}
					<ErrorModal
						show={errorModal}
						onCloseClick={() => setErrorModal(false)}
						title={errorTitle}
						description={errorDescription}
					/>
					<ConfirmModal
						show={createExternalOrderModal}
						onConfirmClick={() => handleCreateExternalOrder()}
						onCloseClick={() => setCreateExternalOrderModal(false)}
						title={t("Order not found in the imported data")}
						question={t("Do you want to create an order in the status 'without management'?")}
						yesQuestion={t("Yes, create a order")}
					/>
					{/*selectedAuthorization && (
						<PickingCompletionRequests
							request={selectedAuthorization}
							isOpen={requestAuthorizePickingModal}
							onAuthorizeClick={()=> {
								setRequestAuthorizationPickingModal(false);
								handleAuthorizePicking(selectedAuthorization);
							}}
							onDenyClick={()=> {
								setRequestAuthorizationPickingModal(false);
								handleDenyPicking(selectedAuthorization);
							}}
							onCloseClick={() => handlePickingCompletionRequestsClose()}
						/>
						)
					<ConfirmModal
						show={authorizePickingModal}
						onConfirmClick={() => handleAuthorizePicking()}
						onCloseClick={() => setAuthorizePickingModal(false)}
						title={t("Authorize picking in")}
						question={t("Do you want to authorize the picking in despite the indicated warnings?")}
						yesQuestion={t("Yes, authorize it")}
					/>*/}
					<Row>
						<Col xxl={3}>
							<Card id="picking-overview">
								<CardHeader className="align-items-center d-flex">
									<h4 className="card-title mb-0 flex-grow-1">{t("Picking Information")}</h4>
								</CardHeader>
								<CardBody>
									{isPickingLoading && <Loader/>}
									{isPickingSuccess && (
										<div className="table-responsive table-card">
											<Table className="table mb-0">
												<tbody>
													<tr>
														<td className="fw-medium text-muted">{t("Picking Reference ID")}</td>
														<td className="overview-industryType">{parseInt(id)}</td>
													</tr>
													<tr>
														<td className="fw-medium text-muted">{t("Kind")}</td>
														<td className="overview-industryType">
															<PickingKind status={picking.kind} status_text={picking.kind_text} />
														</td>
													</tr>
													<tr>
														<td className="fw-medium text-muted">{t("Status")}</td>
														<td className="overview-industryType">
															<PickingStatus status={picking.status} status_text={picking.status_text} />
														</td>
													</tr>
													{picking.driver && picking.driver.user && (
														<tr>
															<td className="fw-medium text-muted">{t("Driver")}</td>
															<td className="overview-industryType">
																{picking.driver.user.first_name} {picking.driver.user.last_name}
															</td>
														</tr>
													)}
													<tr>
														<td className="fw-medium text-muted">{t("Created at")}</td>
														<td className="overview-company_location">
															{handleValidDate(picking.created_at)} {handleValidTime(picking.created_at)}
														</td>
													</tr>
												</tbody>
											</Table>
											{picking.status === 1 && hasGroup(['Manager', 'OperationalManager', 'RouteManager', 'WarehouseOperator']) && (
												<div className="p-3">
													<button 
														type="button"
														className="btn btn-primary w-100"
														disabled={orderRequestPickingReviewLoading}
														onClick={() => toggle()}>
															{orderRequestPickingReviewLoading && <Spinner size='sm' color="secondary"/>}
															{t("Complete picking")}
													</button>
												</div>
											)}
										</div>

									)}
								</CardBody>
							</Card>

							{/*<Card id="company-overview">
								{isOperationalBlockLoading && <Loader/>}
								{isOperationalBlockSuccess && (
									<>
										<CardHeader className="align-items-center d-flex">
											<h4 className="card-title mb-0 flex-grow-1">{t("Billing Day")}</h4>
										</CardHeader>
										<CardBody>
											<div className="text-center">
												<Link to={`../operational-blocks/${operationalBlock.id}`} className="h2 overview-companyname">
												{handleValidDate(operationalBlock.billing_date)}
												</Link>
											</div>
										</CardBody>
									</>
								)}
								</Card>*/}

							<Card className="mb-3">
								<CardHeader className="align-items-center d-flex">
									<h4 className="card-title mb-0 flex-grow-1">{t("Progress")}</h4>
								</CardHeader>
								<CardBody>
									{isPickingLoading && <Loader/>}
									{isPickingSuccess && (
										<div className="d-flex align-items-center">
											<div className="flex-grow-1 overflow-hidden">
											<p className="text-uppercase fw-medium text-muted text-truncate mb-3">
												{t("Boxes")}
											</p>
											<h4 className="fs-22 fw-semibold ff-secondary mb-0">
												{picking.kind === 0 && (
													<span className="counter-value" data-target="36894">
														{picking.picked_in_orders_count}/{picking.customer_orders_count}
													</span>
												)}
												{(picking.kind === 1 || picking.kind === 2) && (
													<span className="counter-value" data-target="36894">
														{picking.picked_out_orders_count}/{picking.picking_out_orders_count}
													</span>
												)}
											</h4>
											</div>
											<div className="flex-shrink-0">
												{picking.kind === 0 && (
													<PickingChart
														seriesData={handlePercentage(picking.picked_in_orders_count, picking.customer_orders_count)}
														colors={getChartColorsArray('["--vz-success"]')[0]}
													/>
												)}
												{(picking.kind === 1 || picking.kind === 2) && (
													<PickingChart
														seriesData={handlePercentage(picking.picked_out_orders_count, picking.picking_out_orders_count)}
														colors={getChartColorsArray('["--vz-success"]')[0]}
													/>
												)}
											</div>
										</div>
									)}
								</CardBody>
							</Card>

							{picking && picking.picking_completion_requests && picking.picking_completion_requests.length > 0 && (
								<Card id="auth">
									{isPickingLoading && <Loader/>}
									{isPickingSuccess && (
										<>
											<CardHeader className="align-items-center d-flex">
												<h4 className="card-title mb-0 flex-grow-1">{t("Authorizations requests")}</h4>
											</CardHeader>
											<CardBody>
												{picking.picking_completion_requests.map((request, key) => (
													<Link to={`../picking-completion-requests/${request.id}`} key={key}>
														<div className="p-3">
															<div className="d-flex mb-3">
																<div className="flex-shrink-0 avatar-xs">
																	<div className="avatar-title bg-soft-danger text-danger rounded fs-16">
																		<i className="ri-file-shield-line"></i>
																	</div>
																</div>
																<div className="flex-grow-1 ms-3">
																	<h6 className="mb-1">{handleValidDate(request.created_at)}</h6>
																	<div className="text-muted mb-0"><PickingCompletionRequestStatus status={request.status} status_text={request.status_text} /></div>
																</div>
															</div>
														</div>
													</Link>
												))}
											</CardBody>
										</>
									)}
								</Card>
							)}

							{hasGroup(['Manager', 'OperationalManager', 'RouteManager', 'Executives', 'OutOfBoxOperator']) && (isPickingSuccess && picking.kind === 1 && picking.status === 3) && (
								<Card id="out">
									<CardHeader className="align-items-center d-flex">
										<h4 className="card-title mb-0 flex-grow-1">{t("Out of box")}</h4>
									</CardHeader>
									<CardBody>
										<div className="p-0">
											<Link to={`out-of-box/`} target="_blank" className="btn btn-primary w-100">
												Ver
											</Link>
										</div>
									</CardBody>
								</Card>
							)}

							{(isPickingSuccess && picking.kind === 1 && picking.status === 3) && (
								<Card id="routes">
									<CardHeader className="align-items-center d-flex">
										<h4 className="card-title mb-0 flex-grow-1">{t("Route")}</h4>
									</CardHeader>
									<CardBody>
										<div className="p-0">
											<Link to={`../routes/${picking.id}`} className="btn btn-secondary w-100">
												Ver
											</Link>
										</div>
									</CardBody>
								</Card>
							)}

						</Col>

						<Col xxl={9}>
							<Row>
								<Col xl={6}>
									<Card className="overflow-hidden">
										<CardHeader className="bg-soft-success">
											<h5 className="fs-24 text-center mb-0">{fromTitle} <Badge color="primary" className="ms-1">{fromOrdersCount}</Badge></h5>
										</CardHeader>
										<CardBody className="bg-soft-success">
											<div className="hstack gap-3 px-3 mx-n3">
												{isPickingSuccess && (
													<input
														autoFocus
														ref={ref}
														className="form-control me-auto"
														type="text"
														placeholder={t("Enter box reference")}
														onKeyUp={e => {
															if (e.key === 'Enter' && picking.status === 1) {
																searchPickingCode(e.target.value);
															}
														}}
													/>
												)}
												{isPickingSuccess && (
													<button 
														type="button" 
														className="btn btn-success w-xs"
														disabled={picking.status !== 1}
														onClick={(e) => {
															searchPickingCode(ref.current.value.toString());
														}}>
														{picking.kind == 0 ? t("Pick in") : t("Pick out")}
													</button>
												)}
											</div>
										</CardBody>
									</Card>

									<InfiniteScroll
										ref={fromOrdersRef}
										pageStart={0}
										loadMore={fetchFromOrders}
										hasMore={fromOrdersHasMore}
										loader={<h4 className="d-flex justify-content-center mx-2 mt-2" key={0}>{t("Loading")}...</h4>}
									>
										{fromOrders.map((item, key) => (
											<Card
												className="mb-1 ribbon-box ribbon-fill ribbon-sm"
												key={item.id}
											>	
												<CardBody>
													<div
														to="#"
														className="d-flex align-items-center"
													>
														<div className="flex-grow-1 ms-3">
															{item.created_in_picking && item.customer === null && item.address === null && (
																<Link to={`../order-sets/${item.order_set_id}/edit`} className="btn btn-danger btn-sm float-end">
																	{t("Complete order info")}
																</Link>
															)}
															{/*<button 
																type="button" 
																className="btn btn-success btn-sm float-end"
																value={item.reference}
																disabled={picking.status !== 1}
																onClick={(e) => {
																	pickingPressed(e.target.value);
																}}>
																{picking.kind == 0 ? t("Pick in") : t("Pick out")}
															</button>*/}
															<Link to={`../boxes/${item.id}`} target="_blank" className="h2 ff-secondary fw-medium">
																{parseInt(item.reference)}
															</Link>
															{ item.customer && item.address && (
																<>
																	<h6 className="fs-14 mb-1 mt-1">
																		<i className="ri-user-line align-bottom me-1"></i>{" "}
																		{item.customer.name}
																	</h6>
																	<p className="text-muted mb-0">
																		<i className="ri-map-pin-line align-bottom me-1"></i>{" "}
																		{item.address ? item.address.address_string : ''}
																	</p>
																	<p className="mt-1 mb-0">
																		<span className="ms-2">
																			<span className="text-muted align-bottom">{t("Zone: ")}</span>
																			{item.zone}
																		</span>
																		<span className="ms-2">
																			<span className="text-muted align-bottom">{t("Campaign: ")}</span>
																			{item.campaign_reference}
																		</span>
																	</p>
																</>
															)}
														</div>
													</div>
												</CardBody>
											</Card>
										))}
									</InfiniteScroll>
								</Col>

								<Col xl={6}>

									<Card className="overflow-hidden">
										<CardHeader className="bg-soft-success">
											<h5 className="fs-24 text-center mb-0">{toTitle} <Badge color="success" className="ms-1">{toOrdersCount}</Badge></h5>
										</CardHeader>
										<CardBody className="bg-soft-success">
											<div className="d-flex flex-row-reverse">

												{picking && picking.kind === 0 && (
													<Link
														to={`new-order`}
														className={`btn btn-success float-right ${picking.status !== 1 ? 'disabled' : ''}`}
													>
														<i className="ri-add-line align-middle me-1"></i>{" "}
														{t("Create box")}
													</Link>
												)}
											</div>
										</CardBody>
									</Card>

									<InfiniteScroll
										ref={toOrdersRef}
										pageStart={0}
										loadMore={fetchToOrders}
										hasMore={toOrdersHasMore}
										loader={<h4 className="d-flex justify-content-center mx-2 mt-2" key={0}>{t("Loading")}...</h4>}
									>
										{toOrders.map((item, key) => (
											<Card
												className={`mb-1 ribbon-box ribbon-fill ribbon-sm tasks-list-2 ${item.created_in_picking && item.customer === null && item.address === null ? "bg-soft-warning" : ""}`}
												key={item.id}
											>	
												<CardBody>
													<div
														to="#"
														className={`d-flex align-items-center`}
													>
														<div className="flex-grow-1 ms-3">
															{item.created_in_picking && item.customer === null && item.address === null && (
																<Link to={`../order-sets/${item.order_set_id}/edit`} /*target="_blank"*/ className="btn btn-danger btn-sm float-end">
																	{t("Complete order info")}
																</Link>
															)}
															<Link to={`../boxes/${item.id}`} target="_blank" className="h2 ff-secondary fw-medium">
																{parseInt(item.reference)}
															</Link>
															{ item.customer && item.address && (
																<>
																	<h6 className="fs-14 mb-1 mt-1">
																		<i className="ri-user-line align-bottom me-1"></i>{" "}
																		{item.customer.name}
																	</h6>
																	<p className="text-muted mb-0">
																		<i className="ri-map-pin-line align-bottom me-1"></i>{" "}
																		{item.address ? item.address.address_string : ''}
																	</p>
																	<p className="mt-1 mb-0">
																		<span className="text-muted align-bottom">{t("Billing: ")}</span>
																		{handleValidDate(item.billing_date)}
																		<span className="ms-5">
																			<span className="text-muted align-bottom">{t("Zone: ")}</span>
																			{item.zone}
																		</span>
																		<span className="ms-5">
																			<span className="text-muted align-bottom">{t("Campaign: ")}</span>
																			{item.campaign_reference}
																		</span>
																	</p>
																</>
															)}
														</div>
													</div>
												</CardBody>
											</Card>
										))}
									</InfiniteScroll>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default withTranslation()(PickingDetails);