import React, { Fragment, useState, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import * as moment from "moment";
import OrdersTableBase from "../../Components/Commons/OrdersTableBase";
import PickingStatus from "./PickingStatus";
import PickingOrderStatusPlot from "./PickingOrderStatusPlot";
import PickingKind from "./PickingKind";
import { withTranslation } from 'react-i18next';
import { hasGroup } from "../../api/session_helper";

import 'react-toastify/dist/ReactToastify.css';


const PickingsTable = ({
  data,
  isLoading,
  isSuccess,
  error,
  t,
  i18n
}) => {

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".orderCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };

  const toggle = useCallback(() => {
    
  });

  const handleOrderClick = useCallback((arg) => {
    const order = arg;
    setOrder({
      _id: order._id,
      orderId: order.orderId,
      customer: order.customer,
      product: order.product,
      orderDate: order.orderDate,
      ordertime: order.ordertime,
      amount: order.amount,
      payment: order.payment,
      status: order.status
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);

  const handleValidDate = date => {
    const date1 = moment(date).format("DD/MM");
    return date1;
  };

  const handleValidTime = (time) => {
    const updateTime = moment(time).format('HH:mm');
    return updateTime;
  };
  
  // Column
  const columns = useMemo(
    () => [
      {
        Header: "#",
				Cell: (picking) => {
          let in_permission = picking.row.original.kind === 0 && hasGroup(['Manager', 'OperationalManager', 'RouteManager', 'Executives', 'ReverseLogisticsOperator']);
          let out_permission = picking.row.original.kind === 1 && hasGroup(['Manager', 'OperationalManager', 'RouteManager', 'Executives']);
          let ret_permission = picking.row.original.kind === 2 && hasGroup(['Manager', 'OperationalManager', 'RouteManager', 'ReverseLogisticsOperator']);
          if(in_permission || out_permission || ret_permission){
            return <Link to={`../pickings/${picking.row.original.id}`}>
              <PickingKind status={picking.row.original.kind} status_text={picking.row.original.kind_text} />
            </Link>
          } else {
            return <PickingKind status={picking.row.original.kind} status_text={picking.row.original.kind_text} />
          }
        }
      },
      {
        Header: t("Picking Id"),
        accessor: "id",
        filterable: false,
        Cell: (cell) => {
          return <Link to={`../pickings/${cell.row.original.id}`} className="fw-medium link-primary h5">{parseInt(cell.value)}</Link>;
        },
      },
      {
        Header: t("Created at"),
        accessor: "created_at",
        Cell: (picking) => (
          <>
            {handleValidDate(picking.row.original.created_at)},
            <small className="text-muted"> {handleValidTime(picking.row.original.created_at)}</small>
          </>
        ),
      },
      {
        Header: t("Driver"),
        Cell: (picking) => (
          <div className="d-flex">
            <div>
              {picking.row.original.driver && <h5 className="fs-13 mb-0">{picking.row.original.driver.name}</h5>}
              {picking.row.original.driver && <p className="fs-12 mb-0 text-muted">{picking.row.original.driver.user.email}</p>}
            </div>
          </div>
        ),
      },
      {
        Header: t("Zones"),
        accessor: "zones",
        Cell: (picking) => {
          if(typeof picking.row.original.zones !== "undefined"){
            return <div className="d-flex">{picking.row.original.zones.join(', ')}</div>;
          } else {
            return '--';
          }
          
        },
      },
      /*{
        Header: t("Billing day"),
        accessor: "billing_date",
        Cell: (picking) => {
          if(picking.row.original.operational_block){
            return <Link to={`../operational-blocks/${picking.row.original.operational_block.id}`} className="link-primary">
              {handleValidDate(picking.row.original.operational_block.billing_date)}
            </Link>
          } else {
            return handleValidDate(picking.row.original.operational_block.billing_date);
          }
        },
      },*/
      {
        Header: t("Orders"),
        filterable: false,
        Cell: (picking) => {
          if(picking.row.original.kind === 0){
            return <>
              <h6 className="mb-0">
                {picking.row.original.picked_in_orders_count} / 
                <span className="text-muted"> {picking.row.original.customer_orders_count} </span>
              </h6>
            </>;
          } else {
            return <>
              <h6 className="mb-0">
                {picking.row.original.picked_out_orders_count} / 
                <span className="text-muted"> {picking.row.original.picking_out_orders_count} </span>
              </h6>
            </>;
          }
        },
      },
      {
        Header: t("Orders Status"),
        filterable: false,
        Cell: (picking) => {
          return <PickingOrderStatusPlot 
            seriesData={picking.row.original.percentage} 
            chartsColor={"#405189"} />;
          
        },
      },
      {
        Header: t('Status'),
        Cell: (picking) => (
          <PickingStatus status={picking.row.original.status} status_text={picking.row.original.status_text} />
        )
      },
    ],
    [handleOrderClick, checkedAll]
  );

  return (
    <OrdersTableBase
      columns={columns}
      data={data}
      isLoading={isLoading}
      isSuccess={isSuccess}
      error={error}
      manualPagination={true}
      divClass="table-responsive table-card mb-1"
      tableClass="align-middle table-wrap"
      theadClass="table-light text-muted"
    />
  );
};

export default  withTranslation()(PickingsTable);

