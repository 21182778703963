import React, { useState, useEffect, useCallback } from "react";
import { Card, CardBody, CardHeader, Col, Container, Form, Row, Label, Input, FormFeedback } from "reactstrap";
import { withTranslation } from 'react-i18next';
import BreadCrumb from "../../Components/Commons/BreadCrumb";
import GenericModal from "../../Components/Commons/GenericModal";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
	useCreateOrderMutation, 
	useUpdateOrderMutation,
	useGetOrderByIdQuery,
} from '../../api/api'
import Flatpickr from "react-flatpickr";

const CreateOrder = ({t, i18n}) => {

	let { id } = useParams();

	const navigate = useNavigate();
	const location = useLocation();

	const [createOrder, 
		{ error: errors, 
			isLoading: isCreationLoading,
			isSuccess: isCreationSuccess,
			data: creationData
		}] = useCreateOrderMutation();

	const [updateOrder, 
		{ error: updateErrors, 
			isLoading: isUpdateLoading,
			isSuccess: isEditionSuccess,
			data: updateData
		}] = useUpdateOrderMutation();

	const { 
		data: order,
		error: orderError,
		isFetching: isOrderLoading,
		isSuccess: isOrderSuccess
	} = useGetOrderByIdQuery(
		id ? id : '',
		{
			refetchOnMountOrArgChange: true,
			skip: !id || location.pathname.includes('/pickings/'),
		}
	);

	const [successModal, setSuccessModal] = useState(false);
	const [errorModal, setErrorModal] = useState(false);
	const [editedOrder, setEditedOrder] = useState(null);

	useEffect(() => {
		if (order) {
			setEditedOrder(order);
		}
	}, [isOrderSuccess]);

	useEffect(() => {
		if (isCreationSuccess || isEditionSuccess) {
			setSuccessModal(true);
		}
	}, [isCreationSuccess, isEditionSuccess]);

	useEffect(() => {
		if (errors || updateErrors) {
			setErrorModal(true);
		}
	}, [errors, updateErrors]);

	const successModalButtonPressed = useCallback(() => {
		setSuccessModal(false);
		//let data = order ? updateData : creationData;
		navigate(`../../pickings/${id}`);
	}, [successModal]);

	// validation
	const validation = useFormik({
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: false,

		initialValues: {
			reference: (editedOrder && editedOrder.reference) || '',
			campaign_reference: (editedOrder && editedOrder.campaign_reference) || '',
			billing_date: (editedOrder && editedOrder.billing_date) || '',
			quantity: (editedOrder && editedOrder.quantity) || '',
			zone: (editedOrder && editedOrder.zone) || '',
			territory: (editedOrder && editedOrder.territory) || '',
			afp_name: (editedOrder && editedOrder.afp_name) || '',
			section: (editedOrder && editedOrder.section) || '',
			customer_name: (editedOrder && editedOrder.customer && editedOrder.customer.name) || '',
			customer_address: (editedOrder && editedOrder.customer && editedOrder.address.address_string) || '',
			customer_reference: (editedOrder && editedOrder.customer && editedOrder.customer.reference) || '',
		},
		
		validationSchema: Yup.object({
			reference: Yup.string().required(t("Please enter a reference")),
			campaign_reference: Yup.string().required(t("Please enter a capaign reference")),
			billing_date: Yup.string().required(t("Please enter a billing day")),
			quantity: Yup.number().moreThan(-1).required(t("Please enter a quantity")),
			zone: Yup.string().required(t("Please enter a zone")),
			territory: Yup.string().required(t("Please enter a territory")),
			afp_name: Yup.string(),
			section: Yup.string().required(t("Please enter a section")),
			customer_name: Yup.string().required(t("Please enter a customer name")),
			customer_address: Yup.string().required(t("Please enter a customer address")),
			customer_reference: Yup.string().required(t("Please enter a customer reference")),
		}),

		onSubmit: (values) => {
			if(order){
				const address = {
					address: values["customer_address"],
					address_string: values["customer_address"],
				}
				const customer = {
					name: values["customer_name"],
					reference: values["customer_reference"]
				}
				const editOrder = {
					reference: values["reference"],
					campaign_reference: values["campaign_reference"],
					billing_date: values["billing_date"],
					box_quantity: values["box_quantity"],
					quantity: values["quantity"],
					zone: values["zone"],
					territory: values["territory"],
					afp_name: values["afp_name"],
					section: values["section"],
					customer: customer,
					address: address,
					picking_id: id
				};
				setEditedOrder(editOrder);
				updateOrder({id: id, body: editOrder});
				validation.resetForm();
			} else {
				const address = {
					address: values["customer_address"],
					address_string: values["customer_address"],
				}
				const customer = {
					name: values["customer_name"],
					reference: values["customer_reference"]
				}
				const newOrder = {
					reference: values["reference"],
					campaign_reference: values["campaign_reference"],
					billing_date: values["billing_date"],
					box_quantity: values["box_quantity"],
					quantity: values["quantity"],
					zone: values["zone"],
					territory: values["territory"],
					afp_name: values["afp_name"],
					section: values["section"],
					customer: customer,
					address: address,
					picking_in_id: id
				};
				setEditedOrder(newOrder);
				createOrder(newOrder);
				validation.resetForm();
			}
		},
	});
	
	document.title = "Sistema de pedidos Transchile";

	return (
		<React.Fragment>
			<div className="page-content">
				<GenericModal
					show={successModal}
					title={t("Order information saved")}
					message={t('All data was saved successfully')}
					okButtonText={'OK'}
					onOkClick={() => successModalButtonPressed()}
					onBackClick={() => navigate(-1)}
				/>
				<GenericModal
					show={errorModal}
					title={t("Order information not saved")}
					message={t('An error occurred while saving Order data')}
					okButtonText={'OK'}
					onOkClick={() => setErrorModal(false)}
				/>
				<Container fluid>
					<BreadCrumb title={order ? t("Edit Box") : t("Create Box")} pageTitle={t("Orders")} />
					<Form className="tablelist-form" onSubmit={validation.handleSubmit}>
						<Row>
						
							<Col lg={8}>
								<Card>
									<CardHeader>
										<h5 className="card-title mb-0">{t("Box Information")}</h5>
									</CardHeader>
									<CardBody>
									
										<Row className="g-3">

											<Col lg={4}>
												<Label htmlFor="reference" className="form-label" >
													{t("Box Reference Id")}
												</Label>
												<Input
													name="reference"
													className="form-control"
													id="reference"
													placeholder={t("Box Reference Id")}
													onChange={validation.handleChange}
													onBlur={validation.handleBlur}
													value={validation.values.reference || ""}
													invalid={
														(validation.errors.reference) || (errors && errors.reference) /*|| (updateErrors && updateErrors.reference)*/ ? true : false
													}
												></Input>
												{validation.touched.reference && validation.errors.reference ? (
													<FormFeedback type="invalid">{validation.errors.reference}</FormFeedback>
												) : null}
												<FormFeedback type="invalid">{errors && errors.reference ? (<div>{errors.reference}</div>) : null}</FormFeedback>
												{/*<FormFeedback type="invalid">{updateErrors && updateErrors.reference ? (<div>{updateErrors.reference}</div>) : null}</FormFeedback>*/}
											</Col>

											<Col lg={4}>
												<Label htmlFor="campaign_reference" className="form-label" >
													{t("Campaign Id")}
												</Label>
												<Input
													name="campaign_reference"
													className="form-control"
													id="campaign_reference"
													placeholder={t("Campaign Id")}
													onChange={validation.handleChange}
													onBlur={validation.handleBlur}
													value={validation.values.campaign_reference || ""}
													invalid={
														(validation.errors.campaign_reference) || (errors && errors.campaign_reference) /*|| (updateErrors && updateErrors.campaign_reference)*/ ? true : false
													}
												></Input>
												{validation.touched.campaign_reference && validation.errors.campaign_reference ? (
													<FormFeedback type="invalid">{validation.errors.campaign_reference}</FormFeedback>
												) : null}
												<FormFeedback type="invalid">{errors && errors.campaign_reference ? (<div>{errors.campaign_reference}</div>) : null}</FormFeedback>
												{/*<FormFeedback type="invalid">{updateErrors && updateErrors.campaign_reference ? (<div>{updateErrors.campaign_reference}</div>) : null}</FormFeedback>*/}
											</Col>

											<Col sm={4}>
												<div>
													<Label htmlFor="billing_date" className="form-label" >
														{t("Billing Day")}
													</Label>
													<Flatpickr
														className="form-control"
														id="billing_date"
														placeholder={t("Billing Day")}
														options={{
															mode: "single",
															dateFormat: "Y-m-d",
														}}
														rules={{ required: true }}
													onChange={(selectedDates, dateStr, instance) => validation.setFieldValue('billing_date', selectedDates[0])}
													/>
													<FormFeedback type="invalid">{errors && errors.billing_date ? (<div>{errors.billing_date}</div>) : null}</FormFeedback>
													{/*<FormFeedback type="invalid">{updateErrors && updateErrors.billing_date ? (<div>{updateErrors.billing_date}</div>) : null}</FormFeedback>*/}
												</div>
											</Col>

											<Col lg={8}>
												<Label htmlFor="afp_name" className="form-label" >
													{t("AFP Name")}
												</Label>
												<Input
													name="afp_name"
													className="form-control"
													id="afp_name"
													placeholder={t("AFP Name")}
													onChange={validation.handleChange}
													onBlur={validation.handleBlur}
													value={validation.values.afp_name || ""}
													invalid={
														(validation.errors.afp_name) || (errors && errors.afp_name) /*|| (updateErrors && updateErrors.afp_name)*/ ? true : false
													}
												></Input>
												{validation.touched.afp_name && validation.errors.afp_name ? (
													<FormFeedback type="invalid">{validation.errors.afp_name}</FormFeedback>
												) : null}
												<FormFeedback type="invalid">{errors && errors.afp_name ? (<div >{errors.afp_name}</div>) : null}</FormFeedback>
												{/*<FormFeedback type="invalid">{updateErrors && updateErrors.afp_name ? (<div>{updateErrors.afp_name}</div>) : null}</FormFeedback>*/}
											</Col>

											<Col lg={4}>
												<Label htmlFor="quantity" className="form-label" >
													{t("AFP Quantity")}
												</Label>
												<Input
													name="quantity"
													className="form-control"
													id="quantity"
													placeholder={t("AFP Quantity")}
													onChange={validation.handleChange}
													onBlur={validation.handleBlur}
													value={validation.values.quantity || ""}
													invalid={
														(validation.errors.quantity) || (errors && errors.quantity) /*|| (updateErrors && updateErrors.quantity)*/ ? true : false
													}
												></Input>
												{validation.touched.quantity && validation.errors.quantity ? (
													<FormFeedback type="invalid">{validation.errors.quantity}</FormFeedback>
												) : null}
												<FormFeedback type="invalid">{errors && errors.quantity ? (<div>{errors.quantity}</div>) : null}</FormFeedback>
												{/*<FormFeedback type="invalid">{updateErrors && updateErrors.quantity ? (<div>{updateErrors.quantity}</div>) : null}</FormFeedback>*/}
											</Col>

											<Col lg={3}>
												<Label htmlFor="zone" className="form-label" >
													{t("Zone")}
												</Label>
												<Input
													name="zone"
													className="form-control"
													id="zone"
													placeholder={t("Zone")}
													onChange={validation.handleChange}
													onBlur={validation.handleBlur}
													value={validation.values.zone || ""}
													invalid={
														(validation.errors.zone) || (errors && errors.zone) /*|| (updateErrors && updateErrors.zone)*/ ? true : false
													}
												></Input>
												{validation.touched.zone && validation.errors.zone ? (
													<FormFeedback type="invalid">{validation.errors.zone}</FormFeedback>
												) : null}
												<FormFeedback type="invalid">{errors && errors.zone ? (<div>{errors.zone}</div>) : null}</FormFeedback>
												{/*<FormFeedback type="invalid">{updateErrors && updateErrors.zone ? (<div>{updateErrors.zone}</div>) : null}</FormFeedback>*/}
											</Col>

											<Col lg={6}>
												<Label htmlFor="territory" className="form-label" >
													{t("Territory")}
												</Label>
												<Input
													name="territory"
													className="form-control"
													id="territory"
													placeholder={t("Territory")}
													onChange={validation.handleChange}
													onBlur={validation.handleBlur}
													value={validation.values.territory || ""}
													invalid={
														(validation.errors.territory) || (errors && errors.territory) /*|| (updateErrors && updateErrors.territory)*/ ? true : false
													}
												></Input>
												{validation.touched.territory && validation.errors.territory ? (
													<FormFeedback type="invalid">{validation.errors.territory}</FormFeedback>
												) : null}
												<FormFeedback type="invalid">{errors && errors.territory ? (<div>{errors.territory}</div>) : null}</FormFeedback>
												{/*<FormFeedback type="invalid">{updateErrors && updateErrors.territory ? (<div>{updateErrors.territory}</div>) : null}</FormFeedback>*/}
											</Col>

											<Col lg={3}>
												<Label htmlFor="section" className="form-label" >
													{t("Section")}
												</Label>
												<Input
													name="section"
													className="form-control"
													id="section"
													placeholder={t("Section")}
													onChange={validation.handleChange}
													onBlur={validation.handleBlur}
													value={validation.values.section || ""}
													invalid={
														(validation.errors.section) || (errors && errors.section) /*|| (updateErrors && updateErrors.territory)*/ ? true : false
													}
												></Input>
												{validation.touched.section && validation.errors.section ? (
													<FormFeedback type="invalid">{validation.errors.section}</FormFeedback>
												) : null}
												<FormFeedback type="invalid">{errors && errors.section ? (<div>{errors.section}</div>) : null}</FormFeedback>
												{/*<FormFeedback type="invalid">{updateErrors && updateErrors.territory ? (<div>{updateErrors.territory}</div>) : null}</FormFeedback>*/}
											</Col>

										</Row>
									
									</CardBody>
								</Card>
							</Col>
							<Col lg={4}>
								<Card>
									<CardHeader>
										<h5 className="card-title mb-0">{t("Customer Information")}</h5>
									</CardHeader>
									<CardBody>
										<div className="mb-3">
											<Label htmlFor="name" className="form-label" >
												{t("Name")}
											</Label>
											<Input
												name="customer_name"
												className="form-control"
												id="customer_name"
												placeholder={t("Name")}
												onChange={validation.handleChange}
												onBlur={validation.handleBlur}
												value={validation.values.customer_name || ""}
												invalid={
													(validation.errors.customer_name) || (errors && errors.customer && errors.customer.name) /*|| (updateErrors && updateErrors.customer && updateErrors.customer.name)*/ ? true : false
												}
											></Input>
											{validation.touched.customer_name && validation.errors.customer_name ? (
												<FormFeedback type="invalid">{validation.errors.customer_name}</FormFeedback>
											) : null}
											<FormFeedback type="invalid">{errors && errors.customer && errors.customer.name ? (<div>{errors.customer.name}</div>) : null}</FormFeedback>
											{/*<FormFeedback type="invalid">{updateErrors && updateErrors.customer && updateErrors.customer.name ? (<div>{updateErrors.customer.name}</div>) : null}</FormFeedback>*/}
										</div>
										<div className="mb-3">
											<Label htmlFor="address" className="form-label" >
												{t("Address")}
											</Label>
											<Input
												name="customer_address"
												className="form-control"
												id="customer_address"
												placeholder={t("Address")}
												onChange={validation.handleChange}
												onBlur={validation.handleBlur}
												value={validation.values.customer_address || ""}
												invalid={
													(validation.errors.customer_address) || (errors && errors.address) /*|| (updateErrors && updateErrors.address)*/ ? true : false
												}
											></Input>
											{validation.touched.customer_address && validation.errors.customer_address ? (
												<FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
											) : null}
											<FormFeedback type="invalid">{errors && errors.address ? (<div>{errors.address}</div>) : null}</FormFeedback>
											{/*<FormFeedback type="invalid">{updateErrors && updateErrors.address ? (<div>{updateErrors.address}</div>) : null}</FormFeedback>*/}
										</div>
										<div>
											<Label htmlFor="customer_reference" className="form-label" >
												{t("Customer Reference")}
											</Label>
											<Input
												name="customer_reference"
												className="form-control"
												id="customer_reference"
												placeholder={t("Customer Reference")}
												onChange={validation.handleChange}
												onBlur={validation.handleBlur}
												value={validation.values.customer_reference || ""}
												invalid={
													(validation.errors.customer_reference) || (errors && errors.customer && errors.customer.reference) /*|| (updateErrors && updateErrors.customer && updateErrors.customer.reference)*/ ? true : false
												}
											></Input>
											{validation.touched.customer_reference && validation.errors.customer_reference ? (
												<FormFeedback type="invalid">{validation.errors.customer_reference}</FormFeedback>
											) : null}
											<FormFeedback type="invalid">{errors && errors.customer && errors.customer.reference ? (<div>{errors.customer.reference}</div>) : null}</FormFeedback>
											{/*<FormFeedback type="invalid">{updateErrors && updateErrors.customer && updateErrors.customer.reference ? (<div>{updateErrors.customer.reference}</div>) : null}</FormFeedback>*/}
										</div>
									</CardBody>
								</Card>
							</Col>
							<Col lg={12}>
								<div className="text-end">
									<button type="submit" className="btn btn-success">{order ? t("Update Box") : t("Create Box")}</button>
								</div>
							</Col>
						</Row>
					</Form>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default withTranslation()(CreateOrder);