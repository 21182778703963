import React, { useEffect, useState, useCallback } from "react";
import { withTranslation } from 'react-i18next';
import {
	Input,
	Card,
	CardBody,
	Col,
	Container,
	CardHeader,
	Table,
	//ModalHeader,
	//Modal,
	//ModalBody,
	Row,
	Badge
} from "reactstrap";
import * as moment from "moment";
import { Link, useParams, useNavigate } from "react-router-dom";
import BreadCrumb from "../../Components/Commons/BreadCrumb";
import OrderSetsTable from "./OrderSetsTable";
//import PickingsTable from "./PickingsTable";
import ImportsTable from "./ImportsTable";
import OperationalBlockStatus from "./OperationalBlockStatus";
import OrderStatusesChart from "./OrderStatusesChart";
import OrderStatusColor from "../OrderSets/OrderStatusColor";
import Pagination from "../../Components/Commons/Pagination";
import Loader from "../../Components/Commons/Loader";
import ConfirmModal from "../../Components/Commons/ConfirmModal";
import ImportOrdersModal from "../OrderImports/ImportOrdersModal";
import ErrorModal from "../../Components/Commons/ErrorModal";

// Formik
import Flatpickr from "react-flatpickr";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { 
	useGetOperationalBlockByIdQuery,
	//useCreatePickingMutation,
	useGetOrderEventsTypesQuery,
	//useGetPickingsQuery,
	useGetOrderImportsQuery,
	useGetOrderSetsQuery
} from '../../api/api'

const OperationalBlockDetails = ({ t, i18n }) => {
	let navigate = useNavigate();

	let { id } = useParams();

	const [statusAmounts, setStatusAmounts] = useState([]);
	const [statuses, setStatuses] = useState([]);
	const [colors, setColors] = useState([]);
	//const [statistics, setStatistics] = useState([]);

	const [orderSetStatusAmounts, setOrderSetStatusAmounts] = useState([]);
	const [orderSetStatuses, setOrderSetStatuses] = useState([]);
	const [orderSetColors, setOrderSetColors] = useState([]);
	//const [orderSetStatistics, setOrderSetStatistics] = useState([]);

	const [importedInput, setImportedInput] = useState(null);
	const [ordersImportedModal, setOrdersImportedModal] = useState(false);

	const [modal, setModal] = useState(false);
	//const [createPickingInModal, setCreatePickingInModal] = useState(false);
	//const [createReturnsPickingModal, setCreateReturnsPickingModal] = useState(false);

	//const [createdPicking, setCreatedPicking] = useState(null);
	const [pickingInCreatedModal, setPickingInCreatedModal] = useState(false);

	const [errorModal, setErrorModal] = useState(false);
	const [errorTitle, setErrorTitle] = useState(false);
	const [errorDescription, setErrorDescription] = useState(false);

	//const [pickingsCurrentPage, setPickingsCurrentPage] = useState(1);
	const [ordersImportCurrentPage, setOrdersImportCurrentPage] = useState(1);
	const [orderSetsCurrentPage, setOrderSetsCurrentPage] = useState(1);

	const { 
		data: block,
		error,
		isFetching: isBlockLoading,
		isSuccess: isBlockSuccess
	} = useGetOperationalBlockByIdQuery(
		id,
		{
			refetchOnMountOrArgChange: true,
			skip: false,
		}
	);

	/*const { 
		data: pickingsPages,
		error: pickingsError,
		isFetching: pickingsLoading,
		isSuccess: pickingsSuccess
	} = useGetPickingsQuery(
		{ 
			page: pickingsCurrentPage,
			operationalBlockId: id,
		}, 
		{
			refetchOnMountOrArgChange: true,
			skip: block === undefined,
		}
	);*/

	const { 
		data: ordersImportPages,
		error: ordersImportErrors,
		isFetching: isOrdersImportLoading,
		isSuccess: isOrdersImportSuccess
	} = useGetOrderImportsQuery(
		{ 
			page: ordersImportCurrentPage,
			operationalBlockId: id,
		},
		{
			refetchOnMountOrArgChange: true,
			skip: block === undefined,
		}
	);

	const { 
		data: orderSetsPages,
		error: orderSetsError,
		isFetching: isOrderSetLoading,
		isSuccess: isOrderSetSuccess
	} = useGetOrderSetsQuery(
		{ 
			page: orderSetsCurrentPage,
			operationalBlockId: id,
			onlyCompanyAssigned: true
		},
		{
			refetchOnMountOrArgChange: true,
			skip: block === undefined,
		}
	);

	useEffect(() => {
		if (block) {
			let amounts = block.orders_statistics.filter((status) => status.count > 0).map((status) => 
				status.count
			);
			setStatusAmounts(amounts);

			let statuses = block.orders_statistics.filter((status) => status.count > 0).map((status) =>
				status.display
			);
			setStatuses(statuses);

			const colors = block.orders_statistics.filter((status) => status.count > 0).map((status) =>
				OrderStatusColor({status: status.value})
			);
			setColors(colors);

			/*
			const statistics = block.orders_statistics.filter((status) => status.count > 0).map((status) => {
				return {
					...status,
					text_color: OrderStatusTextColor({status: status.value}),
				};
			});
			setStatistics(statistics);*/

			let orderSetAmounts = block.order_sets_statistics.filter((status) => status.count > 0).map((status) => 
				status.count
			);
			setOrderSetStatusAmounts(orderSetAmounts);

			let orderSetStatuses = block.order_sets_statistics.filter((status) => status.count > 0).map((status) =>
				status.display
			);
			setOrderSetStatuses(orderSetStatuses);

			const orderSetColors = block.order_sets_statistics.filter((status) => status.count > 0).map((status) =>
				OrderStatusColor({status: status.value})
			);
			setOrderSetColors(orderSetColors);
		}
	}, [block]);

	const [currentPage, setCurrentPage] = useState(1);
	const [orderIdSearch, setOrderIdSearch] = useState(null);
	const [creationStartDate, setCreationStartDate] = useState(null);
	const [billingStartDate, setBillingStartDate] = useState(null);
	const [creationEndDate, setCreationEndDate] = useState(null);
	const [billingEndDate, setBillingEndDate] = useState(null);
	const [campaign, setCampaign] = useState(null);

	const [selectedFiles, setselectedFiles] = useState([]);

	const { 
		data: orderEventsTypes,
		error: orderEventsTypesErrors,
		isFetching: isOrderEventsTypesLoading,
		isSuccess: isOrderEventsTypesSuccess
	} = useGetOrderEventsTypesQuery(
		{
			refetchOnMountOrArgChange: true,
			skip: false
		}
	);

	/*const [createPicking, { 
		error: pickingCreationErrors, 
		isLoading: pickingCreationLoading,
		isSuccess: pickingCreationSuccess,
		data: picking
	}] = useCreatePickingMutation();

	useEffect(() => {
		if(pickingCreationSuccess){
			setCreatePickingInModal(false);
			setCreateReturnsPickingModal(false);
			setCreatedPicking(picking);
			setPickingInCreatedModal(true);
		}
		if(pickingCreationErrors){
			setCreatePickingInModal(false);
			setCreateReturnsPickingModal(false);
			let errorString;
				
			if(pickingCreationErrors.data instanceof Array){
				errorString = error.data.join(', ');
			} else if(pickingCreationErrors.error){
				errorString = pickingCreationErrors.error;
			} else {
				errorString = "Unknown error"
			}
			setErrorTitle("Error when trying to import");
			setErrorDescription(errorString);
			setErrorModal(true);
		}
	}, [pickingCreationSuccess, pickingCreationErrors]);*/
	
	const toggle = useCallback(() => {
		if (modal) {
			setModal(false);
		} else {
			setModal(true);
		}
	}, [modal]);

	const handleCreateInputPicking = () => {
		const newInputPicking = {
			operational_block: {
				id: id
			},
			kind: 0,
		};
		createPicking(newInputPicking);
	};

	const handleCreateReturnsPicking = () => {
		const newInputPicking = {
			operational_block: {
				id: id
			},
			kind: 2,
		};
		createPicking(newInputPicking);
	};

	const handleSuccessImported = (importObject) => {
		setImportedInput(importObject);
		setOrdersImportedModal(true);
	};

	const handleNavigateToInput = () => {
		let data = importedInput;
		navigate(`../order-inputs/${data.input_reference}`);
	};

	const handleNavigateToPicking = () => {
		let data = createdPicking;
		debugger;
		navigate(`../pickings/${data.id}`);
	};

	const handleImportedError = (error) => {
		let errorString;
				
		if(error.data instanceof Array){
			errorString = error.data.join(', ');
		} else if(error.error){
			errorString = error.error;
		} else {
			errorString = "Unknown error"
		}
		setModal(false);
		setErrorTitle("Error when trying to import");
		setErrorDescription(errorString);
		setErrorModal(true);
	};

	const handleValidDate = date => {
	const date1 = moment(date).format("DD/MM");
	return date1;
  };

	document.title="Sistema de pedidos Transchile";

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					<BreadCrumb title={t("Billing Day Details")} pageTitle={t("Billing Days")} />
					<ImportOrdersModal 
						show={modal}
						onClose={() => setModal(false)}
						onOrderImportSuccess={handleSuccessImported}
						onOrderImportError={handleImportedError}
					/>
					<ConfirmModal
						show={ordersImportedModal}
						onConfirmClick={handleNavigateToInput}
						onCloseClick={() => setOrdersImportedModal(false)}
						title={t("Order import created")}
						question={t("You will be redirected to the import detail so you can see the progress.")}
						yesQuestion="Ok"
					/>
					{/*<ConfirmModal
						show={createPickingInModal}
						onConfirmClick={() => handleCreateInputPicking()}
						onCloseClick={() => setCreatePickingInModal(false)}
						title={t("Create new inbound picking")}
						question={t("Do you want to create an Inbound Picking linked to you?")}
						yesQuestion={t("Yes, create it!")}
					/>
					<ConfirmModal
						show={createReturnsPickingModal}
						onConfirmClick={() => handleCreateReturnsPicking()}
						onCloseClick={() => setCreateReturnsPickingModal(false)}
						title={t("Create new return picking")}
						question={t("Do you want to create an Returns Picking linked to you?")}
						yesQuestion={t("Yes, create it!")}
					/>*/}
					<ConfirmModal
						show={pickingInCreatedModal}
						onConfirmClick={handleNavigateToPicking}
						onCloseClick={() => setPickingInCreatedModal(false)}
						title={t("Input picking created")}
						question={t("You will be redirected to the picking detail")}
						yesQuestion={t("Ok")}
					/>
					<ErrorModal
						show={errorModal}
						onCloseClick={() => setErrorModal(false)}
						title={errorTitle}
						description={errorDescription}
					/>
					<Row>
						<Col xl={4}>
							<Card id="picking-overview">
								<CardHeader className="align-items-center d-flex">
									<h4 className="card-title mb-0 flex-grow-1">{t("Billing Day Information")}</h4>
								</CardHeader>
								<CardBody>
									{isBlockLoading && <Loader/>}
									{isBlockSuccess && (
										<>
											<div className="text-center mb-4">
												<div className="text-center">
													<h2 className="overview-companyname">
														{handleValidDate(block.billing_date)}
													</h2>
													<p className="text-muted m-0">{t("Billing Day")}</p>
												</div>
											</div>
											<div className="table-responsive table-card mb-0">
												<Table className="table">
													<tbody>
														<tr>
															<td className="fw-medium">{t("Status")}</td>
															<td className="overview-industryType mb-0 d-flex justify-content-end">
																<OperationalBlockStatus status={block.status} status_text={block.status_text} />
															</td>
														</tr>
													</tbody>
												</Table>
												{/*<div className="p-2 pt-1 pb-0">
													<button
														type="button"
														className="btn btn-primary w-100"
														id="create-btn"
														onClick={() => { setCreatePickingInModal(true); }}>
														<i className="ri-add-line align-bottom me-1"></i> {t("Create Input Picking")}
													</button>
												</div>*/}
												<div className="p-2 pt-1 pb-0">
													<button
														type="button"
														className="btn btn-success w-100"
														id="create-btn"
														onClick={() => { toggle(); }}
													>
														<i className="ri-download-line align-bottom me-1"></i> {t("Import Orders")}
													</button>
												</div>
												<div className="p-2 pt-1 pb-0">
													<Link to="../order-sets/new" className="btn btn-secondary w-100">
														<i className="ri-add-line align-bottom me-1"></i> {t("Create Order")}
													</Link>
												</div>
											</div>	
										</>
									)}
								</CardBody>
							</Card>
						</Col>

						<Col xl={4}>
							<Card id="statistics" className="card-height-100">
								<CardHeader className="align-items-center d-flex">
									<h4 className="card-title mb-0 flex-grow-1">{t("Order states")}</h4>
								</CardHeader>
								<CardBody>
									<div dir="ltr">
										{isBlockLoading && <Loader/>}
										{isBlockSuccess && (
											<>
												<OrderStatusesChart 
													series={orderSetStatusAmounts}
													labels={orderSetStatuses}
													colors={orderSetColors}
													itemName={'Orders'} />
												{/*statistics && <MyPortfolio statuses={statistics}/>*/}
											</>
										)}
									</div>
								</CardBody>
							</Card>
						</Col>

						<Col xl={4}>
							<Card id="statistics" className="card-height-100">
								<CardHeader className="align-items-center d-flex">
									<h4 className="card-title mb-0 flex-grow-1">{t("Boxes states")}</h4>
								</CardHeader>
								<CardBody>
									<div dir="ltr">
										{isBlockLoading && <Loader/>}
										{isBlockSuccess && (
											<>
												<OrderStatusesChart
													series={statusAmounts}
													labels={statuses}
													colors={colors}
													itemName={t('Boxes')} />
												{/*statistics && <MyPortfolio statuses={statistics}/>*/}
											</>
										)}
									</div>
								</CardBody>
							</Card>
						</Col>

					</Row>
					<Row>
						
						<Col xl={12}>
							<Card className="card-height-100">
								<CardHeader className="align-items-center d-flex">
									<h4 className="card-title mb-0 flex-grow-1">{t("Transchile Orders")} <Badge color="primary" className="ms-1">{isOrderSetSuccess ? orderSetsPages.count : '...'}</Badge></h4>
									<div className="flex-shrink-0">
										<Link to="../order-sets/new" className="btn btn-success btn-sm">
											<i className="ri-add-line align-bottom me-1"></i> {t("Create Order")}
										</Link>
									</div>
								</CardHeader>
								<CardBody className="pt-0">
									<div>
										<Row className="mb-3">
											<CardBody className="border border-dashed border-end-0 border-start-0">
												<form>
													<Row>
														<React.Fragment>
															<Col sm={2} className="col-xxl-2">
																<div>
																<Input
																	name="orderId"
																	id="id-field"
																	className="form-control"
																	placeholder="Search order ID"
																	type="text"
																	validate={{
																		required: { value: true },
																	}}
																	onChange={(value) => {
																		onChange(value);
																	}}
																/>
																</div>
															</Col>

															<Col sm={2} className="col-xxl-3">
																<div>
																	<Flatpickr
																		className="form-control"
																		id="datepicker-created-at"
																		placeholder="Create at"
																		options={{
																			altInput: true,
																			altFormat: "F j, Y",
																			mode: "range",
																			dateFormat: "DD-MM-YYYY",
																		}}
																		onChange={(date) => {
																			if(date[0]!== undefined){
																				const date1 = moment(date[0]).format("DD-MM-YYYY");
																				setCreationStartDate(date1);
																			} else {
																				setCreationStartDate(null);
																			}
																			if(date[1]!== undefined){
																				const date1 = moment(date[1]).format("DD-MM-YYYY");
																				setCreationEndDate(date1);
																			} else {
																				setCreationEndDate(null);
																			}
																		}}
																	/>
																</div>
															</Col>

															<Col sm={2} className="col-xxl-2">
																<div>
																<Input
																	name="campaignId"
																	id="id-field"
																	className="form-control"
																	placeholder="Campaign Id"
																	type="text"
																	validate={{
																		required: { value: true },
																	}}
																	onChange={(value) => {
																		setCampaign(value);
																	}}
																/>
																</div>
															</Col>

															{/*orderEventsTypes && <Col lg={4} className="col-xxl-2">
																<select
																	id="event_id"
																	className="form-select"
																	onChange={validation.handleChange}
																>
																	{orderEventsTypes.map((eventType, key) => (
																		<option key={key} value={eventType.id}>
																			{eventType.name}
																		</option>
																	))}
																</select>
															</Col>*/}
												
														</React.Fragment>
													</Row>
												</form>
											</CardBody>
										</Row>
										<OrderSetsTable
											data={orderSetsPages != null ? orderSetsPages.results : []}
											isLoading={isOrderSetLoading}
											isSuccess={isOrderSetSuccess}
											error={orderSetsError}
										/>
									</div>
									{orderSetsPages && orderSetsPages.results.length > 0 && (
										<Pagination
											totalRows={orderSetsPages.count}
											pageChangeHandler={setOrderSetsCurrentPage}
											rowsPerPage={25}
										/>
									)}
								</CardBody>
							</Card>
						</Col>
						{/*<Col xl={12}>
							<Card className="card-height-100">
								<CardHeader className="align-items-center d-flex">
									<h4 className="card-title mb-0 flex-grow-1">{t("Pickings")} <Badge color="primary" className="ms-1">{pickingsSuccess ? pickingsPages.count : '...'}</Badge></h4>
									<div className="flex-shrink-0">
										<button
											type="button"
											className="btn btn-success btn-sm"
											id="create-btn"
											onClick={() => { setCreatePickingInModal(true); }}>
											<i className="ri-add-line align-bottom me-1"></i> {t("Create Input Picking")}
										</button>
									</div>
								</CardHeader>
								<CardBody className="pt-0 mt-3">
									<PickingsTable
										data={pickingsSuccess ? pickingsPages.results : []}
										isLoading={pickingsLoading}
										isSuccess={pickingsSuccess}
										error={pickingsError}
									/>
									{pickingsPages && pickingsPages.results.length > 0 && (
										<Pagination
											totalRows={pickingsPages.count}
											pageChangeHandler={setPickingsCurrentPage}
											rowsPerPage={25}
										/>
									)}
								</CardBody>
							</Card>
						</Col>*/}
						<Col xl={12}>
							<Card className="card-height-100">
								<CardHeader className="align-items-center d-flex">
									<h4 className="card-title mb-0 flex-grow-1">{t("Imports")} <Badge color="primary" className="ms-1">{isOrdersImportSuccess ? ordersImportPages.count : '...'}</Badge></h4>
									<div className="flex-shrink-0">
										<button
											type="button"
											className="btn btn-success btn-sm"
											id="create-btn"
											onClick={() => { toggle(); }}
										>
											<i className="ri-download-line align-bottom me-1"></i> {t("Import Orders")}
										</button>
									</div>
								</CardHeader>
								<CardBody className="pt-0 mt-3">
									<ImportsTable
										data={isOrdersImportSuccess ? ordersImportPages.results : []}
										isLoading={isOrdersImportLoading}
										isSuccess={isOrdersImportSuccess}
										error={ordersImportErrors}
									/>
									{ordersImportPages && ordersImportPages.results.length > 0 && (
										<Pagination
											totalRows={ordersImportPages.count}
											pageChangeHandler={setOrdersImportCurrentPage}
											rowsPerPage={25}
										/>
									)}
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default withTranslation()(OperationalBlockDetails);