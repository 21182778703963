import React, { useEffect, useState, useCallback } from "react";
import { withTranslation } from 'react-i18next';
import {
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Modal,
	Form,
	ModalBody,
	Label,
	Input,
	Button,
	ModalHeader,
	DropdownItem, DropdownMenu, DropdownToggle, Offcanvas, OffcanvasBody, UncontrolledDropdown, FormFeedback
} from "reactstrap";
import { hasGroup } from "../../api/session_helper";
import { Link, useParams, useNavigate } from "react-router-dom";
import BreadCrumb from "../../Components/Commons/BreadCrumb";
import ErrorModal from "../../Components/Commons/ErrorModal";
import DeleteModal from "../../Components/Commons/DeleteModal";
import Loader from "../../Components/Commons/Loader";
import EmptyState from "../../Components/Commons/EmptyState";
import Pagination from "../../Components/Commons/Pagination";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAsyncDebounce } from "react-table";

import { useGetDriversQuery, useCreateDriverMutation, useDeleteDriverMutation } from '../../api/api'

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Drivers = ({ t, i18n }) => {

	document.title = "Sistema de pedidos Transchile";

	let navigate = useNavigate();

	const [currentPage, setCurrentPage] = useState(1);
	const [driverSearch, setDriverSearch] = useState(null);

	const [driver, setDriver] = useState(null);
	const [deleteModal, setDeleteModal] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [modal, setModal] = useState(false);

	const { 
		data: driversPages,
		errors,
		isFetching: isDriversLoading,
		isSuccess: isDriversSuccess
	} = useGetDriversQuery(
		{ 
			page: currentPage,
			filter: driverSearch,
		}, {
			refetchOnMountOrArgChange: true,
			skip: false,
		});

	const [createDriver, { 
		error: creationErrors, 
		isLoading: isCreationLoading 
	}] = useCreateDriverMutation();

	const [deleteDriver, { 
		error: deletionErrors, 
		isLoading: isdeletionLoading 
	}] = useDeleteDriverMutation();

	const toggle = useCallback(() => {
		if (modal) {
			setModal(false);
			validation.resetForm();
		} else {
			setModal(true);
		}
	}, [modal]);

	// Add To do
	const handleTeamClicks = () => {
		setModal(!modal);
		setIsEdit(false);
		toggle();
	};

	// Update To do
	const handleDriverEdit = useCallback((arg) => {
		const driver = arg;
		navigate(`${driver.id}/edit`)
	}, [toggle]);

	// delete
	const onClickData = (driver) => {
		setDriver(driver);
		setDeleteModal(true);
	};

	const handleDeleteDriver = () => {
		if (driver) {
			deleteDriver(driver.id);
			setDeleteModal(false);
			setDriver(null);
		}
	};

	useEffect(() => {
		const list = document.querySelectorAll(".team-list");
		const buttonGroups = document.querySelectorAll('.filter-button');
		for (let i = 0; i < buttonGroups.length; i++) {
			buttonGroups[i].addEventListener('click', onButtonGroupClick);
		}

		function onButtonGroupClick(event) {
			if (event.target.id === 'list-view-button' || event.target.parentElement.id === 'list-view-button') {
				document.getElementById("list-view-button").classList.add("active");
				document.getElementById("grid-view-button").classList.remove("active");
				list.forEach(function (el) {
					el.classList.add("list-view-filter");
					el.classList.remove("grid-view-filter");
				});

			} else {
				document.getElementById("grid-view-button").classList.add("active");
				document.getElementById("list-view-button").classList.remove("active");
				list.forEach(function (el) {
					el.classList.remove("list-view-filter");
					el.classList.add("grid-view-filter");
				});
			}
		}
	}, []);

	//OffCanvas  
	const [isOpen, setIsOpen] = useState(false);
	const [sideBar, setSideBar] = useState([]);

	//Dropdown
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const toggledropDown = () => {
		setDropdownOpen(!dropdownOpen);
	};

	// validation
	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: false,
		initialValues: {
			first_name: '',
			email: '',
			rut: '',
			phone_number: '',
		},
		validationSchema: Yup.object({  
			first_name: Yup.string().required("Please Enter First Name"),
			email: Yup.string().required("Please Enter Email"),
			rut: Yup.string().required("Please Enter Rut"),
			phone_number: Yup.string().required("Please Enter Phone"),
		}),
		onSubmit: (values) => {
			const newUser = {
				email: values["email"],
				first_name: values["first_name"],
			};
			const newDriver = {
				user: newUser,
				rut: values["rut"],
				phone_number: values["phone_number"],
			};
			createDriver(newDriver);
		},
	});

	const onSearchChange = useAsyncDebounce((value) => {
		setDriverSearch(value);
	}, 200);

	document.title = "Sistema de pedidos Transchile";
	return (
		<React.Fragment>
			<ToastContainer closeButton={false} />
			<DeleteModal
				show={deleteModal}
				onDeleteClick={() => handleDeleteDriver()}
				onCloseClick={() => setDeleteModal(false)}
			/>
			<div className="page-content">
				<Container fluid>
					<BreadCrumb title={t("Drivers")} pageTitle={t("Drivers")} />
					<Card>
						<CardBody>
							<Row className="g-2">
								<Col sm={3}>
									<div className="search-box">
										<Input
											type="text"
											className="form-control"
											placeholder={t("Search")}
											onChange={(e) => onSearchChange(e.target.value)} 
										/>
										<i className="ri-search-line search-icon"></i>
									</div>
								</Col>
								<Col className="col-sm-auto ms-auto">
									<div className="list-grid-nav hstack gap-1">
										<Button color="info" id="grid-view-button" className="btn btn-soft-info nav-link btn-icon fs-14 active filter-button"><i className="ri-grid-fill"></i></Button>
										<Button color="info" id="list-view-button" className="btn btn-soft-info nav-link  btn-icon fs-14 filter-button"><i className="ri-list-unordered"></i></Button>
										{hasGroup(['Manager', 'OperationalManager', 'RouteManager']) && (
											<Button color="success" onClick={() => handleTeamClicks()}>
												<i className="ri-add-fill me-1 align-bottom"></i> {t("Add Driver")}
											</Button>
										)}
									</div>
								</Col>
							</Row>
						</CardBody>
					</Card>

					<Row>
						<Col lg={12}>
							<div id="teamlist">
								<Row className="team-list grid-view-filter">
									{isDriversLoading && <Loader/>}
									{!isDriversLoading && driversPages && driversPages.count > 0 && (
										driversPages.results.map((item, key) => (
											<Col key={key}>
												<Card className="team-box">
													<div className="team-cover">
														<img src={item.backgroundImg} alt="" className="img-fluid" />
													</div>
													<CardBody className="p-4">
														<Row className="align-items-center team-row">
															<Col className="team-settings">
																<Row>
																	<Col>
																		<div className="flex-shrink-0 me-2">
																			<button type="button" className="btn btn-light btn-icon rounded-circle btn-sm favourite-btn" onClick={(e) => { setIsOpen(!isOpen); setSideBar(e.target); }}>
																				<i className="ri-information-fill fs-14 text-muted"></i>
																			</button>
																		</div>
																	</Col>
																	{hasGroup(['Manager', 'OperationalManager', 'RouteManager']) && (
																		<UncontrolledDropdown direction='start' className="col text-end">
																			<DropdownToggle tag="a" id="dropdownMenuLink2" role="button">
																				<i className="ri-more-fill fs-17"></i>
																			</DropdownToggle>
																			<DropdownMenu>
																				<DropdownItem className="dropdown-item edit-list" onClick={() => handleDriverEdit(item)}>
																					<i className="ri-pencil-line me-2 align-bottom text-muted"></i>{t("Edit")}
																				</DropdownItem>
																				{hasGroup(['Manager', 'OperationalManager']) && (
																					<DropdownItem className="dropdown-item remove-list" onClick={() => onClickData(item)}>
																						<i className="ri-delete-bin-5-line me-2 align-bottom text-muted"></i>{t("Remove")}
																					</DropdownItem>
																				)}
																			</DropdownMenu>
																		</UncontrolledDropdown>
																	)}
																</Row>
															</Col>
															<Col lg={4} className="col">
																<div className="team-profile-img">

																	<div className="avatar-lg img-thumbnail rounded-circle flex-shrink-0">
																		<div className="avatar-title text-uppercase border rounded-circle bg-light text-primary">
																			{item.user.first_name.charAt(0) + item.user.last_name.charAt(0)}
																		</div>
																	</div>
																	<div className="team-content">
																		<Link to="#" onClick={() => { setIsOpen(!isOpen); setSideBar(item); }}><h5 className="fs-16 mb-1">{item.user.first_name} {item.user.last_name}</h5></Link>
																		<p className="text-muted mb-0">{item.user.email}</p>
																	</div>
																</div>
															</Col>
															{/*<Col lg={4} className="col">
																<Row className="text-muted text-center">
																	<Col xs={6} className="border-end border-end-dashed">
																		<h5 className="mb-1">{item.projectCount}</h5>
																		<p className="text-muted mb-0">Projects</p>
																	</Col>
																	<Col xs={6}>
																		<h5 className="mb-1">{item.taskCount}</h5>
																		<p className="text-muted mb-0">Tasks</p>
																	</Col>
																</Row>
															</Col>*/}
															<Col lg={2} className="col">
																<div className="text-end">
																	<Link to={`${item.id}`} className="btn btn-light view-btn">{t("View Profile")}</Link>
																</div>
															</Col>
														</Row>
													</CardBody>
												</Card>
											</Col>
										))
									)}
									{!isDriversLoading && driversPages && driversPages.count == 0 && (
										<EmptyState
											title={t("No records found")}
											description={t("Change the filters for better results")}
										/>
									)}

									{/*<Col lg={12}>
										<div className="text-center mb-3">
											<Link to="#" className="text-success"><i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i> Load More </Link>
										</div>
									</Col>*/}

								</Row>

								{driversPages && driversPages.count > 0 && (
									<Pagination
										totalRows={driversPages.count}
										pageChangeHandler={setCurrentPage}
										rowsPerPage={25}
									/>
								)}

								<div className="modal fade" id="addmembers" tabIndex="-1" aria-hidden="true">
									<div className="modal-dialog modal-dialog-centered">
										<Modal isOpen={modal} toggle={toggle} centered>
											<ModalHeader toggle={toggle}>{t("Create Driver")}</ModalHeader>
											<ModalBody>
												<Form onSubmit={validation.handleSubmit}>
													<Row>
														<Col lg={12}>
															<div className="mb-3">
																<Label htmlFor="first_name" className="form-label">{t("First Name")}</Label>
																<Input type="text" className="form-control" id="first_name" placeholder={t("First Name")}
																	name='first_name'
																	validate={{
																			required: { value: true },
																	}}
																	onChange={validation.handleChange}
																	onBlur={validation.handleBlur}
																	value={validation.values.first_name || ""}
																	invalid={
																		(validation.errors.first_name) || (creationErrors && creationErrors.first_name) ? true : false
																	}
																/>
																{validation.touched.first_name && validation.errors.first_name ? (
																	<FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
																) : null}
																{creationErrors && creationErrors.first_name ? (<FormFeedback type="invalid">{creationErrors.first_name}</FormFeedback>) : null}
															</div>
														</Col>
														<Col lg={6}>
															<div className="mb-3">
																<Label htmlFor="phone_number" className="form-label">{t("Phone")}</Label>
																<Input type="text" className="form-control" id="phone_number" placeholder={t("Phone")}
																	name='phone_number'
																	validate={{
																			required: { value: true },
																	}}
																	onChange={validation.handleChange}
																	onBlur={validation.handleBlur}
																	value={validation.values.phone_number || ""}
																	invalid={
																		(validation.errors.phone_number) || (creationErrors && creationErrors.phone_number) ? true : false
																	}
																/>
																{validation.touched.phone_number && validation.errors.phone_number ? (
																	<FormFeedback type="invalid">{validation.errors.phone_number}</FormFeedback>
																) : null}
																{creationErrors && creationErrors.phone_number ? (<FormFeedback type="invalid">{creationErrors.phone_number}</FormFeedback>) : null}
															</div>
														</Col>
														<Col lg={6}>
															<div className="mb-3">
																<Label htmlFor="rut" className="form-label">{t("RUT")}</Label>
																<Input type="text" className="form-control" id="rut" placeholder={t("Enter RUT")}
																	name='rut'
																	validate={{
																			required: { value: true },
																	}}
																	onChange={validation.handleChange}
																	onBlur={validation.handleBlur}
																	value={validation.values.rut || ""}
																	invalid={
																		(validation.errors.rut) || (creationErrors && creationErrors.rut)? true : false
																	}
																/>
																{validation.touched.rut && validation.errors.rut ? (
																	<FormFeedback type="invalid">{validation.errors.rut}</FormFeedback>
																) : null}
																{creationErrors && creationErrors.rut ? (<FormFeedback type="invalid">{creationErrors.rut}</FormFeedback>) : null}
															</div>
														</Col>
														<Col lg={12}>
															<div className="mb-3">
																<Label htmlFor="email" className="form-label">{t("Email")}</Label>
																<Input type="text" className="form-control" id="email" placeholder={t("Enter email")} name='email'
																	validate={{
																		required: { value: true },
																	}}
																	onChange={validation.handleChange}
																	onBlur={validation.handleBlur}
																	value={validation.values.email || ""}
																	invalid={
																		(validation.errors.email) || (creationErrors && creationErrors.user && creationErrors.user.email) || (creationErrors && creationErrors.non_field_errors) ? true : false
																	}
																/>
																{creationErrors && creationErrors.user && creationErrors.user.email ? (<FormFeedback type="invalid">{creationErrors.user.email}</FormFeedback>) : null}
																{creationErrors && creationErrors.non_field_errors ? (<FormFeedback type="invalid">{creationErrors.non_field_errors}</FormFeedback>) : null}
																{validation.touched.email && validation.errors.email ? (
																	<FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
																) : null}
															</div>
														</Col>
														<Col lg={12}>
															<div className="hstack gap-2 justify-content-end">
																<button type="button" className="btn btn-light" onClick={() => toggle()}>{t("Close")}</button>
																<button type="submit" className="btn btn-success" id="addNewUser">{!isEdit ? t("Add Driver") : t("Save")}</button>
															</div>
														</Col>
													</Row>
												</Form>
											</ModalBody>
										</Modal>
									</div>
								</div>

								{sideBar && sideBar.user && (
									<Offcanvas
											isOpen={isOpen}
											direction="end"
											toggle={() => setIsOpen(!isOpen)}
											className="offcanvas-end border-0"
											tabIndex="-1"
											id="member-overview"
									>
										<OffcanvasBody className="profile-offcanvas p-0">
											<div className="p-3">
												<div className="team-settings">
													<Row>
														<Col>
															<button type="button" className="btn btn-light btn-icon rounded-circle btn-sm favourite-btn "> <i className="ri-star-fill fs-14"></i> </button>
														</Col>
														<UncontrolledDropdown direction='start' className="col text-end">
															<DropdownToggle tag="a" id="dropdownMenuLink14" role="button">
																<i className="ri-more-fill fs-17"></i>
															</DropdownToggle>
															<DropdownMenu>
																<DropdownItem><i className="ri-star-line me-2 align-middle" />Favorites</DropdownItem>
																<DropdownItem><i className="ri-delete-bin-5-line me-2 align-middle" />Delete</DropdownItem>
															</DropdownMenu>
														</UncontrolledDropdown>
													</Row>
												</div>
											</div>
											<div className="p-3 text-center">
												<div className="mt-3">
													<h5 className="fs-15 profile-name"><Link to="#" className="link-primary">{sideBar.name || "Nancy Martino"}</Link></h5>
													<p className="text-muted profile-designation">{sideBar.designation || "Team Leader & HR"}</p>
												</div>
											</div>
											<Row className="g-0 text-center">
												<Col xs={6}>
													<div className="p-3 border border-dashed border-start-0">
														<h5 className="mb-1 profile-project">{sideBar.projectCount || "124"}</h5>
														<p className="text-muted mb-0">Routes</p>
													</div>
												</Col>
												<Col xs={6}>
													<div className="p-3 border border-dashed border-start-0">
														<h5 className="mb-1 profile-task">{sideBar.taskCount || "81"}</h5>
														<p className="text-muted mb-0">Orders</p>
													</div>
												</Col>
											</Row>
											<div className="p-3">
												<h5 className="fs-15 mb-3">Personal Details</h5>
												<div className="mb-3">
													<p className="text-muted text-uppercase fw-semibold fs-12 mb-2">Phone</p>
													<h6>{sideBar.phone_number}</h6>
												</div>
												<div className="mb-3">
													<p className="text-muted text-uppercase fw-semibold fs-12 mb-2">Rut</p>
													<h6>{sideBar.user.rut}</h6>
												</div>
											</div>
										</OffcanvasBody>
										<div className="offcanvas-foorter border p-3 hstack gap-3 text-center position-relative">
											<Link to={`${sideBar.id}`} className="btn btn-primary w-100"><i className="ri-user-3-fill align-bottom ms-1"></i> View Profile</Link>
										</div>
									</Offcanvas>
								)}
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	</React.Fragment>
	);
};

export default withTranslation()(Drivers);


